// import React from 'react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslations from "../resources/english-translate.json";
import vietnameseTranslations from "../resources/vietnam-translate.json";

i18n
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            translation: englishTranslations
          },
          vn: {
            translation: vietnameseTranslations
          }
        },
        lng: "en", // ngôn ngữ mặc định
        fallbackLng: 'en', // ngôn ngữ dự phòng
        interpolation: {
          escapeValue: false
        }
      });

export default i18n;