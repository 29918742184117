import React, { useState, useEffect, lazy, Suspense } from "react";

import { Header } from "./header";
import { useAppSelector } from "../redux/store";
import { useLocation } from "react-router-dom";

const Main = lazy(() => import("./main/Main"));
const Footer = lazy(() => import("./footer/Footer"));
const ScrollToTop = lazy(
  () => import("../component/scrollToTop/scroll-to-top")
);

interface PropsType {
  children: React.ReactNode;
}
export default function RoyalLightLayout(props: PropsType) {
  const location = useLocation();
  const { pathname } = location;
  const [isComingSoon, setComingSoon] = useState(false);
  const { loading } = useAppSelector((state) => state.menuNavigation);

  useEffect(() => {
    if (
      pathname === "/coming-soon" ||
      pathname === "/payment" ||
      pathname === "/payment-success" ||
      pathname === "/payment-failed" ||
      pathname === "/login" ||
      pathname === "/register" ||
      pathname === "/teacher"
    ) {
      setComingSoon(true);
    } else {
      setComingSoon(false);
    }
  }, [pathname]);
  return (
    <div>
      <Header />
      {!loading && (
        <Suspense fallback={<div>Loading...</div>}>
          <Main>{props.children}</Main>
          <ScrollToTop />
          {!isComingSoon ? <Footer /> : ""}
        </Suspense>
      )}
    </div>
  );
}
