import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveAccountService,
  CheckSessionLoginService,
  FindEmailExistAPI,
  LoginService,
  LogoutService,
  RegisterAccountService,
  SendUpdatePasswordAPI,
} from "../../services/AuthenticateAPI/AuthenticateAPI";
import {
  LoginRequest,
  LoginResponse,
  RegisterRequest,
} from "../../services/AuthenticateAPI/InterfaceResponse";
import { toast } from "react-toastify";
import { LoginWithOAuth2GoogleRequest } from "../../services/GoogleOAuth2API/InterfaceResponse";
import { LoginWithOAuth2GoogleAPI } from "../../services/GoogleOAuth2API/GoogleOAuth2API";

interface InitialValuesStyle {
  isLoginned: boolean;
  emailExist: boolean;
  updatePassword: boolean;
  loading: boolean;
  message: string;
  error: string;
  errorRegister: boolean|null;
  otp: string;
  otpExpires: null;
  fullName: string;
  roleName: string;
  email: string;
  phoneNumber: string;
  featureAccountId: number[];
}

export const RegisterAccountAction = createAsyncThunk<
  string | undefined,
  RegisterRequest
>("RegisterAccountAction", async (data: RegisterRequest) => {
  try {
    const response: string | unknown = await RegisterAccountService(data);
    if (response !== undefined) {
      toast.success("Register account successfully");
      return response as string;
    }
  } catch (err: any) {
    toast.error(err.message?err.message:"Have error when try register account. Please contact hotline xxx for consulting support.");
    throw new Error(err.message);
  }
});

export const ActiveAccountAction= createAsyncThunk<string|any,any>(
  "ActiveAccountAction",
  async (values: string) => {
    try {
      const response: string | unknown = await ActiveAccountService(values);
      if (response!== undefined) {
        toast.success("Active account successfully");
        return response as string;
      }
    } catch (err: any) {
      toast.error(err.message);
      throw new Error(err.message);
    }
  }
)

export const LoginAccountAction = createAsyncThunk<
  LoginResponse | any,
  LoginRequest
>("LoginAccountAction", async (data: LoginRequest) => {
  try {
    const response: LoginResponse | any = await LoginService(data);
    if (response !== undefined) {
      toast.success("Login account successfully");
      return response as LoginResponse;
    }
  } catch (err: any) {
    toast.error("Username or password incorrect");
    throw new Error(err.message);
  }
});

export const LoginWithOAuth2GoogleAction= createAsyncThunk<LoginResponse|any,LoginWithOAuth2GoogleRequest>(
  "LoginWithOAuth2Action",
  async (data:LoginWithOAuth2GoogleRequest)=>{
      try{
          const res: LoginResponse= await LoginWithOAuth2GoogleAPI(data);
          toast.success("Login successfull");
          return res;
      }catch(err:any){
          toast.error("Login failed");
          throw new Error(err.message);
      }
  }
)
export const CheckSessionLoginAction = createAsyncThunk<LoginResponse|any>(
  "CheckSessionLoginAction",
  async () => {
    try {
      const response: LoginResponse | any = await CheckSessionLoginService();
      if (response!== undefined) {
        return response as LoginResponse;
      }
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
);

export const LogoutAction = createAsyncThunk(
  "LogoutAction",
  async () => {
    try {
      const response:string|any = await LogoutService();
      if (response!== undefined) {
        toast.success(response);
        return response as unknown as string;;
      }
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
);

export const FindEmailExistAction = createAsyncThunk<string,string>(
  "FindEmailExistAction",
  async (email: string) => {
    try {
      const response: string = await FindEmailExistAPI(email);
      return response as unknown as string;
    } catch (err: any) {
      toast.error("Email does not exist");
      throw new Error(err.message);
    }
  }
);

export const SendUpdatePasswordAction = createAsyncThunk<string,{email:string;password:string}>(
  "SendUpdatePasswordAction",
  async (data: {email:string;password:string}) => {
    try {
      const response: string = await SendUpdatePasswordAPI(data);
      toast.success("Reset password successfully.");
      return response as unknown as string;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
)

const initialState: InitialValuesStyle = {
  loading: false,
  errorRegister: null,
  error: "",
  message: "",
  otp: "",
  otpExpires: null,
  fullName: "",
  roleName: "",
  featureAccountId: [],
  isLoginned: false,
  email: "",
  phoneNumber: "",
  emailExist: false,
  updatePassword: false
};

const AuthenticateSlice = createSlice({
  name: "authenticate",
  initialState,
  reducers: {
    updateOtp: (state, action) => {
      state.otp = action.payload.otp;
      state.otpExpires = action.payload.otpExpires;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RegisterAccountAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(ActiveAccountAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginAccountAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginWithOAuth2GoogleAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(CheckSessionLoginAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(FindEmailExistAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(SendUpdatePasswordAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(RegisterAccountAction.fulfilled, (state, action) => {
        state.loading = false;
        state.errorRegister = false;
        state.message = action.payload || "Register account successfully";
      })
      .addCase(ActiveAccountAction.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload || "Register account successfully";
      })
      .addCase(LoginAccountAction.fulfilled, (state, action:PayloadAction<LoginResponse>) => {
        state.loading = false;
        state.fullName= action.payload.fullName;
        state.roleName= action.payload.roleName;
        state.email= action.payload.email;
        state.phoneNumber= action.payload.phoneNumber;
        state.featureAccountId= action.payload.featureAccountId;
        state.isLoginned = true;
      })
      .addCase(LoginWithOAuth2GoogleAction.fulfilled, (state, action:PayloadAction<LoginResponse>) => {
        state.loading = false;
        state.fullName= action.payload.fullName;
        state.roleName= action.payload.roleName;
        state.email= action.payload.email;
        state.phoneNumber= action.payload.phoneNumber;
        state.featureAccountId= action.payload.featureAccountId;
        state.isLoginned = true;
      })
      .addCase(CheckSessionLoginAction.fulfilled, (state, action:PayloadAction<LoginResponse>) => {
        state.loading = false;
        state.fullName= action.payload.fullName;
        state.roleName= action.payload.roleName;
        state.email= action.payload.email;
        state.phoneNumber= action.payload.phoneNumber;
        state.featureAccountId= action.payload.featureAccountId;
        state.isLoginned = true;
      })
      .addCase(LogoutAction.fulfilled, (state) => {
        state.loading = false;
        state.fullName= "";
        state.roleName= "";
        state.featureAccountId= [];
        state.isLoginned = false;
      })
      .addCase(FindEmailExistAction.fulfilled, (state) => {
        state.loading = false;
        state.emailExist=true;
      })
      .addCase(SendUpdatePasswordAction.fulfilled, (state) => {
        state.loading = false;
        state.updatePassword=true;
      })
      .addCase(RegisterAccountAction.rejected, (state, action) => {
        state.loading = false;
        state.errorRegister = true;
        state.error = action.error.message || "Register account failed";
      })
      .addCase(ActiveAccountAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Register account failed";
      })
      .addCase(LoginAccountAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Login account failed";
        state.isLoginned = false;
      })
      .addCase(LoginWithOAuth2GoogleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Login account failed";
        state.isLoginned = false;
      })
      .addCase(CheckSessionLoginAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Check session login failed";
        state.isLoginned = false;
      })
      .addCase(LogoutAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Logout failed";
      })
      .addCase(FindEmailExistAction.rejected, (state, action) => {
        state.loading = false;
        state.emailExist = false;
        state.error = action.error.message || "Account not exist";
      })
      .addCase(SendUpdatePasswordAction.rejected, (state, action) => {
        state.loading = false;
        state.updatePassword = false;
        state.error = action.error.message || "Update password is failed";
      })
      ;
  },
});

export const { updateOtp } = AuthenticateSlice.actions;
export default AuthenticateSlice.reducer;
