import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./Header.module.scss";
import { Button } from "../../component/Button";
import LanguageIcon from "@mui/icons-material/Language";
import TableRowsIcon from "@mui/icons-material/TableRows";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import classNames from "classnames/bind";

import EnglishLanguage from "../../assets/images/us-flag.png";
import VietnameseLanguage from "../../assets/images/vietnam-flag.png";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import ImgLogo from "../../assets/images/logo3.jpg";

import { useRouter } from "../../routes/hooks";
// import "../../utils/Translate-config";
import {
  VietnameseCourseData,
  VietnameseCourseForAdult,
} from "../../resources/VietnameseCourseData";
import { EnglishCourseData } from "../../resources/EnglishCourseData";
import { updateStateLoading } from "../../redux/CourseSlice/CourseSlice";
import { updateStateMenuNavigation } from "../../redux/MenuNavigationSlice/MenuNavigationSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import "animate.css";
import {
  CheckSessionLoginAction,
  LogoutAction,
} from "../../redux/AuthenticateSlice/AuthenticateSlice";
import {
  ClearStateCartItemWhenLogout,
  GetAllCartItemAction,
} from "../../redux/CartSlice/CartSlice";
import { CartResponse } from "../../redux/CartSlice/InterfaceResponse";
import { GetAllPosts } from "../../services/BlogAPI/BlogAPI";
import { updatePost } from "../../redux/BlogSlice/BlogSlice";
import { updateLanguage } from "../../redux/TranslateSlice/TranslateSlice";
// import { toast } from "react-toastify";
// import { use } from "i18next";

interface CourseTypes {
  title: string;
  data: {
    id: number;
    value: {
      name: string;
      color: string;
      label: string;
    }[];
  }[];
}

const cx = classNames.bind(styles);
function Header() {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const VietnameseCourseForChildrenDataArray: CourseTypes[] =
    VietnameseCourseData();
  const VietnameseCourseForAdultDataArray: CourseTypes[] =
    VietnameseCourseForAdult();
  const EnglishCourseForChildrenDataArray: CourseTypes[] = EnglishCourseData();

  //state
  const { loading } = useAppSelector((state) => state.menuNavigation);
  const { isLoginned, fullName } = useAppSelector(
    (state) => state.authenticate
  );
  const { language } = useAppSelector((state) => state.translate);
  const { CartItem } = useAppSelector((state) => state.cart);
  const [scrolled, setScrolled] = useState(0);
  const [showMarquee, setShowMarquee] = useState(true);
  const [activeCourseItem, setActiveCourseItem] = useState(
    Array.from([1, 2, 3]).map(() => false)
  );
  const [activeCourse, setActiveCourse] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showBoxSearchResult, setShowBoxSearchResult] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  //ref element
  const prevScrolledRef = useRef(window.scrollY);
  const refBoxSearchResult = useRef<HTMLDivElement>(null);
  const refBoxSettings = useRef<HTMLDivElement>(null);

  //functionality
  const handlePreventDefault = (e: any) => {
    e.preventDefault();
  };
  const handleClickMenuNavigation = (e: any, href: string) => {
    e.preventDefault();
    dispatch(updateStateMenuNavigation(false));
    router.push(href);
  };
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos < 1) {
      setScrolled(0);
      setShowMarquee(true);
    } else {
      setShowMarquee(false);
      if (prevScrolledRef.current > currentScrollPos) {
        setScrolled(0);
      } else {
        setScrolled(-150);
      }
    }
    prevScrolledRef.current = currentScrollPos;
  }, []);
  const handleClickCourseView = (courseName: string, courseType: string) => {
    dispatch(updateStateMenuNavigation(false));
    setActiveCourse(false);
    setActiveCourseItem(Array.from([1, 2, 3]).map(() => false));
    dispatch(updateStateLoading());
    if (
      courseType === "Vietnamese for children" ||
      courseType === "Vietnamese for adult"
    ) {
      router.push(
        `/courses/vi/course?name=${courseName}&courseType=${courseType}`
      );
    } else {
      router.push(
        `/courses/en/course?name=${courseName}&courseType=${courseType}`
      );
    }
  };
  const hanleClickRedirectCourseRegisterPage = () => {
    dispatch(updateStateMenuNavigation(false));
    router.push("/course-register");
  };
  const handleClickShowMenuNavigation = () => {
    dispatch(updateStateMenuNavigation(true));
  };
  const handleClickDisableMenuNavigation = () => {
    dispatch(updateStateMenuNavigation(false));
  };
  const handleClickShowListCourseItem = (index: number) => {
    const newActiveCourseItem = [...activeCourseItem];
    newActiveCourseItem[index] = !newActiveCourseItem[index];
    const findActiveCourseItem = activeCourseItem.findIndex(
      (activeCourseItemItem) => activeCourseItemItem === true
    );
    newActiveCourseItem[findActiveCourseItem] = false;
    if (newActiveCourseItem[index] !== activeCourseItem[index]) {
      setActiveCourseItem(newActiveCourseItem);
    }
  };
  const handleClickActiveCourse = (e: any) => {
    e.preventDefault();
    setActiveCourse(!activeCourse);
    setActiveCourseItem(Array.from([1, 2, 3]).map(() => false));
  };
  const handleClickShowSetting = () => {
    setShowSettings(!showSettings);
  };
  const handleClickLogout = () => {
    setShowSettings(false);
    dispatch(LogoutAction());
    dispatch(ClearStateCartItemWhenLogout());
    setTimeout(() => {
      router.push("/login");
    }, 2000);
  };
  const handleChangeLanguage = (language: string) => {
    dispatch(updateLanguage(language));
  };
  const handleNavigateAudioDownload = () => {
    setShowSettings(false);
    router.push("/audio-the-book");
    // if (isLoginned) {
    //   router.push("/audio-the-book");
    // } else {
    //   toast.warn("you must be logged in");
    //   router.push("/login");
    // }
  };

  //hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (window.innerWidth < 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        refBoxSearchResult.current &&
        !refBoxSearchResult.current.contains(event.target as Node)
      ) {
        setShowBoxSearchResult(false);
      }
      if (
        refBoxSettings.current &&
        !refBoxSettings.current.contains(event.target as Node)
      ) {
        setShowSettings(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isLoginned) {
          dispatch(CheckSessionLoginAction());
        }
        if (isLoginned) {
          dispatch(GetAllCartItemAction());
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [dispatch, isLoginned]);
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await GetAllPosts();
        if (response.status === 200) {
          dispatch(updatePost(response.data));
          sessionStorage.setItem("blogs", JSON.stringify(response.data));
        } else {
          console.log("Failed to fetch data");
        }
      } catch (error: any) {
        console.error(error);
      }
    };
    loadData();
  }, [dispatch]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  return (
    <header
      className={cx("header")}
      style={{
        top: `${scrolled}px`,
        position: `${loading ? "relative" : "fixed"}`,
      }}
    >
      <div
        className={cx("header-top")}
        style={{ display: `${showMarquee ? "flex" : "none"}` }}
      >
        <div className={cx("wrapper")}>
          <div className={cx("wrapper-left")}>
            <div className={cx("logo")}>
              <div className={cx("logo-image")}>
                <img src={ImgLogo} alt="logo" />
              </div>
              <div className={cx("logo-text")}>
                <p>
                  <span translate="no">ABBA Light</span>
                  <span translate="no">Academy</span>
                </p>
              </div>
            </div>
            <div className={cx("box-search")}>
              <div className={cx("box-search-input")}>
                <input
                  type="text"
                  className={cx("input-box")}
                  placeholder={`${t("SEARCH")}...`}
                />
              </div>
              <div className={cx("box-search-button")}>
                <button className={cx("button-box")}>
                  <span>{t("SEARCH")}</span>
                </button>
              </div>
            </div>
          </div>
          <div className={cx("wrapper-right")}>
            <Button
              onClick={() => {
                router.push("/course-register");
              }}
              bgColor="#58B4DB"
              width="200"
            >
              {" "}
              {t("REGISTER-NOW")}{" "}
            </Button>
            <div className={cx("language")}>
              <LanguageIcon />
              <span>{language === "en" ? "English" : "Vietnamese"}</span>
              <div className={cx("dropdown-translate")}>
                <div
                  className={cx("dropdown-translate-item")}
                  onClick={() => handleChangeLanguage("en")}
                >
                  <img src={EnglishLanguage} alt="us-flag" />
                  <span>English</span>
                </div>
                <div
                  className={cx("dropdown-translate-item")}
                  onClick={() => handleChangeLanguage("vn")}
                >
                  <img src={VietnameseLanguage} alt="vietnam" />
                  <span>Vietnamese</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cx("header-menu")}>
        <div className={cx("container-tablet", `${loading ? "show" : "hide"}`)}>
          <div className={cx("header-menu-navigation")}>
            <div
              className={cx(
                "header-menu-navigation-top",
                `${isMobile ? "isMobile" : ""}`
              )}
            >
              <div
                className={cx("header-menu-navigation-button-close")}
                onClick={handleClickDisableMenuNavigation}
              >
                <CloseIcon />
              </div>
              <div className={cx("search-box")}>
                <div
                  className={cx("search-box-input")}
                  ref={refBoxSearchResult}
                >
                  <input
                    type="text"
                    className={cx("input-box")}
                    placeholder="Search..."
                    onFocus={() => {
                      setShowBoxSearchResult(true);
                    }}
                  />
                </div>
                <div className={cx("search-box-button")}>
                  <SearchIcon />
                </div>
                <div
                  className={cx(
                    "search-box-result",
                    "animate__animated animate__zoomIn animate__delay-0.5s"
                  )}
                  style={{
                    display: `${showBoxSearchResult ? "block" : "none"}`,
                  }}
                >
                  <ul>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                    <li>⊙ ket qua 1</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={cx("header-menu-navigation-body")}>
              <nav className={cx("menu-list-navigation")}>
                <div className={cx("menu-item")}>
                  <a
                    href="/login"
                    onClick={(e) => {
                      handleClickMenuNavigation(e, "/login");
                    }}
                  >
                    {t("SIGN-IN")}
                  </a>
                </div>
                <div className={cx("menu-item")}>
                  <a
                    href="/"
                    onClick={(e) => {
                      handleClickMenuNavigation(e, "/");
                    }}
                  >
                    {t("HOME")}
                  </a>
                </div>
                <div className={cx("menu-item")}>
                  <a
                    href="/about"
                    onClick={(e) => {
                      handleClickMenuNavigation(e, "/about");
                    }}
                  >
                    {t("ABOUT")}
                  </a>
                </div>
                <div className={cx("menu-item", "dropdown")}>
                  <a
                    href="/"
                    onClick={handleClickActiveCourse}
                    className={cx(`${activeCourse ? "active" : ""}`)}
                  >
                    {t("COURSE")} <ExpandMoreIcon />
                  </a>
                  <div
                    className={cx(
                      "dropdown-menu",
                      `${activeCourse ? "active" : ""}`
                    )}
                  >
                    <div
                      className={cx("dropdown-menu-item", "dropdown-sub")}
                      onClick={() => handleClickShowListCourseItem(0)}
                    >
                      <span
                        className={cx(`${activeCourseItem[0] ? "active" : ""}`)}
                      >
                        {t("ENGLISH-FOR-CHILDREN")} <ExpandMoreIcon />
                      </span>
                      <div
                        className={cx(
                          "dropdown-sub-menu",
                          `${activeCourseItem[0] ? "active" : ""}`,
                          "english-course-for-children"
                        )}
                      >
                        {EnglishCourseForChildrenDataArray.map((courseItem) => {
                          return (
                            <div
                              className={cx("dropdown-sub-menu-item")}
                              key={courseItem.title}
                            >
                              {courseItem.data.map((courseItemData) => {
                                return (
                                  <div
                                    className={cx("list-course-item")}
                                    key={courseItemData.id}
                                  >
                                    <ul>
                                      {courseItemData.value.map(
                                        (item, index) => {
                                          return (
                                            <li
                                              className={cx("course-item")}
                                              key={index + 7}
                                            >
                                              <a
                                                href="/"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleClickCourseView(
                                                    item.name,
                                                    "English for children"
                                                  );
                                                }}
                                                style={{
                                                  color: `${item.color}`,
                                                }}
                                              >
                                                ◆ {t(item.label)}
                                              </a>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className={cx("dropdown-menu-item", "dropdown-sub")}
                      onClick={() => handleClickShowListCourseItem(1)}
                    >
                      <span
                        className={cx(`${activeCourseItem[1] ? "active" : ""}`)}
                      >
                        {t("VIETNAM-FOR-CHILDREN")} <ExpandMoreIcon />
                      </span>
                      <div
                        className={cx(
                          "dropdown-sub-menu",
                          "vietname-course-for-children",
                          `${activeCourseItem[1] ? "active" : ""}`
                        )}
                      >
                        {VietnameseCourseForChildrenDataArray.map(
                          (courseItem) => {
                            return (
                              <div
                                className={cx("dropdown-sub-menu-item")}
                                key={courseItem.title}
                              >
                                {courseItem.data.map((courseItemData) => {
                                  return (
                                    <div
                                      className={cx("list-course-item")}
                                      key={courseItemData.id}
                                    >
                                      <ul>
                                        {courseItemData.value.map(
                                          (item, index) => {
                                            return (
                                              <li
                                                className={cx("course-item")}
                                                key={index + 7}
                                              >
                                                <a
                                                  href="/"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClickCourseView(
                                                      item.name,
                                                      "Vietnamese for children"
                                                    );
                                                  }}
                                                  style={{
                                                    color: `${item.color}`,
                                                  }}
                                                >
                                                  ◆ {t(item.label)}
                                                </a>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div
                      className={cx("dropdown-menu-item", "dropdown-sub")}
                      onClick={() => handleClickShowListCourseItem(2)}
                    >
                      <span
                        className={cx(`${activeCourseItem[2] ? "active" : ""}`)}
                      >
                        {t("VIETNAM-FOR-ADULT")} <ExpandMoreIcon />
                      </span>
                      <div
                        className={cx(
                          "dropdown-sub-menu",
                          `${activeCourseItem[2] ? "active" : ""}`,
                          "vietname-course-for-adult"
                        )}
                      >
                        {VietnameseCourseForAdultDataArray.map((courseItem) => {
                          return (
                            <div
                              className={cx("dropdown-sub-menu-item")}
                              key={courseItem.title}
                            >
                              {courseItem.data.map((courseItemData) => {
                                return (
                                  <div
                                    className={cx("list-course-item")}
                                    key={courseItemData.id}
                                  >
                                    <ul>
                                      {courseItemData.value.map(
                                        (item, index) => {
                                          return (
                                            <li
                                              className={cx("course-item")}
                                              key={index + 7}
                                            >
                                              <a
                                                href="/"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleClickCourseView(
                                                    item.name,
                                                    "Vietnamese for adult"
                                                  );
                                                }}
                                                style={{
                                                  color: `${item.color}`,
                                                }}
                                              >
                                                ◆ {t(item.label)}
                                              </a>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx("menu-item")}>
                  <a
                    href="/e-book"
                    onClick={(e) => {
                      handleClickMenuNavigation(e, "/e-book");
                    }}
                  >
                    {t("E-BOOK")}
                  </a>
                </div>
                <div className={cx("menu-item")}>
                  <a
                    href="/teacher"
                    onClick={(e) => {
                      handleClickMenuNavigation(e, "/teacher");
                    }}
                  >
                    {t("TEACHER")}
                  </a>
                </div>
                <div className={cx("menu-item")}>
                  <a
                    href="/blog"
                    onClick={(e) => {
                      handleClickMenuNavigation(e, "/blog");
                    }}
                  >
                    {t("BLOG")}
                  </a>
                </div>
                <div className={cx("menu-item")}>
                  <a
                    href="/courses/price"
                    onClick={(e) => {
                      handleClickMenuNavigation(e, "/courses/price");
                    }}
                  >
                    {t("TUITION")}
                  </a>
                </div>
              </nav>
              <div className={cx("button-register")}>
                <Button
                  onClick={hanleClickRedirectCourseRegisterPage}
                  bgColor="#569db9"
                  width="300"
                >
                  {" "}
                  {t("Register Now")}{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={cx("container", `${loading ? "hide" : "show"}`)}>
          <div className={cx("header-menu-left")}>
            <div className={cx("header-menu-left-contact")}>
              <PhoneInTalkIcon />
            </div>
            <div className={cx("header-menu-left-phone")}>
              <p>
                <span>{t("HOTLINE")}</span>
                <span>0975 620 010</span>
              </p>
            </div>
          </div>
          <nav className={cx("header-menu-navigation")}>
            <div className={cx("menu-item")}>
              <a
                style={{ fontSize: language === "vn" ? "18px" : "20px" }}
                href="/"
                onClick={(e) => {
                  handleClickMenuNavigation(e, "/");
                }}
              >
                {t("HOME")}
              </a>
            </div>
            <div
              className={cx("menu-item")}
              style={{ width: language === "vn" ? "85px" : "80px" }}
            >
              <a
                style={{ fontSize: language === "vn" ? "18px" : "20px" }}
                href="/about"
                onClick={(e) => {
                  handleClickMenuNavigation(e, "/about");
                }}
              >
                {t("ABOUT")}
              </a>
            </div>
            <div className={cx("menu-item", "dropdown")}>
              <a
                href="/"
                style={{ fontSize: language === "vn" ? "18px" : "20px" }}
                onClick={handlePreventDefault}
              >
                <span>{t("COURSE")}</span>
                <ExpandMoreIcon
                  style={{ right: language === "vn" ? "-15px" : "-5px" }}
                />
              </a>
              <div className={cx("dropdown-menu")}>
                <div className={cx("dropdown-menu-item", "dropdown-sub")}>
                  <a
                    href="/"
                    onClick={handlePreventDefault}
                    style={{ fontSize: language === "vn" ? "14px" : "16px" }}
                  >
                    {t("ENGLISH-FOR-CHILDREN")}
                    <KeyboardArrowRightIcon />
                  </a>
                  <div
                    className={cx(
                      "dropdown-sub-menu",
                      "english-course-for-children",
                      "animate__animated animate__fadeInLeft animate__delay-0.1s"
                    )}
                  >
                    {EnglishCourseForChildrenDataArray.map((courseItem) => {
                      return (
                        <div
                          className={cx("dropdown-sub-menu-item")}
                          key={courseItem.title}
                        >
                          {courseItem.data.map((courseItemData) => {
                            return (
                              <div
                                className={cx("list-course-item")}
                                key={courseItemData.id}
                              >
                                <ul>
                                  {courseItemData.value.map((item, index) => {
                                    return (
                                      <li
                                        className={cx("course-item")}
                                        key={index + 7}
                                      >
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleClickCourseView(
                                              item.name,
                                              "English for children"
                                            );
                                          }}
                                          style={{
                                            color: `${item.color}`,
                                          }}
                                        >
                                          {t(`${item.label}`)}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <div className={cx("dropdown-menu-item", "dropdown-sub")}>
                  <a
                    href="/"
                    onClick={handlePreventDefault}
                    style={{ fontSize: language === "vn" ? "14px" : "16px" }}
                  >
                    {t("VIETNAM-FOR-CHILDREN")}
                    <KeyboardArrowRightIcon />
                  </a>
                  <div
                    className={cx(
                      "dropdown-sub-menu",
                      "vietname-course-for-children",
                      "animate__animated animate__fadeInLeft animate__delay-0.1s"
                    )}
                  >
                    {VietnameseCourseForChildrenDataArray.map((courseItem) => {
                      return (
                        <div
                          className={cx("dropdown-sub-menu-item")}
                          key={courseItem.title}
                        >
                          {courseItem.data.map((courseItemData) => {
                            return (
                              <div
                                className={cx("list-course-item")}
                                key={courseItemData.id}
                              >
                                <ul>
                                  {courseItemData.value.map((item, index) => {
                                    return (
                                      <li
                                        className={cx("course-item")}
                                        key={index + 7}
                                      >
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleClickCourseView(
                                              item.name,
                                              "Vietnamese for children"
                                            );
                                          }}
                                          style={{
                                            color: `${item.color}`,
                                          }}
                                        >
                                          {t(item.label)}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={cx("dropdown-menu-item", "dropdown-sub")}>
                  <a
                    href="/"
                    onClick={handlePreventDefault}
                    style={{ fontSize: language === "vn" ? "14px" : "16px" }}
                  >
                    {t("VIETNAM-FOR-ADULT")}
                    <KeyboardArrowRightIcon />
                  </a>
                  <div
                    className={cx(
                      "dropdown-sub-menu",
                      "vietname-course-for-adult",
                      "animate__animated animate__fadeInLeft animate__delay-0.1s"
                    )}
                  >
                    {VietnameseCourseForAdultDataArray.map((courseItem) => {
                      return (
                        <div
                          className={cx("dropdown-sub-menu-item")}
                          key={courseItem.title}
                        >
                          {courseItem.data.map((courseItemData) => {
                            return (
                              <div
                                className={cx("list-course-item")}
                                key={courseItemData.id}
                              >
                                <ul>
                                  {courseItemData.value.map((item, index) => {
                                    return (
                                      <li
                                        className={cx("course-item")}
                                        key={index + 7}
                                      >
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleClickCourseView(
                                              item.name,
                                              "Vietnamese for adult"
                                            );
                                          }}
                                          style={{
                                            color: `${item.color}`,
                                          }}
                                        >
                                          {t(item.label)}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div> */}
              </div>
            </div>
            <div className={cx("menu-item")}>
              <a
                href="/e-book"
                style={{ fontSize: language === "vn" ? "18px" : "20px" }}
                onClick={(e) => {
                  handleClickMenuNavigation(e, "/e-book");
                }}
              >
                {t("E-BOOK")}
              </a>
            </div>
            <div className={cx("menu-item")}>
              <a
                href="/teacher"
                style={{ fontSize: language === "vn" ? "18px" : "20px" }}
                onClick={(e) => {
                  handleClickMenuNavigation(e, "/teacher");
                }}
              >
                {t("TEACHER")}
              </a>
            </div>
            <div className={cx("menu-item")}>
              <a
                href="/blog"
                style={{ fontSize: language === "vn" ? "18px" : "20px" }}
                onClick={(e) => {
                  handleClickMenuNavigation(e, "/blog");
                }}
              >
                {t("BLOG")}
              </a>
            </div>
            <div className={cx("menu-item")}>
              <a
                href="/courses/price"
                style={{ fontSize: language === "vn" ? "18px" : "20px" }}
                onClick={(e) => {
                  handleClickMenuNavigation(e, "/courses/price");
                }}
              >
                {t("TUITION")}
              </a>
            </div>
          </nav>
          <div ref={refBoxSettings} className={cx("header-menu-right")}>
            <div className={cx("button-sign-in")}>
              <button
                className={cx("btn-person")}
                onClick={handleClickShowSetting}
              >
                <span>
                  <PersonIcon />
                </span>
              </button>

              <div
                className={cx("settings", `${showSettings ? "active" : ""}`)}
              >
                <div>
                  <span>{isLoginned?(fullName && fullName):"Hello, welcome back"}</span>
                </div>

                <div onClick={handleNavigateAudioDownload}>
                  <span>Watch the audio of the book</span>
                </div>
                {isLoginned ? (
                  <button onClick={handleClickLogout}>
                    <span>Logout</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleClickMenuNavigation(e, "/login")}
                  >
                    <span>{t("SIGN-IN")}</span>
                  </button>
                )}
              </div>
            </div>

            <div
              className={cx("button-cart")}
              onClick={() => {
                router.push("/cart");
              }}
            >
              <button>
                <span>
                  <ShoppingCartIcon />
                </span>
                <div className={cx("quantity-circle")}>
                  {CartItem
                    ? CartItem.reduce((sum: number, curr: CartResponse) => {
                        return sum + 1;
                      }, 0)
                    : 0}
                </div>
              </button>
            </div>
            <div
              className={cx("icon-menu")}
              onClick={handleClickShowMenuNavigation}
            >
              <TableRowsIcon />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
