import axios from 'axios';
import Cookies from 'js-cookie';

export const baseUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

let refreshTokePromise: Promise<any> | null = null;
let isRefreshingToken = false;

const callRefreshToken = async () => {
    if (!isRefreshingToken) {
        isRefreshingToken = true;
        try {
            await axiosInstance.get("/authenticate/refreshToken");
        } catch (error) {
            console.error("Failed to refresh token", error);
            throw error;
        } finally {
            isRefreshingToken = false;
        }
    }
};

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = Cookies.get("accessToken");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            config.headers.Authorization = null;
        }
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (!isRefreshingToken) {
                refreshTokePromise = callRefreshToken();
            }

            try {
                await refreshTokePromise;
                refreshTokePromise = null;

                return axiosInstance(originalRequest);
            } catch (err) {
                refreshTokePromise = null;
                return Promise.reject(err);
            }
        }

        if (error.response.status === 401) {
            const accessToken = Cookies.get("accessToken");
            if (accessToken) {
                // call logout function or handle accordingly
                error.response.message = 'Vui lòng đăng nhập lại';
            }
            error.response.message = "Phiên đăng nhập hết hạn, vui lòng đăng nhập lại";
            return Promise.reject(error.response);
        }

        if (error.response) {
            return Promise.reject(error.response);
        }
        if (error.request) {
            return Promise.reject(error.request);
        }
        return Promise.reject(error.message);
    }
);
