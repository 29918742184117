import React from 'react';
import styles from './Loader.module.scss';
interface PropsStyle{
  color: string;
  width: number;
}

const Loader = ({color,width}:PropsStyle) => {
  return (
    <div className={styles.loader} style={{background: `conic-gradient(#0000 10%,#${color}) content-box`,width:`${width}px`}}></div>
  )
}

export default Loader
