interface EnglishCourses {
  title: string;
  data: {
    id: number;
    value: {
      name: string;
      color: string;
      label: string;
    }[];
  }[];
}

interface IntroductionContentType {
  contentFor: string;
  content: string[];
  contentEN: string[];
}
interface CoursePath {
  lessonName: string;
  communication: string;
  vocabulary: string;
  grammar: string;
  activitiesOther: string;
}

interface CourseContent {
  courseName: string;
  introductionContent: IntroductionContentType[];
  coursePath: {
    level: string;
    courseContent: CoursePath[];
    courseContentEN: CoursePath[];
  }[];
}

interface CoursePathSAT {
  grade: number;
  content: {
    semester: string;
    duration: string;
    courseDetails: {
      subject: string;
      description: string[];
      majorConcepts: string[];
    }[];
  }[];
}

interface SATCourse {
  courseName: string;
  introductionContent: IntroductionContentType[];
  coursePath: CoursePathSAT[];
}

export const EnglishCourseData = () => {
  const data: EnglishCourses[] = [
    {
      title: "courses-type-1",
      data: [
        {
          id: 1,
          value: [
            {
              name: "Learn and play (3~6 y/o)",
              color: "#9358b8",
              label: "ENGLISH-COURSE-LEARN-AND-PLAY",
            },
          ],
        },
        {
          id: 3,
          value: [
            {
              name: "Basic English Pre A1 (8 y/o)",
              color: "#4f673b",
              label: "ENGLISH-COURSE-BASIC-ENGLISH-PRE-A1",
            },
            {
              name: "Basic English A1 (9 y/o)",
              color: "#4f673b",
              label: "ENGLISH-COURSE-BASIC-ENGLISH-A1",
            },
            {
              name: "Basic English A2 (10 y/o)",
              color: "#4f673b",
              label: "ENGLISH-COURSE-BASIC-ENGLISH-A2",
            },
          ],
        },
        {
          id: 4,
          value: [
            {
              name: "Intermediate English Pre B1 (11 y/o)",
              color: "#ca9d2a",
              label: "ENGLISH-COURSE-INTERMEDIATE-ENGLISH-PRE-B1",
            },
            {
              name: "Intermediate English B1 (12 y/o)",
              color: "#ca9d2a",
              label: "ENGLISH-COURSE-INTERMEDIATE-ENGLISH-B1",
            },
            {
              name: "Intermediate English B2 (13 y/o)",
              color: "#ca9d2a",
              label: "ENGLISH-COURSE-INTERMEDIATE-ENGLISH-B2",
            },
          ],
        },
        {
          id: 5,
          value: [
            {
              name: "Advanced English Pre C1 (14 y/o)",
              color: "#295590",
              label: "ENGLISH-COURSE-ADVANCED-ENGLISH-PRE-C1",
            },
            {
              name: "Advanced English C1 (15 y/o)",
              color: "#295590",
              label: "ENGLISH-COURSE-ADVANCED-ENGLISH-C1",
            },
            {
              name: "Advanced English C2 (16 y/o)",
              color: "#295590",
              label: "ENGLISH-COURSE-ADVANCED-ENGLISH-C2",
            },
          ],
        },
      ],
    },
    {
      title: "course-type-2",
      data: [
        {
          id: 1,
          value: [
            {
              name: "Practice English pronunciation",
              color: "#1b1b1b",
              label: "ENGLISH-COURSE-PRACTICE-ENGLISH-PRONUNCIATION",
            },
            {
              name: "Practice English vocabulary",
              color: "#1b1b1b",
              label: "ENGLISH-COURSE-PRACTICE-ENGLISH-VOCABULARY",
            },
            {
              name: "Practice English grammar",
              color: "#1b1b1b",
              label: "ENGLISH-COURSE-PRACTICE-ENGLISH-GRAMMAR",
            },
            {
              name: "Math, Language Arts, Science, Social Studies class (7 - 18y/o)",
              color: "#9358b8",
              label: "ENGLISH-COURSE-PRACTICE-ENGLISH-SAT",
            },
          ],
        },
      ],
    },
  ];
  return data;
};

export const EnglishCourseContentForChildren = () => {
  const data: CourseContent[] = [
    {
      courseName: "Learn and play (3~6 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 3 – 6, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Anh đầy thú vị.",
          ],
          contentEN: [
            "This specialized course is dedicated for children aged 3-6, with rich and varied content, offering a unique and engaging experience.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The teachers of ABBA are highly qualified from prestigious universities such as: National University of Singapore, Institute of Social Sciences and Humanities.",
            "The course design is specifically tailored for each group of study, with the hope of helping students achieve higher education.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Anh, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ vựng nào.",
          ],
          contentEN: [
            "Students will receive a comprehensive vocabulary and basic grammar knowledge to facilitate communication, serve as a foundation for higher education levels.",
            "Vital attention is given to standard pronunciation, as native speakers will be taught from the beginning. After mastering the alphabet and phonetics, students will be encouraged to learn to read fluently from any vocabulary.",
          ],
        },
      ],
      coursePath: [
        {
          level: "1",
          courseContent: [
            {
              lessonName: "Bài 0",
              communication: "Nhìn tranh và đọc theo giáo viên",
              vocabulary: "Từ vựng về giao tiếp",
              grammar: "My name is..",
              activitiesOther: "",
            },
            {
              lessonName: "Bài 1",
              communication: "Nhìn tranh và đọc theo giáo viên, khoanh tròn",
              vocabulary: "Từ vựng về các bộ phận cơ thể, số lượng",
              grammar: "I have …",
              activitiesOther: "Vẽ theo đường có sẵn",
            },
            {
              lessonName: "Bài 2",
              communication: "Nhìn tranh và đọc theo giáo viên, nối hình ",
              vocabulary: "Từ vựng về các vật dụng trong nhà, open và close",
              grammar: "",
              activitiesOther: "Nối hình liên quan nhau",
            },
            {
              lessonName: "Bài 3",
              communication: "Nhìn tranh và đọc theo giáo viên ",
              vocabulary: "Từ vừng về trái cây, số đếm",
              grammar: "",
              activitiesOther: "Nối hình liên quan nhau",
            },
            {
              lessonName: "Bài 4",
              communication: "Nhìn tranh và đọc theo giáo viên ",
              vocabulary: "Từ vựng về màu sắc",
              grammar: "Hiện tại đơn",
              activitiesOther: "Vẽ theo đường có sẵn. tô màu",
            },
            {
              lessonName: "Bài 5",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nối các hình với nhau ",
              vocabulary: "Từ vựng về gia đình",
              grammar: "Cấu trúc Like (don’t like) +Sth",
              activitiesOther: "Vẽ theo đường có sẵn. tô màu, tập đọc ",
            },
            {
              lessonName: "Bài 6",
              communication:
                "Nhìn tranh và đọc theo giáo viên, khoanh tròn chỉ những hình giống nhau",
              vocabulary: "Từ vựng về đồ chơi",
              grammar: "Cấu trúc Like (don’t like) +Sth",
              activitiesOther: "Vẽ theo đường có sẵn. tô màu, tập đọc ",
            },
            {
              lessonName: "Bài 7",
              communication: "Nhìn tranh và đọc theo giáo viên, nối hình",
              vocabulary: "Từ vựng về thú cưng",
              grammar: "Hiện tại đơn",
              activitiesOther: "Nối hình theo số và tô màu",
            },
            {
              lessonName: "Bài 8",
              communication: "Nhìn tranh và đọc theo giáo viên",
              vocabulary: "Từ vựng về sâu bọ và thiên nhiên",
              grammar: "Look at +Sth",
              activitiesOther: "Vẽ theo đường có sẵn. tô màu",
            },
          ],
          courseContentEN: [
            {
              lessonName: "unit 0",
              communication: "Look at the picture and read after the teacher",
              vocabulary: "Vocabulary about communication",
              grammar: "My name is..",
              activitiesOther: "",
            },
            {
              lessonName: "unit 1",
              communication:
                "Look at the picture and read after the teacher, circle",
              vocabulary: "Vocabulary about body parts, quantity",
              grammar: "I have …",
              activitiesOther: "Draw along the available lines",
            },
            {
              lessonName: "unit 2",
              communication:
                "Look at the picture and read after the teacher, connect the pictures",
              vocabulary: "Vocabulary about household items, open and close",
              grammar: "",
              activitiesOther: "Connect related pictures",
            },
            {
              lessonName: "unit 3",
              communication: "Look at the picture and read after the teacher",
              vocabulary: "Fruit vocabulary, counting",
              grammar: "",
              activitiesOther: "Connect related pictures",
            },
            {
              lessonName: "unit 4",
              communication: "Look at the picture and read after the teacher",
              vocabulary: "Colour vocabulary",
              grammar: "Present simple",
              activitiesOther: "Draw along the available lines. Colour",
            },
            {
              lessonName: "unit 5",
              communication:
                "Look at the picture and read after the teacher, connect the pictures together",
              vocabulary: "Family vocabulary",
              grammar: "Like (don’t like) + Sth structure",
              activitiesOther:
                "Draw along the available lines. coloring, reading ",
            },
            {
              lessonName: "unit 6",
              communication:
                "Look at the pictures and read after the teacher, circle the similar pictures",
              vocabulary: "Toy vocabulary",
              grammar: "Like (don’t like) +Sth structure",
              activitiesOther:
                "Draw along the available lines. coloring, reading ",
            },
            {
              lessonName: "unit 7",
              communication:
                "Look at the pictures and read after the teacher, connect the pictures",
              vocabulary: "Vocabulary about pets",
              grammar: "Present simple",
              activitiesOther: "Connect the pictures by numbers and color",
            },
            {
              lessonName: "unit 8",
              communication: "Look at the pictures and read after the teacher",
              vocabulary: "Vocabulary about insects and nature",
              grammar: "Look at +Sth",
              activitiesOther: "Draw along the available lines. color",
            },
          ],
        },
        {
          level: "2",
          courseContent: [
            {
              lessonName: "unit 0",
              communication: "Nhìn tranh và đọc các mẫu câu nhờ vả ",
              vocabulary: "",
              grammar: "Trợ động từ Can, May",
              activitiesOther: "",
            },
            {
              lessonName: "unit 1",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nối hình giống nhau",
              vocabulary:
                "Từ vựng về bảng, kéo, bút màu, Các giới từ in, on, under.",
              grammar: "",
              activitiesOther:
                "Tìm các vật dụng theo từ đã học, tô màu, thảo luận",
            },
            {
              lessonName: "unit 2",
              communication:
                "Nhìn tranh và đọc theo giáo viên, tìm và khoanh tròn",
              vocabulary: "Từ vựng về các hoạt động ngoài công viên",
              grammar: "Let’s + V",
              activitiesOther: "Nhìn tranh và chọn up hoặc down, nối hình",
            },
            {
              lessonName: "unit 3",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary: "Từ vựng về thức ăn",
              grammar: "Like (Don’t Like) + Sth, Hiện tại đơn",
              activitiesOther:
                "Tô màu các con vật, nghe và nối hình ảnh với nhau",
            },
            {
              lessonName: "unit 4",
              communication: "Nhìn tranh và đọc theo giáo viên, nối hình",
              vocabulary: "Từ vựng về động vật chăn nuôi",
              grammar: "",
              activitiesOther:
                "Đếm số lượng các con vât và khoanh tròn, nghe và nối hình ảnh và tập đọc",
            },
            {
              lessonName: "unit 5",
              communication: "Nhìn tranh và đọc theo giáo viên, nối hình",
              vocabulary: "Từ vựng về quần áo phụ kiện",
              grammar: "",
              activitiesOther: "Nối hình liên quan với nhau và tập đọc, tô màu",
            },
            {
              lessonName: "unit 6",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary: "Từ vựng về côn trùng",
              grammar: "Hiện tại đơn",
              activitiesOther: "Nối số và tô màu",
            },
            {
              lessonName: "unit 7",
              communication:
                "Nhìn tranh đọc theo giáo viên, nối các biểu cảm giống nhau",
              vocabulary: "Từ vựng về biểu cảm trên khuôn mặt",
              grammar: "Hiện tại đơn",
              activitiesOther:
                "Nhìn hình và đoán biểu cảm, tìm hình khác với các hình còn lại",
            },
            {
              lessonName: "unit 8",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary: "Từ vựng về phương tiện di chuyển, fast và slow",
              grammar: "",
              activitiesOther: "Nối hình và tô màu",
            },
          ],
          courseContentEN:[
            {
            lessonName: "unit 0",
            communication: "Look at the pictures and read the sentences asking for help",
            vocabulary: "",
            grammar: "Auxiliary verbs Can, May",
            activitiesOther: "",
            },
            {
            lessonName: "unit 1",
            communication:
            "Look at the pictures and read after the teacher, match the similar pictures",
            vocabulary:
            "Vocabulary about boards, scissors, crayons, Prepositions in, on, under.",
            grammar: "",
            activitiesOther:
            "Find the items according to the words learned, color, discuss",
            },
            {
            lessonName: "unit 2",
            communication:
            "Look at the pictures and read after the teacher, find and circle",
            vocabulary: "Vocabulary about outdoor activities in the park",
            grammar: "Let's + V",
            activitiesOther: "Look at the pictures and choose up or down, match the pictures",
            },
            {
            lessonName: "unit 3",
            communication:
            "Look at the pictures and read after the teacher, listen and circle",
            vocabulary: "Vocabulary about food",
            grammar: "Like (Don’t Like) + Sth, Present Simple",
            activitiesOther:
            "Color the animals, listen and match the pictures",
            },
            {
            lessonName: "unit 4",
            communication: "Look at the pictures and read after the teacher, match the pictures",
            vocabulary: "Vocabulary about livestock",
            grammar: "",
            activitiesOther:
            "Count the number of animals and circle, listen and match the pictures and practice reading",
            },
            {
            lessonName: "unit 5",
            communication: "Look at the pictures and read after the teacher, match the pictures",
            vocabulary: "Vocabulary about clothing and accessories",
            grammar: "",
            activitiesOther: "Connect related pictures and practice reading, coloring",
            },
            {
            lessonName: "unit 6",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary: "Vocabulary about insects",
            grammar: "Present simple",
            activitiesOther: "Connect numbers and color",
            },
            {
            lessonName: "unit 7",
            communication:
            "Look at the picture and read after the teacher, match similar expressions",
            vocabulary: "Vocabulary about facial expressions",
            grammar: "Present simple",
            activitiesOther:
            "Look at the picture and guess the expression, find the picture that is different from the rest",
            },
            {
            lessonName: "unit 8",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary: "Vocabulary about means of transport, fast and slow",
            grammar: "",
            activitiesOther: "Connect pictures and color",
            },
            ],
        },
        {
          level: "3",
          courseContent: [
            {
              lessonName: "unit 0",
              communication:
                "Nhìn tranh và đọc theo giáo viên, Tìm và khoanh tròn",
              vocabulary: "",
              grammar: "Let’s … ",
              activitiesOther: "",
            },
            {
              lessonName: "unit 1",
              communication: " Nhìn tranh và đọc theo giáo viên, Nối hình",
              vocabulary: "Từ vựng về các khối hình, Chữ cái a,b,c",
              grammar: "Stand up, Sit down",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó, nghe và đọc",
            },
            {
              lessonName: "unit 2",
              communication: "Nhìn tranh và đọc theo giáo viên, Nối hình",
              vocabulary: "Từ vựng về thời tiết, Chữ cái D - E - F",
              grammar: "Hiện tại tiếp diễn",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó, nghe và đọc",
            },
            {
              lessonName: "unit 3",
              communication:
                "Nhìn tranh và đọc theo giáo viên, tìm hình và khoanh tròn",
              vocabulary:
                "Từ vựng liên quan đến động vật hoang dã, chữ cái G - H - I ",
              grammar: "",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó,nghe và đọc",
            },
            {
              lessonName: "unit 4",
              communication: "Nhìn tranh và đọc theo giáo viên, khoanh tròn",
              vocabulary: "Từ vựng về nhạc cụ, chữ cái j, k, l",
              grammar: "",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó,nghe và đọc",
            },
            {
              lessonName: "unit 5",
              communication:
                "Nhìn tranh và đọc theo giáo viên. nối các hình liên quan",
              vocabulary: "Từ vựng về giác quan, chữ cái m, n, o",
              grammar: "",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó,nghe và đọc",
            },
            {
              lessonName: "unit 6",
              communication:
                "Nhìn tranh và đọc theo giáo viên, khoanh tròn hình khác nhau ",
              vocabulary: "Từ vựng về nhân vật trong cổ tích, chữ cái p, q, r",
              grammar: "",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó,nghe và đọc",
            },
            {
              lessonName: "unit 7",
              communication: "Nhìn tranh và đọc theo giáo viên",
              vocabulary: "Từ vựng về bữa tiệc, chữ cái s, t, u, v,",
              grammar: "Hiện faij đơn, less, more",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó,nghe và đọc",
            },
            {
              lessonName: "unit 8",
              communication:
                "Nhìn tranh và đọc theo giáo viên về các danh từ chỉ thiên nhiên, hình liên quan",
              vocabulary:
                "Từ vựng về trái đất, thiên nhiên, chữ cái w, x, y, z",
              grammar: "",
              activitiesOther:
                "Tập viết chữ cái và các từ chứa chữ cái đó,nghe và đọc",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 0",
            communication:
            "Look at the pictures and read after the teacher, Find and circle",
            vocabulary: "",
            grammar: "Let's … ",
            activitiesOther: "",
            },
            {
            lessonName: "unit 1",
            communication: "Look at the pictures and read after the teacher, Match the pictures",
            vocabulary: "Vocabulary about shapes, Letters a, b, c",
            grammar: "Stand up, Sit down",
            activitiesOther:
            "Practice writing letters and words containing those letters, listen and read",
            },
            {
            lessonName: "unit 2",
            communication: "Look at the pictures and read after the teacher, Match the pictures",
            vocabulary: "Vocabulary about weather, Letters D - E - F",
            grammar: "Present continuous",
            activitiesOther:
            "Practice writing letters and words containing those letters, listen and read",
            },
            {
            lessonName: "unit 3",
            communication:
            "Look at the pictures and read after the teacher, Match the pictures\", teacher, find the picture and circle it",
            vocabulary:
            "Vocabulary related to wild animals, letters G - H - I",
            grammar: "",
            activitiesOther:
            "Practice writing letters and words containing those letters, listen and read",
            },
            {
            lessonName: "unit 4",
            communication: "Look at the picture and read after the teacher, circle it",
            vocabulary: "Vocabulary about musical instruments, letters j, k, l",
            grammar: "",
            activitiesOther:
            "Practice writing letters and words containing those letters, listen and read",
            },
            {
            lessonName: "unit 5",
            communication:
            "Look at the picture and read after the teacher. connect related pictures",
            vocabulary: "Vocabulary about senses, letters m, n, o",
            grammar: "",
            activitiesOther:
            "Practice writing letters and words containing those letters, listen and read",
            },
            {
            lessonName: "unit 6",
            communication:
            "Look at the pictures and read after the teacher, circle the different pictures",
            vocabulary: "Vocabulary about characters in fairy tales, letters p, q, r",
            grammar: "",
            activitiesOther:
            "Practice writing letters and words containing those letters, listen and read",
            },
            {
            lessonName: "unit 7",
            communication: "Look at the pictures and read after the teacher",
            vocabulary: "Vocabulary about parties, letters s, t, u, v",
            grammar: "Present simple form, less, more",
            activitiesOther:
            "Practice writing letters and words containing those letters, listen and read",
            },
            {
            lessonName: "unit 8",
            communication:
            "Look at the pictures and read with the teacher about the nouns referring to nature, related pictures",
            vocabulary:
            "Vocabulary about the earth, nature, letters w, x, y, z",
            grammar: "",
            activitiesOther:
            "Practice writing letters and words containing those letters, listening and reading",
            },
            ],
        },
      ],
    },
    {
      courseName: "Basic English Pre A1 (8 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 8 - 10, Học viên muốn tiếp cận với môi trường tiếng Anh.",
            "Học viên muốn củng cố lại kiến thức ở trình độ PRE A1.",
          ],
          contentEN: [
            "Specialized program for children aged 8 - 10, Students who want to access an English environment.",
            "Students want to consolidate their knowledge at PRE A1 level.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "Team of English teachers from prestigious universities like: National University of Singapore, National University of Khoa Hoc Tay Ninh.",
            "Designed individualized courses according to the student's needs, hope for their improvement.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Đọc hiểu những câu ngắn, đoạn văn ngắn liên quan đến những chủ đề quen thuộc như động vật, nơi chốn, nghề nghiệp,...",
            "Trang bị các kỹ năng làm các bài tập về viết lại từ vựng, viết lại câu, đúng hay sai, sử dụng giới từ chỉ thời gian và nơi chốn, đại từ chỉ định, đại từ ,…",
            "Sử dụng ngôn ngữ linh hoạt và hiệu quả khi nói về các chủ đề quen thuộc hằng ngày liên quan đến trường học, gia đình, thức ăn, màu sắc,...",
          ],
          contentEN: [
            "Read and understand short sentences and short paragraphs related to familiar topics such as animals, places, occupations,...",
            "Equip skills to do exercises on rewriting vocabulary, rewriting sentences, true or false, using prepositions of time and place, demonstrative pronouns, pronouns,...",
            "Use language flexibly and effectively when talking about familiar everyday topics related to school, family, food, colors,...",
          ],
        },
      ],
      coursePath: [
        {
          level: "1",
          courseContent: [
            {
              lessonName: "unit 0",
              communication:
                "Các mẫu câu giao tiếp đơn giản, đọc theo giáo viên",
              vocabulary: "Từ vựng sit down, stand up, open, close",
              grammar: "Mẫu câu giới thiệu, giao iép căn bản",
              activitiesOther: "Nghe từ và điền vào khung",
            },
            {
              lessonName: "unit 1",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary:
                "Từ vựng về dụng cụ học tập, trường lớp, bàn ghế, số đếm,..",
              grammar: "I have…, Let’s + V",
              activitiesOther:
                "Nghe từ và điền vào khung, tô màu, nghe mẩu chuyện nhỏ ",
            },
            {
              lessonName: "unit 2",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary: "Từ vựng về đồ chơi, số đếm,..",
              grammar: "Hiện tại đơn, Đại từ chỉ định: This",
              activitiesOther:
                "Nghe từ và điền vào khung, tô màu, nghe mẩu chuyện nhỏ",
            },
            {
              lessonName: "unit 3",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary: "Từ vựng về gia đình, số đếm,..",
              grammar: "Who, Where, How many",
              activitiesOther:
                "Nghe từ và điền vào khung, tô màu, nghe mẩu chuyện nhỏ",
            },
            {
              lessonName: "unit 4",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary: "Từ vựng về bộ phận cơ thể, số đếm, màu sắc,..",
              grammar: "Thì hiện tại đơn, Mẫu câu sử dụng hav/has",
              activitiesOther:
                "Nghe từ và điền vào khung, tô màu, nghe mẩu chuyện nhỏ",
            },
            {
              lessonName: "unit 5",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary:
                "Từ vựng về nhân vật trong cổ tích, biểu cảm khuôn mặt, tập đếm số lượng,..",
              grammar: "Cấu trúc Want",
              activitiesOther:
                "Nhìn tranh, nghe và chọn yes hoặc no. Nghe từ và điền vào khung, tô màu, nghe mẩu chuyện nhỏ ",
            },
            {
              lessonName: "unit 6",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary:
                "Từ vựng về thức ăn, trái cây, hình tòn hình vuông,..",
              grammar: "Like (don’t like) + sth",
              activitiesOther:
                "Nghe từ và điện vào khung, tô màu, nghe mẩu chuyện nhỏ",
            },
            {
              lessonName: "unit 7",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary:
                "Từ vựng về quần áo phụ kiện, hình chữ nhật, tam giác,..",
              grammar: "Thì hiện tại tiếp diễn",
              activitiesOther:
                "Nghe từ và điện vào khung, tô màu, nghe mẩu chuyện nhỏ",
            },
            {
              lessonName: "unit 8",
              communication:
                "Nhìn tranh và đọc theo giáo viên, nghe và khoanh tròn",
              vocabulary:
                "Từ vựng về động vật hoang dã, hình trái tim, ngôi sao,..",
              grammar: "Hiện tại đơn, Hiện tại tiếp diễn",
              activitiesOther:
                "Nhìn tranh, nghe và chọn yes hoặc no. Nghe từ và điện vào khung, tô màu, nghe mẩu chuyện nhỏ",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 0",
            communication:
            "Simple communication sentences, read after the teacher",
            vocabulary: "Vocabulary sit down, stand up, open, close",
            grammar: "Basic introduction and communication sentences",
            activitiesOther: "Listen to the words and fill in the box",
            },
            {
            lessonName: "unit 1",
            communication:
            "Look at the pictures and read after the teacher, listen and circle",
            vocabulary:
            "Vocabulary about school supplies, schools, desks and chairs, numbers,..",
            grammar: "I have…, Let's + V",
            activitiesOther:
            "Listen to the words and fill in the box, color, listen to a short story",
            },
            {
            lessonName: "unit 2",
            communication:
            "Look at the pictures and read after the teacher, listen and circle",
            vocabulary: "Vocabulary about toys, numbers,..",
            grammar: "Present simple, Demonstrative pronoun: This",
            activitiesOther:
            "Listen words and fill in the box, color, listen to a short story",
            },
            {
            lessonName: "unit 3",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary: "Vocabulary about family, counting numbers,..",
            grammar: "Who, Where, How many",
            activitiesOther:
            "Listen to the word and fill in the box, color, listen to a short story",
            },
            {
            lessonName: "unit 4",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary: "Vocabulary about body parts, counting numbers, colors,..",
            grammar: "Present tense, Sentence patterns using hav/has",
            activitiesOther:
            "Listen to the word and fill in the box, color, listen to a short story",
            },
            {
            lessonName: "unit 5",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary:
            "Vocabulary about characters in fairy tales, facial expressions, practice counting quantity,..",
            grammar: "Want structure",
            activitiesOther:
            "Look at the pictures, listen and choose yes or no. Listen to the words and fill in the box, color, listen to the short story ",
            },
            {
            lessonName: "unit 6",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary:
            "Vocabulary about food, fruit, circle, square,..",
            grammar: "Like (don’t like) + sth",
            activitiesOther:
            "Listen to the words and fill in the box, color, listen to the short story",
            },
            {
            lessonName: "unit 7",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary:
            "Vocabulary about clothes, accessories, rectangles, triangles,..",
            grammar: "Present continuous tense",
            activitiesOther:
            "Listen to the words and fill in the box, color, listen to the short story",
            },
            {
            lessonName: "unit 8",
            communication:
            "Look at the picture and read after the teacher, listen and circle",
            vocabulary:
            "Vocabulary about animals wild, heart-shaped, star,..",
            grammar: "Present Simple, Present Continuous",
            activitiesOther:
            "Look at the picture, listen and choose yes or no. Listen to the word and put it in the box, color, listen to a short story",
            },
            ]
        },
      ],
    },
    {
      courseName: "Basic English A1 (9 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp Pre A1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 8 - 10.",
            "Học viên muốn củng cố lại kiến thức ở trình độ A1.",
          ],
          contentEN: [
            "Students have completed Pre A1 class",
            "Specialized program for children aged 8 - 10.",
            "Students want to consolidate their knowledge at level A1.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Đọc hiểu những câu ngắn, đoạn văn ngắn liên quan đến những chủ đề quen thuộc như động vật, nơi chốn, nghề nghiệp,...",
            "Trang bị các kỹ năng làm các bài tập về viết lại từ vựng, viết lại câu, đúng hay sai, sử dụng giới từ chỉ thời gian và nơi chốn, đại từ chỉ định, đại từ ,…",
            "Sử dụng ngôn ngữ linh hoạt và hiệu quả khi nói về các chủ đề quen thuộc hằng ngày liên quan đến trường học, gia đình, thức ăn, màu sắc,...",
          ],
          contentEN: [
            "Understand short, paragraph shorts related to familiar topics such as animals, places, professions,...",
            "Acquire language skills and practical applications when speaking about daily life topics such as family, food, colors,...",
            "Use natural language and practical results when discussing familiar topics in school, family, and daily life.",
          ],
        },
      ],
      coursePath: [
        {
          level: "2",
          courseContent: [
            {
              lessonName: "unit 0",
              communication:
                "Nhìn tranh chỉ và đọc tên các danh từ chỉ thành viên trong gia đình",
              vocabulary: "Các cụm từ chỉ hành động",
              grammar: "",
              activitiesOther: "Trò chơi với các con số",
            },
            {
              lessonName: "unit 1",
              communication:
                "Nhìn hình và trả lời câu hỏi Yes,No về các con vật ",
              vocabulary: "Từ vựng về các con vật ",
              grammar: "Thì hiện tại tiếp diễn",
              activitiesOther: "Cắt thẻ trang 97. Hỏi và trả lời",
            },
            {
              lessonName: "unit 2",
              communication:
                "Nhìn tranh trả lời câu hỏi Yes, No về các hoạt động",
              vocabulary: "Từ vựng về hoạt động của con người",
              grammar: "Thì hiện tại tiếp diễn",
              activitiesOther: "Đọc và trả lời câu hỏi ",
            },
            {
              lessonName: "unit 3",
              communication:
                "Nhìn tranh và trả lời câu hỏi Yes, No về trang phục",
              vocabulary: "Từ vựng về trang phục",
              grammar: "Hỏi về thời tiết ",
              activitiesOther: "Cắt thẻ trang 101 và hỏi bạn bè ",
            },
            {
              lessonName: "unit 4",
              communication: "Nhìn tranh trả lời câu hỏi Yes, No",
              vocabulary: "Từ vựng về các hoạt động",
              grammar: "Hỏi về sở thích",
              activitiesOther: "Cắt thẻ trang 103 và chơi với bạn bè",
            },
            {
              lessonName: "unit 5",
              communication:
                "Nhìn tranh và trả lời câu hỏi Yes, No về hiện tượng tự nhiên",
              vocabulary: "Từ vựng về các vật dụng trong gia đình",
              grammar: "Học về giới từ",
              activitiesOther: "Cắt thẻ trang 105 và chơi với bạn bè",
            },
            {
              lessonName: "unit 6",
              communication:
                "Nhìn tranh và trả lời câu hỏi Yes, No về các vật dụng trong gia đình",
              vocabulary:
                "Cụm từ vựng diễn tả các hoạt động diễn ra hằng ngày ",
              grammar: "Thì hiện tại đơn và các trạng từ chỉ tần suất ",
              activitiesOther: "Đọc và trả lời các câu hỏi với bạn bè",
            },
            {
              lessonName: "unit 7",
              communication: "Nhìn tranh và trả lời câu hỏi Yes, No",
              vocabulary: "Từ vựng diễn tả cảm xúc, tâm trạng, trạng thái",
              grammar: "Hỏi về tâm trạng, miêu tả người",
              activitiesOther: "Cắt thẻ trang 109 và chơi với bạn bè",
            },
            {
              lessonName: "unit 8",
              communication:
                "Nhìn tranh và trả lời câu hỏi Yes, No về hoạt động của con vật",
              vocabulary: "Danh từ chỉ cách gọi các con vật trong tự nhiên",
              grammar: "Thể khả năng, ai đó có thể biết làm gì đó",
              activitiesOther: "Cắt thẻ trang 101 và chơi với bạn bè.",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 0",
            communication:
            "Look at the pictures and read the names of the nouns referring to family members",
            vocabulary: "Action phrases",
            grammar: "",
            activitiesOther: "Games with numbers",
            },
            {
            lessonName: "unit 1",
            communication:
            "Look at the pictures and answer Yes, No questions about animals",
            vocabulary: "Vocabulary about animals",
            grammar: "Present continuous tense",
            activitiesOther: "Cut out the cards on page 97. Ask and answer",
            },
            {
            lessonName: "unit 2",
            communication:
            "Look at the pictures and answer Yes, No questions about activities",
            vocabulary: "Vocabulary about human activities",
            grammar: "Present continuous tense",
            activitiesOther: "Read and answer the questions",
            },
            {
            lessonName: "unit 3",
            communication:
            "Look at the pictures and answer Yes, No questions about activities, No about clothes",
            vocabulary: "Vocabulary about clothes",
            grammar: "Ask about the weather",
            activitiesOther: "Cut out the cards on page 101 and ask your friends",
            },
            {
            lessonName: "unit 4",
            communication: "Look at the pictures and answer Yes, No questions",
            vocabulary: "Vocabulary about activities",
            grammar: "Ask about hobbies",
            activitiesOther: "Cut out the cards on page 103 and play with your friends",
            },
            {
            lessonName: "unit 5",
            communication:
            "Look at the pictures and answer Yes, No questions about natural phenomena",
            vocabulary: "Vocabulary about household items",
            grammar: "Learn about prepositions",
            activitiesOther: "Cut out the cards on page 105 and play with your friends",
            },
            {
            lessonName: "unit 6",
            communication:
            "Look at the pictures and answer Yes, No questions about household items",
            vocabulary:
            "Vocabulary phrases describing daily activities ",
            grammar: "Present simple tense and adverbs of frequency",
            activitiesOther: "Read and answer questions with friends",
            },
            {
            lessonName: "unit 7",
            communication: "Look at the pictures and answer Yes, No questions",
            vocabulary: "Vocabulary describing emotions, moods, states",
            grammar: "Ask about moods, describe people",
            activitiesOther: "Cut out the cards on page 109 and play with friends",
            },
            {
            lessonName: "unit 8",
            communication:
            "Look at the pictures and answer Yes, No questions about animal activities",
            vocabulary: "Nouns that refer to how animals are called in nature",
            grammar: "Ability, someone can do something",
            activitiesOther: "Cut out the cards on page 101 and play with friends.",
            },
            ]
        },
        {
          level: "3",
          courseContent: [
            {
              lessonName: "unit 0",
              communication: "Nhìn tranh nghe và đọc theo giáo viên",
              vocabulary: "Từ vựng về mùa và tháng, số đếm",
              grammar: "Hỏi về tính từ  sở hữu( vật này sở hữu của ai?)",
              activitiesOther: "Làm bài tập về Object, tính từ sở hữu",
            },
            {
              lessonName: "unit 1",
              communication:
                "Nhìn tranh chọn đáp án a, b về các địa điểm trên thế giới",
              vocabulary: "Học các danh từ về nghề nghiệp ",
              grammar: "Cách hỏi ai đó làm công việc gì?",
              activitiesOther:
                "Thảo luận với bạn về chủ đề bạn muốn làm gì nếu là Photograph ",
            },
            {
              lessonName: "unit 2",
              communication: "Nhìn tranh và xếp lại trật tự từ",
              vocabulary: "Từ vựng về rau củ quả ",
              grammar: "Học về “any”",
              activitiesOther:
                "Tìm sự khác nhau giữa 2 tranh. Cắt tranh trang 99 và chơi với bạn bè ",
            },
            {
              lessonName: "unit 3",
              communication: "Nhìn tranh và chọn đáp án a,b",
              vocabulary: "Từ vựng về động từ thường gặp hằng ngày",
              grammar: "Học về “before”  “after” và tính từ chỉ tần suất",
              activitiesOther:
                "Nhìn tranh và tập nói với bạn + cắt thẻ trang 101 để chơi với bạn",
            },
            {
              lessonName: "unit 4",
              communication: "Nhìn tranh đoán khu vực",
              vocabulary: "Từ vựng về các địa điểm",
              grammar: "Cách nhờ sự trợ giúp",
              activitiesOther: "Cắt thẻ trang 103 và chơi với bạn ",
            },
            {
              lessonName: "unit 5",
              communication: "Nhìn tranh và chọn True hoặc False",
              vocabulary: "Từ vựng về các phương tiện",
              grammar: "Cách nói tôi cũng vậy, tôi không",
              activitiesOther:
                "Nói về gia đình và bạn của bạn. Cắt thẻ trang 105 và chơi với bạn",
            },
            {
              lessonName: "unit 6",
              communication: "Nhìn tranh và chọn a hoặc b",
              vocabulary: "Tính từ miêu tả tính chất của sự vật ",
              grammar: "Câu hỏi về giác quan, cảm giác, mùi vị",
              activitiesOther:
                "Miêu tả tranh cắt vòng quay trang 107 và ghép câu",
            },
            {
              lessonName: "unit 7",
              communication: "Nhìn hình và chọn a hoặc b",
              vocabulary: "Từ vựng về các hiện tượng trong tự nhiên",
              grammar: "Ngữ pháp với “to” chỉ mục đích",
              activitiesOther: "Cắt hộp trang 109 và chơi với bạn bè",
            },
            {
              lessonName: "unit 8",
              communication: "Nhìn hình và chọn đáp án a hoặc b",
              vocabulary: "Từ vựng về các loại thực phẩm",
              grammar: "Ngữ pháp với “some” và “any”",
              activitiesOther: "Quay vòng quay may mắn và đoán nghĩa của từ ",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 0",
            communication: "Look at the picture, listen and read after the teacher",
            vocabulary: "Vocabulary about seasons and months, counting",
            grammar: "Ask about possessive adjectives (who owns this thing?)",
            activitiesOther: "Do exercises about Objects, possessive adjectives",
            },
            {
            lessonName: "unit 1",
            communication:
            "Look at the picture, choose answers a, b about places in the world",
            vocabulary: "Learn nouns about occupations",
            grammar: "How to ask someone what job they do?",
            activitiesOther:
            "Discuss with your partner about the topic of what you would like to do if you were a Photograph",
            },
            {
            lessonName: "unit 2",
            communication: "Look at the picture and rearrange the word order",
            vocabulary: "Vocabulary about vegetables and fruits",
            grammar: "Learn about “any”",
            activitiesOther:
            "Find the difference between the 2 pictures. Cut out the picture on page 99 and play with friends ",
            },
            {
            lessonName: "unit 3",
            communication: "Look at the picture and choose the answer a, b",
            vocabulary: "Vocabulary about common daily verbs",
            grammar: "Learn about \"before\" \"after\" and frequency adjectives",
            activitiesOther:
            "Look at the picture and practice talking with friends + cut out the card on page 101 to play with friends",
            },
            {
            lessonName: "unit 4",
            communication: "Look at the picture and guess the area",
            vocabulary: "Vocabulary about places",
            grammar: "How to ask for help",
            activitiesOther: "Cut out the card on page 103 and play with friends ",
            },
            {
            lessonName: "unit 5",
            communication: "Look at the picture and choose True or False",
            vocabulary: "Vocabulary about means of transport",
            grammar: "How to say me too, I don't",
            activitiesOther:
            "Talk about your family and friends. Cut out the card on page 105 and play with your friends",
            },
            {
            lessonName: "unit 6",
            communication: "Look at the picture and choose a or b",
            vocabulary: "Adjectives describing the properties of things",
            grammar: "Questions about senses, feelings, and tastes",
            activitiesOther:
            "Describe the spinning wheel picture on page 107 and make sentences",
            },
            {
            lessonName: "unit 7",
            communication: "Look at the picture and choose a or b",
            vocabulary: "Vocabulary about natural phenomena",
            grammar: "Grammar with “to” indicating purpose",
            activitiesOther: "Cut out the box on page 109 and play with your friends",
            },
            {
            lessonName: "unit 8",
            communication: "Look at the picture and choose a or b",
            vocabulary: "Vocabulary about foods",
            grammar: "Grammar with “some” and “any”",
            activitiesOther: "Spin the lucky wheel and guess the meaning of the word ", 
          }, 
        ]
        },
        {
          level: "4",
          courseContent: [
            {
              lessonName: "unit 1",
              communication: "Nhìn tranh chọn True, False và trả lời câu hỏi",
              vocabulary: "Từ vựng các bộ phận của cơ thể",
              grammar:
                "Câu khẳng định, phủ định và nghi vấn với thì quá khứ, ngữ pháp với enough và too",
              activitiesOther:
                "Quay vòng quay  và trả lời câu hỏi. Cắt thẻ trang 97 và chơi với bạn bè",
            },
            {
              lessonName: "unit 2",
              communication: "Nhìn tranh và lựa chọn câu trả lời",
              vocabulary: "Từ vựng về lễ hội",
              grammar: "Ôn lại thì quá khứ ",
              activitiesOther: "Đọc phần giải thích và trả lời câu hỏi",
            },
            {
              lessonName: "unit 3",
              communication: "Nhìn tranh và trả lời câu hỏi",
              vocabulary: "Từ vựng về tính từ chỉ tính chất của sự việc",
              grammar: "Ôn thì quá khứ",
              activitiesOther: "Cắt game trang 101 và chơi với bạn",
            },
            {
              lessonName: "unit 4",
              communication: "Nhìn tranh và trả lời câu hỏi",
              vocabulary: "Từ vựng về đặc điểm của cơ thể",
              grammar: "So sánh hơn (tính từ ngắn)",
              activitiesOther: "Cắt thẻ trang 103 và chơi với bạn bè",
            },
            {
              lessonName: "unit 5",
              communication: "Nhìn tranh và trả lời câu hỏi",
              vocabulary: "Từ vựng về nông nghiệp",
              grammar: "Cấu trúc have -has và câu mời would you like",
              activitiesOther: "",
            },
            {
              lessonName: "unit 6",
              communication: "Nhìn tranh và chọn câu trả lời a hoặc b",
              vocabulary: "Từ vựng về tính từ chỉ tính chất của sự vật",
              grammar: "So sánh hơn( tính từ dài)",
              activitiesOther: "Cắt thẻ trang 107 và chơi với bạn",
            },
            {
              lessonName: "unit 7",
              communication: "Nhìn tranh và chọn 3 đáp án",
              vocabulary: "Từ vựng về các căn bệnh",
              grammar: "Should và đại từ phản thân",
              activitiesOther: "Cắt thẻ trang 109 và chơi với bạn",
            },
            {
              lessonName: "unit 8",
              communication: "Nhìn tranh trả lời True, False và các câu hỏi",
              vocabulary: "Từ vựng về thể thao",
              grammar: "So sánh nhất",
              activitiesOther: "Cắt thẻ trang 111 và chơi với bạn",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 1",
            communication: "Look at the picture, choose True, False and answer the questions",
            vocabulary: "Vocabulary of body parts",
            grammar:
            "Affirmative, negative and interrogative sentences with past tense, grammar with enough and too",
            activitiesOther:
            "Spin the wheel and answer the questions. Cut out the card on page 97 and play with friends",
            },
            {
            lessonName: "unit 2",
            communication: "Look at the picture and choose the answer",
            vocabulary: "Vocabulary about festivals",
            grammar: "Review the past tense",
            activitiesOther: "Read the explanation and answer the questions",
            },
            {
            lessonName: "unit 3",
            communication: "Look at the picture and answer the questions",
            vocabulary: "Vocabulary about adjectives indicating the nature of things",
            grammar: "Review the past tense",
            activitiesOther: "Cut out the game on page 101 and play with friends",
            },
            {
            lessonName: "unit 4",
            communication: "Look at the picture and answer the questions",
            vocabulary: "Vocabulary about body characteristics",
            grammar: "Comparative (short adjectives)",
            activitiesOther: "Cut out the cards on page 103 and play with friends",
            },
            {
            lessonName: "unit 5",
            communication: "Look at the picture and answer the questions",
            vocabulary: "Vocabulary about agriculture",
            grammar: "The have-has structure and the invitation would you like",
            activitiesOther: "",
            },
            {
            lessonName: "unit 6",
            communication: "Look at the picture and choose answer a or b",
            vocabulary: "Vocabulary about adjectives describing the properties of things",
            grammar: "Comparative (long adjectives)",
            activitiesOther: "Cut out the cards on page 107 and play with friends",
            },
            {
            lessonName: "unit 7",
            communication: "Look at the picture and choose 3 answers",
            vocabulary: "Vocabulary about diseases",
            grammar: "Should and reflexive pronouns",
            activitiesOther: "Cut out the cards on page 109 and play with your friends",
            },
            {
            lessonName: "unit 8",
            communication: "Look at the pictures and answer True, False and the questions",
            vocabulary: "Sports vocabulary",
            grammar: "Superlatives",
            activitiesOther: "Cut out the cards on page 111 and play with your friends",
            },
            ]
        },
        {
          level: "5",
          courseContent: [
            {
              lessonName: "unit 1",
              communication: "Xếp các chữ cái theo đúng từ",
              vocabulary: "Từ vựng về con vật",
              grammar: "Thể khả năng",
              activitiesOther: "Làm bài tập",
            },
            {
              lessonName: "unit 2",
              communication:
                "Khoanh tròn từ có nghĩa theo hàng ngang hoặc hàng dọc",
              vocabulary: "Từ vựng về sáng kiến",
              grammar: "used to",
              activitiesOther: "Làm bài tập",
            },
            {
              lessonName: "unit 3",
              communication: "Xếp các chữ cái theo đúng từ ",
              vocabulary: "Từ vựng về đồ chơi",
              grammar: "Điền Object cho phù hợp",
              activitiesOther: "Làm bài tập",
            },
            {
              lessonName: "unit 4",
              communication:
                "Khoanh tròn từ có nghĩa theo hàng ngang hoặc hàng dọc",
              vocabulary: "Từ vựng về các hoạt động",
              grammar: "So sánh hơn",
              activitiesOther: "Làm bài tập",
            },
            {
              lessonName: "unit 5",
              communication: "Trả lời câu hỏi để điền từ vào ô trống",
              vocabulary: "Từ vựng về thời tiết",
              grammar: "Mệnh đề If",
              activitiesOther: "Làm bài tập",
            },
            {
              lessonName: "unit 6",
              communication:
                "Khoanh tròn từ có nghĩa theo hàng ngang hoặc hàng dọc",
              vocabulary: "Từ vựng về động vật",
              grammar: "Câu hỏi đuôi",
              activitiesOther: "Làm bài tập",
            },
            {
              lessonName: "unit 7",
              communication: "Nghe và điền từ vào ô cho thích hợp",
              vocabulary: "Từ vựng về nhạc cụ",
              grammar: "Thì quá khứ",
              activitiesOther: "Làm bài tập",
            },
            {
              lessonName: "unit 8",
              communication: "Trả lời câu hỏi để điền từ vào ô trống",
              vocabulary: "Từ vựng chủ đề khám phá",
              grammar: "Might và may",
              activitiesOther: "Làm bài tập",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 1",
            communication: "Put the letters in the right order",
            vocabulary: "Animal vocabulary",
            grammar: "Possible form",
            activitiesOther: "Do exercises",
            },
            {
            lessonName: "unit 2",
            communication:
            "Circle the meaningful words horizontally or vertically",
            vocabulary: "Innovation vocabulary",
            grammar: "used to",
            activitiesOther: "Do exercises",
            },
            {
            lessonName: "unit 3",
            communication: "Put the letters in the right order",
            vocabulary: "Toy vocabulary",
            grammar: "Fill in the appropriate Object",
            activitiesOther: "Do exercises",
            },
            {
            lessonName: "unit 4",
            communication:
            "Circle the meaningful words horizontally or vertically",
            vocabulary: "Activity vocabulary",
            grammar: "Comparative",
            activitiesOther: "Do exercises",
            },
            {
            lessonName: "unit 5",
            communication: "Answer the questions to fill in the blanks",
            vocabulary: "Weather vocabulary",
            grammar: "If clause",
            activitiesOther: "Do exercises",
            },
            {
            lessonName: "unit 6",
            communication:
            "Circle the words that mean horizontally or vertically",
            vocabulary: "Animal vocabulary",
            grammar: "Tag questions",
            activitiesOther: "Do exercises",
            },
            {
            lessonName: "unit 7",
            communication: "Listen and fill in the blanks",
            vocabulary: "Musical instruments vocabulary",
            grammar: "Past tense",
            activitiesOther: "Do exercises",
            },
            {
            lessonName: "unit 8",
            communication: "Answer the questions to fill in the blanks",
            vocabulary: "Discovery topic vocabulary",
            grammar: "Might and may",
            activitiesOther: "Do exercises",
            },
            ]
        },
      ],
    },
    {
      courseName: "Basic English A2 (10 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp A1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 8 - 10.",
            "Học viên muốn củng cố lại kiến thức ở trình độ A2.",
          ],
          contentEN: [
            "Students have completed class A1",
            "Specialized program for children aged 8 - 10.",
            "Students want to consolidate their knowledge at level A2.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA staff are experienced teachers from prestigious universities like: Hanoi University of Science and Technology, Vietnam National University of Education.",
            "The program is designed for each group of courses according to specific needs, hoping for students' success.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Đọc hiểu những câu ngắn, đoạn văn ngắn liên quan đến những chủ đề quen thuộc như động vật, nơi chốn, nghề nghiệp,...",
            "Trang bị các kỹ năng làm các bài tập về viết lại từ vựng, viết lại câu, đúng hay sai, sử dụng giới từ chỉ thời gian và nơi chốn, đại từ chỉ định, đại từ ,…",
            "Sử dụng ngôn ngữ linh hoạt và hiệu quả khi nói về các chủ đề quen thuộc hằng ngày liên quan đến trường học, gia đình, thức ăn, màu sắc,...",
            "Đọc và nghe những câu chuyện dài hơn bằng tiếng Anh với nhiều từ vựng và ngữ pháp phức tạp hơn.",
          ],
          contentEN: [
            "Read and understand short sentences and short paragraphs related to familiar topics such as animals, places, occupations,...",
            "Equip skills to do exercises on rewriting vocabulary, rewriting sentences, true or false, using prepositions of time and place, demonstrative pronouns, pronouns,...",
            "Use language flexibly and effectively when talking about familiar everyday topics related to school, family, food, colors,...",
            "Read and listen to longer stories in English with more complex vocabulary and grammar.",
          ],
        },
      ],
      coursePath: [
        {
          level: "4",
          courseContent: [
            {
              lessonName: "unit 1",
              communication:
                "Tên các bộ phận cơ thể, Thì quá khứ, Các thói quen có lợi, có hại",
              vocabulary:
                "Từ vựng về các bộ phận của cơ thể và hoạt động có lợi cho sức khỏe",
              grammar:
                "Quá khứ đơn và câu trả lời ngắn, Mô tả số lượng sử dụng “enough”, “too”",
              activitiesOther:
                "Chỉ vào các bộ phận trên cơ thể và đọc hoặc chơi trò liệt kê các thói quen có lợi",
            },
            {
              lessonName: "unit 2",
              communication:
                "Kỷ niệm và lễ hội, Những gì xảy ra trong quá khứ, Truyền thống văn hóa",
              vocabulary:
                "Từ vựng về hoạt động và đồ vật trong một buổi lễ/kỷ niệm",
              grammar: "Thì quá khứ của động từ thường và động từ bất quy tắc",
              activitiesOther:
                "Kể với các bạn về những lễ hội hoặc truyền thống văn hóa mà mình biết",
            },
            {
              lessonName: "unit 3",
              communication:
                "Hoạt động trong thời gian rảnh, Thì quá khứ, Sở thích",
              vocabulary: "Từ vựng về các hoạt động",
              grammar:
                "Quá khứ đơn với “didn’t” trong câu trả lời dài, Dạng V + -ing trong quá khứ đơn",
              activitiesOther:
                "Nói về sở thích của bản thân đã thay đổi thế nào (có sử dụng quá khứ đơn)",
            },
            {
              lessonName: "unit 4",
              communication:
                " Miêu tả gia đình, Mô tả ngoại hình, Lên kế hoạch",
              vocabulary:
                "Từ vựng về so sánh hơn với đuôi “er” và miêu tả mái tóc",
              grammar:
                "So sánh hơn với “-er”, Thì hiện tại tương lai tiếp diễn",
              activitiesOther:
                "Giới thiệu và miêu tả với các bạn về các thành viên trong gia đình mình",
            },
            {
              lessonName: "unit 5",
              communication:
                "Nói về bổn phận, Mô tả tần suất, Thảo luận và đặt đồ ăn",
              vocabulary:
                "Từ vựng về rau củ quả và từ vựng về tần suất thời gian",
              grammar: "have to; would like, Đặt hàng sử dụng “would like”",
              activitiesOther:
                "Đóng vai người mua hàng và người bán hàng (sử dụng “would like”)",
            },
            {
              lessonName: "unit 6",
              communication:
                "Hoạt động của con người trong quá khứ, Mô tả quá khứ, So sánh quá khứ và hiện tại",
              vocabulary: "Từ vựng về vật dụng, hoạt động và tính từ so sánh",
              grammar: "Liên từ “but” và “instead”, So sánh hơn với more + adj",
              activitiesOther:
                "Sử dụng mẫu câu so sánh hiện tại có gì mới so với quá khứ",
            },
            {
              lessonName: "unit 7",
              communication:
                "Sức khỏe, bệnh tật, Mô tả hoạt động, Đưa ra lời khuyên, Nguyên nhân và kết quả",
              vocabulary: "Từ vựng về các chứng bệnh và kết quả do tai nạn",
              grammar:
                "Đưa ra lời khuyên với “should”, Đại từ phản thân, Từ nhiều nghĩa",
              activitiesOther:
                "Chia đội chơi trò chơi liệt kê những từ vựng về bệnh đã được học và đưa ra lời khuyên",
            },
            {
              lessonName: "unit 8",
              communication:
                "Các loại hình giải trí, Con người và hoạt động, Những người và sự vật yêu thích, Đưa ra ý kiến",
              vocabulary: "Từ vựng về nghề nghiệp và các dạng hình giải trí",
              grammar:
                "So sánh nhất với “-est” và “most”, So sánh hơn và so sánh nhất dạng bất quy tắc",
              activitiesOther:
                "Chia sẻ với các bạn về các loại hình giải trí mà mình thích",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 1",
            communication:
            "Names of body parts, Past tense, Good and bad habits",
            vocabulary:
            "Vocabulary about body parts and healthy activities",
            grammar:
            "Simple past and short answers, Describing quantities using “enough”, “too”",
            activitiesOther:
            "Point to body parts and read or play a game listing healthy habits",
            },
            {
            lessonName: "unit 2",
            communication:
            "Celebrations and festivals, What happened in the past, Cultural traditions",
            vocabulary:
            "Vocabulary about activities and objects in a ceremony/celebration",
            grammar: "Past tense of regular and irregular verbs",
            activitiesOther:
            "Tell your friends about festivals or cultural traditions that you know",
            },
            {
            lessonName: "unit 3",
            communication:
            "Free time activities, Past tense, Hobbies",
            vocabulary: "Vocabulary about activities",
            grammar:
            "Simple past with “didn’t” in long answers, V + -ing form in simple past",
            activitiesOther:
            "Talking about how your hobbies have changed (using simple past)",
            },
            {
            lessonName: "unit 4",
            communication:
            "Describing family, Describing appearance, Making plans",
            vocabulary:
            "Vocabulary about comparatives with “er” and describing hair",
            grammar:
            "Comparatives with “-er”, Present future continuous",
            activitiesOther:
            "Introducing and describing members of your family",
            },
            {
            lessonName: "unit 5",
            communication:
            "Talking about duties, Describing frequency, Discussing and ordering food",
            vocabulary:
            "Vocabulary about vegetables and vocabulary about time frequency time", grammar: "have to; would like, Order using “would like”",
            activitiesOther:
            
            "Play the role of buyer and seller (use “would like”)",
            },
            {
            lessonName: "unit 6",
            communication:
            "Human activities in the past, Describing the past, Comparing the past and present",
            vocabulary: "Vocabulary about objects, activities and comparative adjectives",
            grammar: "Conjunctions “but” and “instead”, Comparing with more + adj",
            activitiesOther:
            "Using comparative sentences to compare the present with the past",
            },
            {
            lessonName: "unit 7",
            communication:
            "Health, illness, Describing activities, Giving advice, Cause and effect",
            vocabulary: "Vocabulary about illnesses and accident results",
            grammar:
            "Giving advice with “should”, Reflexive pronouns, Multiple-meaning words",
            activitiesOther:
            "Divide into teams to play a game of listing words vocabulary about diseases learned and giving advice",
            },
            {
            lessonName: "unit 8",
            communication:
            "Types of entertainment, People and activities, Favorite people and things, Giving opinions",
            vocabulary: "Vocabulary about occupations and types of entertainment",
            grammar:
            "Superlatives with “-est” and “most”, Irregular comparatives and superlatives",
            activitiesOther:
            "Sharing with friends about the types of entertainment I like",
            },
            ]
        },
        {
          level: "5",
          courseContent: [
            {
              lessonName: "unit 1",
              communication:
                "Cuộc sống dưới đại dương, Đặc điểm của các sinh vật biển, Bảo vệ môi trường biển",
              vocabulary:
                "Từ vựng về sinh vật biển và hoạt động gây ô nhiễm môi trường biển",
              grammar:
                "Hậu tố “-able”, Thể hiện sự cần thiết với “must”, “have to” và nghiêm cấm với “can’t”, “don’t”, Đưa ra dự đoán với “will” và “won’t”",
              activitiesOther:
                "Chia đội liệt kê các loài sinh vật biển/các hành động gây ô nhiễm môi trường",
            },
            {
              lessonName: "unit 2",
              communication:
                "Các phát minh, Thói quen trong quá khứ, Cách sử dụng một sáng chế",
              vocabulary:
                "Từ vựng về các vật dụng và hoạt động liên quan đến phát mình",
              grammar:
                "Hậu tố “-ful”, Thói quen trong quá khứ với “used to +verb”, Sử dụng “you” trong câu khái quát",
              activitiesOther:
                "Nói về thói quen trong quá khứ của mình mà giờ không còn nữa",
            },
            {
              lessonName: "unit 3",
              communication:
                "Sở thích và mối quan tâm, Thông tin về một người, Mô tả một sở thích",
              vocabulary: "Từ vựng về thói quen và sở thích",
              grammar:
                "Mệnh đề quan hệ với “Who”, Tân ngữ trực tiếp và gián tiếp",
              activitiesOther:
                "Chia sẻ với các bạn về sở thích và thói quen của mình",
            },
            {
              lessonName: "unit 4",
              communication:
                "Các hành động sử dụng sức mạnh, Hiểu, đưa ra định nghĩa, Đọc và thuật lại thông tin",
              vocabulary: "Từ vựng về các hành động",
              grammar:
                "So sánh kép chỉ ra nguyên nhân và kết quả, Mệnh đề quan hệ với “Which”",
              activitiesOther:
                "Diễn tả các hành động (theo từ vựng đã học) để bạn mình đoán",
            },
            {
              lessonName: "unit 5",
              communication:
                "Các loại thời tiết cực đoan, Thiệt hại mà bão có thể gây ra, Cách chuẩn bị cho thời tiết khắc nghiệt",
              vocabulary: "Từ vựng về các dạng thời tiết cực đoan",
              grammar:
                "Sử dụng “going to + verb” để dự đoán tương lai, Câu điều kiện loại 0 (thì hiện tại đơn)",
              activitiesOther: "Mô tả một dạng thời tiết để bạn mình đoán",
            },
            {
              lessonName: "unit 6",
              communication:
                "Các đặc điểm của động vật, Cách động vật tự bảo vệ bản thân, Cách động vật bắt chước người khác",
              vocabulary: "Từ vựng về động vật và các hành động của chúng",
              grammar: "So sánh ngang bằng với “as + adj + as”, Câu hỏi đuôi",
              activitiesOther:
                "Bắt chước hành động của các loại động vật để bạn mình đoán",
            },
            {
              lessonName: "unit 7",
              communication:
                "Các loại nhạc cụ, Phong cách âm nhạc, Bày tỏ về sở thích",
              vocabulary:
                "Từ vựng về các loại nhạc cụ và thể loại nhạc khác nhau",
              grammar:
                "Thì hiện tại hoàn thành với “ever” và “never”, So sánh với trạng từ (more/less… than; as… as)",
              activitiesOther:
                "Chia đội liệt kê các loại nhạc cụ/phong cách âm nhạc",
            },
            {
              lessonName: "unit 8",
              communication:
                "Cuộc sống trong không gian, Hành trình khám phá không gian, Bày tỏ ý kiến",
              vocabulary: "Từ vựng về không gian vũ trụ",
              grammar:
                "Suy đoán bằng “may” và “might”, Đại từ không xác định (everyone, someone, anyone, no one)",
              activitiesOther:
                "Sưu tầm hình ảnh về vũ trụ/chơi trò chơi liệt kê các hành tinh xem ai nhanh nhất",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 1",
            communication:
            "Life under the ocean, Characteristics of marine creatures, Protecting the marine environment",
            vocabulary:
            "Vocabulary about marine creatures and activities that pollute the marine environment",
            grammar:
            "The suffix “-able”, Expressing necessity with “must”, “have to” and prohibition with “can’t”, “don’t”, Making predictions with “will” and “won’t”",
            activitiesOther:
            "Divide into teams to list marine creatures/actions that pollute the environment",
            },
            {
            lessonName: "unit 2",
            communication:
            "Inventions, Past habits, How to use an invention",
            vocabulary:
            "Vocabulary about items and activities related to inventions",
            grammar:
            "The suffix “-ful”, Past habits with “used to +verb”, Using “you” in general sentences",
            activitiesOther:
            "Talking about habits in my past that is no longer there",
            },
            {
            lessonName: "unit 3",
            communication:
            "Hobbies and interests, Information about a person, Describing a hobby",
            vocabulary: "Vocabulary about habits and interests",
            grammar:
            "Relative clauses with “Who”, Direct and indirect objects",
            activitiesOther:
            "Sharing with friends about my hobbies and habits",
            },
            {
            lessonName: "unit 4",
            communication:
            "Actions using power, Understanding, giving definitions, Reading and reporting information",
            vocabulary: "Vocabulary about actions",
            grammar:
            "Double comparisons showing cause and effect, Relative clauses with “Which”",
            activitiesOther:
            "Describing actions (according to the vocabulary learned) for your friends to guess",
            },
            {
            lessonName: "unit 5",
            communication:
            "Types of extreme weather, Damage caused by storms can cause, How to prepare for severe weather",
            vocabulary: "Vocabulary about types of extreme weather",
            grammar:
            "Using “going to + verb” to predict the future, Conditional sentences type 0 (simple present tense)",
            activitiesOther: "Describe a type of weather for your friend to guess",
            },
            {
            lessonName: "unit 6",
            communication:
            "Characteristics of animals, How animals protect themselves, How animals imitate others",
            vocabulary: "Vocabulary about animals and their actions",
            grammar: "Comparisons with “as + adj + as”, Tag questions",
            activitiesOther:
            "Imitate the actions of animals for your friend to guess",
            },
            {
            lessonName: "unit 7",
            communication: "Musical instruments, Styles of music, Expressing preferences",
            vocabulary:
            "Vocabulary about different types of musical instruments and genres",
            grammar:
            "Present tense complete with \"ever\" and “never”, Compare with adverbs (more/less… than; as… as)",
            activitiesOther: "Divide into teams to list musical instruments styles",
            },
            {
            lessonName: "unit 8",
            communication:
            "Life in space, Space exploration, Expressing opinions",
            vocabulary: "Space vocabulary",
            grammar:
            "Speculate using “may” and “might”, Indefinite pronouns (everyone, someone, anyone, no one)",
            activitiesOther:
            
            "Collect pictures of space/play a game of listing planets to see who is the fastest",
            },
            ]
        },
      ],
    },
    {
      courseName: "Intermediate English Pre B1 (11 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp A2",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 11 - 13.",
            "Học viên muốn củng cố lại kiến thức ở trình độ A2 & PRE B1.",
          ],
          contentEN: [
            "Students have completed class A2",
            "Specialized program for children aged 11 - 13.",
            "Students want to consolidate their knowledge at level A2 & PRE B1.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Đọc hiểu những câu ngắn, đoạn văn ngắn liên quan đến những chủ đề quen thuộc như động vật, nơi chốn, nghề nghiệp,...",
            "Phát triển vốn từ vựng với nhiều chủ đề khác nhau sẽ giúp trẻ giao tiếp trong các tình huống quen thuộc.",
            "Tương tác với người nói tiếng Anh một cách chậm rãi và rõ ràng.",
            "Có khả năng viết đoạn văn ngắn.",
          ],
          contentEN: [
            "Read and understand short sentences and short paragraphs related to familiar topics such as animals, places, occupations,...",
            "Developing vocabulary with many different topics will help children communicate in familiar situations.",
            "Interact with English speakers slowly and clearly.",
            "Able to write short paragraphs.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonName: "unit 1",
              communication:
                "Môn thể thao ưa thích, Diễn đạt thời gian, Kể về một việc đã xảy ra trong quá khứ",
              vocabulary: "Những môn thể thao ưa thích",
              grammar:
                "Hiện tại hoàn thành với since và for, Trạng từ nhấn mạnh",
              activitiesOther: "Mô tả một hoạt động, môn thể thao hay chơi",
            },
            {
              lessonName: "unit 2",
              communication:
                "Kỳ nghỉ, đi du lịch, Những món đồ cổ, Nơi di tích",
              vocabulary:
                "Từ vựng về hoạt động và đồ vật trong một buổi lễ/kỷ niệm",
              grammar: "Câu bị động: Simple past, Câu bị động: by + agent",
              activitiesOther:
                "Tưởng tượng mình đi tới Pompeii trong kỳ nghỉ. Hỏi và trả lời đóng vai",
            },
            {
              lessonName: "unit 3",
              communication:
                "Hoạt động trong thời gian rảnh, Thì quá khứ, Sở thích",
              vocabulary: "Từ vựng về các hoạt động",
              grammar:
                "Quá khứ tiếp diễn, Nguyên nhân và kết quả với thì quá khứ và động từ tình thái.",
              activitiesOther: "Tối qua bạn làm gì ?",
            },
            {
              lessonName: "unit 4",
              communication:
                "Những nơi chứa nước, Những hoạt động liên quan đến nước",
              vocabulary:
                "Từ vựng về so sánh hơn với đuôi “er” và miêu tả mái tóc",
              grammar:
                "Hiện tại hoàn thành tiếp diễn với since & for, whatever, whenever, wherever, whoever",
              activitiesOther:
                "Những hoạt động giúp tiết kiệm và bảo vệ nguồn nước",
            },
            {
              lessonName: "unit 5",
              communication:
                "Kiến thức vi sinh, Tên các loại động vật bò sát, Tên bộ phận cơ thể",
              vocabulary: "Tên một số loại vi sinh, Tính từ miêu tả",
              grammar:
                "Câu tường thuật: Reported Speech, Tính từ sở hữu, Vị trí tính từ",
              activitiesOther: "Sưu tập các hình ảnh về các loài bò sát",
            },
            {
              lessonName: "unit 6",
              communication: "Đi mua sắm, Hướng dẫn sử dụng điện thoại",
              vocabulary:
                "Các hành động mua sắm / sản xuất, Các nút / ứng dụng trên điện thoại",
              grammar:
                "Câu tường thật của dạng câu mệnh lệnh (Imperatives) & câu hỏi",
              activitiesOther: "Video Call",
            },
            {
              lessonName: "unit 7",
              communication: "Thiên nhiên và con người",
              vocabulary: "Từ vựng về thiên nhiên, Hiện tượng thiên nhiên",
              grammar: "Have to / Must",
              activitiesOther: "Đi thám hiểm trong một khu rừng",
            },
            {
              lessonName: "unit 8",
              communication: "Cuộc sống trong tương lai, Sở thú Robot",
              vocabulary: "Các hành động của Robot, Máy vi tính",
              grammar: "Cấu trúc Wish, Passive Voice in Future",
              activitiesOther: "Du hành thời gian",
            },
          ],
          courseContentEN: [
            {
            lessonName: "unit 1",
            communication:
            "Favorite sport, Expressing time, Telling about something that happened in the past",
            vocabulary: "Favorite sports",
            grammar:
            "Present perfect with since and for, Intensive adverbs",
            activitiesOther: "Describing an activity, sport often played",
            },
            {
            lessonName: "unit 2",
            communication:
            "Holiday, Travel, Antiques, Monuments",
            vocabulary:
            "Vocabulary about activities and objects in a ceremony/celebration",
            grammar: "Passive voice: Simple past, Passive voice: by + agent",
            activitiesOther:
            "Imagine going to Pompeii on holiday. Ask and answer role-play questions",
            },
            {
            lessonName: "unit 3",
            communication:
            "Free time activities, Past tense, Hobbies",
            vocabulary: "Vocabulary about activities",
            grammar:
            "Past Continuous, Cause and Effect with Past Tense and Modal Verbs.",
            activitiesOther: "What did you do last night?",
            },
            {
            lessonName: "unit 4",
            communication:
            "Water reservoirs, Activities related to water",
            vocabulary:
            "Vocabulary about comparatives with the suffix “er” and describing hair",
            grammar:
            "Present Perfect Continuous with since & for, whatever, whenever, wherever, whoever",
            activitiesOther:
            "Activities to help save and protect water resources",
            },
            {
            lessonName: "unit 5",
            communication:
            "Microbiology knowledge, Names of reptiles, Names of body parts",
            vocabulary: "Names of some microorganisms, Descriptive adjectives",
            grammar:
            "Reported Speech, Possessive adjectives, Adjective position",
            activitiesOther: "Collecting images of reptiles",
            },
            {
            lessonName: "unit 6",
            communication: "Go shopping, Instructions for using a phone",
            vocabulary:
            "Shopping / production actions, Buttons / applications on a phone",
            grammar:
            "Imperatives & questions",
            activitiesOther: "Video Call",
            },
            {
            lessonName: "unit 7",
            communication: "Nature and people",
            vocabulary: "Nature vocabulary, Natural phenomena",
            grammar: "Have to / Must",
            activitiesOther: "Go on an expedition in a forest",
            },
            {
            lessonName: "unit 8",
            communication: "Life in the future, Robot zoo",
            vocabulary: "Robot actions, Computer",
            grammar: "Wish structure, Passive Voice in Future",
            activitiesOther: "Time travel",
            },
            ]
        },
      ],
    },
    {
      courseName: "Intermediate English B1 (12 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp Pre B1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 11 - 13.",
            "Học viên muốn nâng cao trình độ tiếng Anh.",
          ],
          contentEN: [
            "Students who have completed Pre B1",
            "Specialized course designed for children aged 11 - 13.",
            "Students looking to improve their English level.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Có khả năng thuyết trình trước đám đông bằng tiếng Anh một cách thuần thục.",
            "Tham gia tranh luận về các chủ đề xã hội, con người, đời sống, ... bằng tiếng Anh.",
            "Tự tin tham gia làm các công việc nhóm, nâng cao khả năng tư duy phản biện.",
          ],
          contentEN: [
            "Can effectively converse in front of a large crowd in English.",
            "Can participate in debates on various topics such as society, people, life, ... in English.",
            "Can trust in group work, improve problem-solving skills.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonName: "",
              communication:
                "Học nói và trình bày ý tưởng qua thảo luận nhóm, Học cách thuyết trình qua bài tập và project cá nhân, Học cách phát âm và kỹ năng nói qua các bài tập ngắn và các video",
              vocabulary:
                "Học từ vựng và các chiến lược đọc qua các bài đọc học thuật",
              grammar:
                "Học ngữ pháp và từ vựng qua các bài video và các câu chuyện có thật trong cuộc sống",
              activitiesOther:
                "Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế,Học cách đọc hiểu và phân tích biểu đồ,Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế",
            },
          ],
          courseContentEN: [
            {
            lessonName: "",
            communication:
            "Learn to speak and present ideas through group discussions, Learn to give presentations through individual exercises and projects, Learn pronunciation and speaking skills through short exercises and videos",
            vocabulary:
            "Learn vocabulary and reading strategies through academic readings",
            grammar:
            "Learn grammar and vocabulary through videos and real-life stories",
            activitiesOther:
            "Learn listening and note-taking skills through lectures, science films and real-life interviews, Learn to read and analyze charts, Learn listening and note-taking skills through lectures, science films and real-life interviews",
            },
            ]
        },
      ],
    },
    {
      courseName: "Intermediate English B2 (13 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp B1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 11-13.",
            "Học viên muốn nâng cao trình độ tiếng Anh.",
          ],
          contentEN: [
            "Students who have completed B1",
            "Specialized course designed for children aged 11 - 13.",
            "Students looking to improve their English level.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Có khả năng thuyết trình trước đám đông bằng tiếng Anh một cách thuần thục.",
            "Tham gia tranh luận về các chủ đề xã hội, con người, đời sống, ... bằng tiếng Anh.",
            "Tự tin tham gia làm các công việc nhóm, nâng cao khả năng tư duy phản biện.",
          ],
          contentEN: [
            "Can effectively converse in front of a large crowd in English.",
            "Can participate in debates on various topics such as society, people, life, ... in English.",
            "Can trust in group work, improve problem-solving skills.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonName: "",
              communication:
                "Học nói và trình bày ý tưởng qua thảo luận nhóm, Học cách thuyết trình qua bài tập và project cá nhân, Học cách phát âm và kỹ năng nói qua các bài tập ngắn và các video",
              vocabulary:
                "Học từ vựng và các chiến lược đọc qua các bài đọc học thuật",
              grammar:
                "Học ngữ pháp và từ vựng qua các bài video và các câu chuyện có thật trong cuộc sống",
              activitiesOther:
                "Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế,Học cách đọc hiểu và phân tích biểu đồ,Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế",
            },
          ],
          courseContentEN: [
            {
            lessonName: "",
            communication:
            "Learn to speak and present ideas through group discussions, Learn to give presentations through individual exercises and projects, Learn pronunciation and speaking skills through short exercises and videos",
            vocabulary:
            "Learn vocabulary and reading strategies through academic readings",
            grammar:
            "Learn grammar and vocabulary through videos and real-life stories",
            activitiesOther:
            "Learn listening and note-taking skills through lectures, science films and real-life interviews, Learn to read and analyze charts, Learn listening and note-taking skills through lectures, science films and real-life interviews",
            },
            ]
        },
      ],
    },
    {
      courseName: "Advanced English Pre C1 (14 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp B2",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 14 - 16.",
            "Học viên muốn nâng cao trình độ tiếng Anh",
          ],
          contentEN: [
            "Students who have completed B2",
            "Specialized course designed for children aged 14 - 16.",
            "Students looking to improve their English level.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Có khả năng thuyết trình trước đám đông bằng tiếng Anh một cách thuần thục.",
            "Tham gia tranh luận về các chủ đề xã hội, con người, đời sống, ... bằng tiếng Anh.",
            "Tự tin tham gia làm các công việc nhóm, nâng cao khả năng tư duy phản biện.",
          ],
          contentEN: [
            "Can effectively converse in front of a large crowd in English.",
            "Can participate in debates on various topics such as society, people, life, ... in English.",
            "Can trust in group work, improve problem-solving skills.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonName: "",
              communication:
                "Học nói và trình bày ý tưởng qua thảo luận nhóm, Học cách thuyết trình qua bài tập và project cá nhân, Học cách phát âm và kỹ năng nói qua các bài tập ngắn và các video",
              vocabulary:
                "Học từ vựng và các chiến lược đọc qua các bài đọc học thuật",
              grammar:
                "Học ngữ pháp và từ vựng qua các bài video và các câu chuyện có thật trong cuộc sống",
              activitiesOther:
                "Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế,Học cách đọc hiểu và phân tích biểu đồ,Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế",
            },
          ],
          courseContentEN: [
            {
            lessonName: "",
            communication:
            "Learn to speak and present ideas through group discussions, Learn to give presentations through individual exercises and projects, Learn pronunciation and speaking skills through short exercises and videos",
            vocabulary:
            "Learn vocabulary and reading strategies through academic readings",
            grammar:
            "Learn grammar and vocabulary through videos and real-life stories",
            activitiesOther:
            "Learn listening and note-taking skills through lectures, science films and real-life interviews, Learn to read and analyze charts, Learn listening and note-taking skills through lectures, science films and real-life interviews",
            },
            ]
        },
      ],
    },
    {
      courseName: "Advanced English C1 (15 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp Pre C1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 14 - 16.",
            "Học viên muốn nâng cao trình độ tiếng Anh",
          ],
          contentEN: [
            "Students who have completed Pre C1",
            "Specialized course designed for children aged 14 - 16.",
            "Students looking to improve their English level.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Có khả năng thuyết trình trước đám đông bằng tiếng Anh một cách thuần thục.",
            "Tham gia tranh luận về các chủ đề xã hội, con người, đời sống, ... bằng tiếng Anh.",
            "Tự tin tham gia làm các công việc nhóm, nâng cao khả năng tư duy phản biện.",
          ],
          contentEN: [
            "Able to present to crowds in English fluently.",
            "Participate in debates on social topics, people, life,... in English.",
            "Confidently participate in group work, improve critical thinking ability.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonName: "",
              communication:
                "Học nói và trình bày ý tưởng qua thảo luận nhóm, Học cách thuyết trình qua bài tập và project cá nhân, Học cách phát âm và kỹ năng nói qua các bài tập ngắn và các video",
              vocabulary:
                "Học từ vựng và các chiến lược đọc qua các bài đọc học thuật",
              grammar:
                "Học ngữ pháp và từ vựng qua các bài video và các câu chuyện có thật trong cuộc sống",
              activitiesOther:
                "Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế,Học cách đọc hiểu và phân tích biểu đồ,Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế",
            },
          ],
          courseContentEN: [
            {
            lessonName: "",
            communication:
            "Learn to speak and present ideas through group discussions, Learn to give presentations through individual exercises and projects, Learn pronunciation and speaking skills through short exercises and videos",
            vocabulary:
            "Learn vocabulary and reading strategies through academic readings",
            grammar:
            "Learn grammar and vocabulary through videos and real-life stories",
            activitiesOther:
            "Learn listening and note-taking skills through lectures, science films and real-life interviews, Learn to read and analyze charts, Learn listening and note-taking skills through lectures, science films and real-life interviews",
            },
            ]
        },
      ],
    },
    {
      courseName: "Advanced English C2 (16 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp C1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 14 - 16.",
            "Học viên muốn nâng cao trình độ tiếng Anh",
          ],
          contentEN: [
            "Students who have completed C1",
            "Specialized course designed for children aged 14 - 16.",
            "Students looking to improve their English level.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Có khả năng thuyết trình trước đám đông bằng tiếng Anh một cách thuần thục.",
            "Tham gia tranh luận về các chủ đề xã hội, con người, đời sống, ... bằng tiếng Anh.",
            "Tự tin tham gia làm các công việc nhóm, nâng cao khả năng tư duy phản biện.",
          ],
          contentEN: [
            "Able to present to crowds in English fluently.",
            "Participate in debates on social topics, people, life,... in English.",
            "Confidently participate in group work, improve critical thinking ability.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonName: "",
              communication:
                "Học nói và trình bày ý tưởng qua thảo luận nhóm, Học cách thuyết trình qua bài tập và project cá nhân, Học cách phát âm và kỹ năng nói qua các bài tập ngắn và các video",
              vocabulary:
                "Học từ vựng và các chiến lược đọc qua các bài đọc học thuật",
              grammar:
                "Học ngữ pháp và từ vựng qua các bài video và các câu chuyện có thật trong cuộc sống",
              activitiesOther:
                "Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế,Học cách đọc hiểu và phân tích biểu đồ,Học kỹ năng nghe và ghi chú qua các bài giảng, phim khoa học và các buổi phỏng vấn thực tế",
            },
          ],
          courseContentEN: [
            {
            lessonName: "",
            communication:
            "Learn to speak and present ideas through group discussions, Learn to give presentations through individual exercises and projects, Learn pronunciation and speaking skills through short exercises and videos",
            vocabulary:
            "Learn vocabulary and reading strategies through academic readings",
            grammar:
            "Learn grammar and vocabulary through videos and real-life stories",
            activitiesOther:
            "Learn listening and note-taking skills through lectures, science films and real-life interviews, Learn to read and analyze charts, Learn listening and note-taking skills through lectures, science films and real-life interviews",
            },
            ]
        },
      ],
    },
    {
      courseName: "Practice English pronunciation",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Chương trình chuyên biệt dành cho trẻ em cần hỗ trợ về cách phát âm.",
          ],
          contentEN: [
            "Specialized course for children needing support for pronunciation.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu, hiểu tại sao chính tả lại như vậy, tự tin về chính tả và viết.",
            "Xây dựng các từ có tiền tố và hậu tố để giúp đánh vần và đọc, thông báo về chữ cái và cách viết.",
          ],
          contentEN: [
            "Children will be provided with a vast vocabulary and basic grammar knowledge to facilitate communication, serve as a foundation for higher levels of learning.",
            "Focus on reinforcing standard pronunciation as native speakers from the beginning, understand why it always seems right, confidently believe in it, and build vocabulary with prefixes and suffixes to help memorize and read, report on vowels and writing patterns.",
          ],
        },
      ],
      coursePath: [],
    },
    {
      courseName: "Practice English vocabulary",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Chương trình chuyên biệt dành cho trẻ em cần hỗ trợ về từ vựng.",
          ],
          contentEN: [
            "Specialized course for children needing support for vocabulary.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Xây dựng các từ có tiền tố và hậu tố để giúp đánh vần và đọc, thông báo về chữ cái và cách viết.",
          ],
          contentEN: [
            "Build vocabulary with prefixes and suffixes to help memorize and read, report on vowels and writing patterns.",
          ],
        },
      ],
      coursePath: [],
    },
    {
      courseName: "Practice English grammar",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Chương trình chuyên biệt dành cho trẻ em cần hỗ trợ về ngữ pháp, cấu trúc câu.",
          ],
          contentEN: [
            "Specialized course for children needing support for grammar, sentence structure.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "The ABBA teaching staff consist of highly regarded universities such as: The University of New South Wales, The University of Sydney.",
            "Designed course paths specific to each group of learning based on the student's requirements, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu, hiểu tại sao chính tả lại như vậy, tự tin về chính tả và viết.",
            "Xây dựng các từ có tiền tố và hậu tố để giúp đánh vần và đọc, thông báo về chữ cái và cách viết.",
          ],
          contentEN: [
            "Children will be provided with a vast vocabulary and basic grammar knowledge to facilitate communication, serve as a foundation for higher levels of learning.",
            "Focus on reinforcing standard pronunciation as native speakers from the beginning, understand why it always seems right, confidently believe in it, and build vocabulary with prefixes and suffixes to help memorize and read, report on vowels and writing patterns.",
          ],
        },
      ],
      coursePath: [],
    },
  ];
  return data;
};

export const SATCourseContent = () => {
  const data: SATCourse[] = [
    {
      courseName:
        "Math, Language Arts, Science, Social Studies class (7 - 18y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 8 - 16.",
            "Học viên muốn mở ra cơ hội du học trên toàn thế giới.",
          ],
          contentEN: [
            "Specialized course for children aged 8-16.",
            "Students wishing to open up opportunities to study abroad worldwide.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Lộ trình học tập khoa học nhằm tối ưu hóa điểm số IELTS, SAT để chạm tới các mục tiêu du học, thi tuyển vào đại học hàng đầu, xa hơn là phát triển và thăng tiến trong sự nghiệp.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Chương trình học được kết hợp giữa giáo trình chuẩn quốc tế mới nhất cùng nền tảng quản lý học tập của Cambridge (CLMS) giúp hỗ trợ học viên ôn tập cả 4 kỹ năng, ngữ pháp và từ vựng, cùng với các kỹ năng thi IELTS.",
          ],
          contentEN: [
            "A scientific learning roadmap to optimize IELTS and SAT scores to reach the goals of studying abroad, taking entrance exams to top universities, and further developing and advancing in your career.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "The curriculum combines the latest international standard curriculum with Cambridge learning management platform (CLMS) to help students review all 4 skills, grammar and vocabulary, along with other skills. IELTS test.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Học viên sẽ tích lũy và trau dồi thêm được các kỹ năng bổ trợ, giúp ích cho các kỳ thi IELTS, TOEFL,... như kỹ năng đọc - hiểu, kỹ năng tư duy, suy luận logic, vốn từ vựng,...",
            "Học viên hoàn thành khoá học SAT sẽ được tuyển thẳng vào các trường đại học danh tiếng.",
          ],
          contentEN: [
            "Students will acquire and reinforce additional skills to support their IELTS, TOEFL,... skills such as reading, understanding, thinking, logical reasoning, vocabulary,...",
            "Students will complete the SAT course and be eligible for direct entry into prestigious universities.",
          ],
        },
      ],
      coursePath: [
        {
          grade: 1,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "During the first semester students will build fluency with basic math facts. They will learn basic addition and subtraction facts, how to count to 100, and how to add double-digit numbers. Students will be introduced to such new concepts as word problems, Venn diagrams, and basic geometric concepts. There is an emphasis on learning practical skills such as reading thermometers, looking at maps, and understanding the value of coins.",
                  ],
                  majorConcepts: [
                    "Counting to 100",
                    "Counting strategies",
                    "Addition of three numbers; double digit addition",
                    "Understanding even and odd numbers",
                    "Recognizing geometry in daily life",
                    "Introduction to word problems",
                    "Introduction to Venn diagrams",
                    "Elementary probability",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "This First Grade Language Arts course will teach students to identify and write all letters, produce letter sounds, and frequently used phonograms. Students will also master reading and comprehension strategies and weekly sight words to grow as readers. All Common Core 1 Language Arts standards are met in this course.",
                  ],
                  majorConcepts: [
                    "Short a",
                    "Short e",
                    "r Blends",
                    "Short i",
                    "Short o",
                    "s Blends",
                    "l Blends",
                    "Short u",
                    "Phonics: sh, th",
                    "Phonics: ch, wh, ph",
                    "Long i; _i_e",
                    "Longa; _a_e",
                    "Soft c, soft g, dge",
                    "Phonics: ou and ow",
                    "Phonics: oo and u",
                    "Long o; _o_e",
                    "Long e: _e_e and Long u: _u_e",
                    "Phonics: oi and oy",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "In First Grade Science, students in this course will complete projects that are designed to allow for exploration and discovery. Students observe their surroundings and through observations of the natural world conduct inquiries into topics related to their healthy development.",
                  ],
                  majorConcepts: [
                    "The Moon",
                    "The Seasons",
                    "Classifying Leaves",
                    "Seeds",
                    "Animal Tracks",
                    "Birds",
                    "Animals and their basic needs",
                    "Weather Charts",
                    "Cloud Formation",
                    "Weather Change and the Effects on Animals",
                    "Living with Nature",
                    "Animal Adaptation",
                    "Winter and Snow Formation",
                    "Comparing Tree Types",
                    "Wild Animal Responses",
                    "Hibernation",
                    "Erosion",
                    "Constellations",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this semester, students begin to explore basic fundamentals of social studies, including map skills and cardinal directions, and will begin to examine maps of the U.S. and the globe. Students will also be introduced to diversity and the importance of it in developing the culture of the United States. They will also begin their studies of character education, like good citizenship, taking responsibility, serving their community, and leadership.",
                  ],
                  majorConcepts: [
                    "Maps, globes, and cardinal directions",
                    "Weather and water",
                    "Location (physical features)",
                    "Diversity",
                    "Family and traditions",
                    "Communities",
                    "Character education (responsibility, respect, compassion, citizenship)",
                    "Problem solving and making voices",
                    "Government",
                    "Change over time",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "During the second semester, students will begin counting by twos, fives, and tens. They will learn both vertical addition and subtraction. Students are introduced to multiplication and division, as well as the signs used in those operations. They will also study even and odd numbers. Students continue their exploration of geometric shapes through drawing and apply what they learn about shapes by sorting various figures in Venn diagrams. They will also use a balance beam to understand the concept of weight – lighter versus heavier.",
                    "In both semesters, students will have multiple opportunities to practice new skills and knowledge through using integrated online practice problems.",
                  ],
                  majorConcepts: [
                    "Counting by twos, fives, and tens",
                    "Ordinal numbers",
                    "Identifying 2-dimensional shapes",
                    "Introduction to multiplication",
                    "Introduction to division",
                    "Signs used in operations",
                    "Drawing shapes",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "This First Grade Language Arts course will teach students to identify and write all letters, produce letter sounds, and frequently used phonograms. Students will also master reading and comprehension strategies and weekly sight words to grow as readers. All Common Core 1 Language Arts standards are met in this course.",
                  ],
                  majorConcepts: [
                    "ai and ay",
                    "ee, ea",
                    "nk and ng",
                    "ey and y",
                    "oa, oe, ow",
                    "igh, ie, y",
                    "ar",
                    "ur, er, ir",
                    "ck, k",
                    "ing and ed",
                    "Double Consonants",
                    "s and es",
                    "au and aw",
                    "kn, gn, wr",
                    "er, est",
                    "Compound Words",
                    "Contractions",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "In First Grade Science, students in this course will complete projects that are designed to allow for exploration and discovery. Students observe their surroundings and through observations of the natural world conduct inquiries into topics related to their healthy development.",
                  ],
                  majorConcepts: [
                    "Animals in the Arctic",
                    "Nocturnal and Diurnal Animals",
                    "States of Matter",
                    "Dolphins and Sea Life",
                    "Planting a Tomato",
                    "Scientific Inquiry",
                    "Reading a Thermometer",
                    "Life in a Pond",
                    "Life in the Forest",
                    "Characteristics of the Maple Leaf",
                    "How the Marketplace Works",
                    "Sounds in Nature",
                    "Birdhouses",
                    "Deciduous Trees",
                    "Sunflowers",
                    "Bees and Pollination",
                    "Comparing Butterflies",
                    "Planting a Garde",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "The second semester will begin the students’ studies of historical America. They will talk about famous historical figures such as George Washington Carver, Susan B. Anthony, Ruby Bridges, Amelia Earhart, Thomas Edison, and Benjamin Franklin. Students will explore tall tales and folklore dealing with history, character education, and other morals. They will also explore what is meant by economics, studying the difference between goods and services, needs and wants, and producers and consumers. They will end the semester doing some research and presentation of what they learned this semester.",
                  ],
                  majorConcepts: [
                    "Historical America",
                    "Tall tales and folklore",
                    "Economics",
                    "Goods and services",
                    "Work and home life",
                    "Needs and wants",
                    "Scarcity of products",
                    "Making and using money",
                    "Technology",
                    "Research and presenting",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 2,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "During the first semester, students will build fluency with basic math facts and add and subtract within 100 to solve word problems using strategic methods. Students will also manipulate numbers to 1000 using knowledge of hundreds, tens, and ones. Lastly, students with demonstrate arrays with repeated addition.",
                  ],
                  majorConcepts: [
                    "Fact Fluency",
                    "Addition and Subtraction to 1000",
                    "Addition and Subtraction Strategies",
                    "Repeated Addition",
                    "Associative and Commutative Properties",
                    "Estimation",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "The 2nd Grade Language Arts course will teach students to spell and write vocabulary, read more fluently, apply grammar concepts, and participate in handwriting and writing activities through thematic units. Students will also continue to master reading and comprehension strategies and weekly sight words to grow as readers. All Common Core 2 Language Arts standards are met in this course.",
                  ],
                  majorConcepts: [
                    "Your Family",
                    "Family Fun",
                    "Bedtime",
                    "Fall Fun",
                    "Farm Visit",
                    "Dentist Visit",
                    "Grandparents",
                    "Making Snow",
                    "Winter Time",
                    "Cooking Fun",
                    "Cycles",
                    "Getting Creative",
                    "Fun with Friends",
                    "Animal Friends",
                    "Animal Helpers",
                    "Animal Babies",
                    "Doctors & Nurses",
                    "Authors",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 2 introduces students to the process of observation and how important it is to the study of science. Learners will identify their five senses and why they are critical to observation. Students will use these observation skills throughout the course as they examine many different types of animals and their environments. Students will begin by exploring the needs and behaviors of animals and continue into learning how scientists solve problems through engineering.",
                    "As the course continues, exploration continues to topics of matter, energy, habitats, weather, and animal characteristics. Students will learn through video, hands-on participation, and observation with nature. The teachers will conduct live assessments for the topics that have been covered throughout the week’s lessons. Grade 2 Science provides students with the opportunity to expand their minds and see for themselves how the world works and the way animals and nature are a part of their everyday lives.",
                  ],
                  majorConcepts: [
                    "Senses",
                    "Needs",
                    "Animal Behavior",
                    "Engineering",
                    "Matter",
                    "Habitats",
                    "Weather",
                    "Water",
                    "Diversity of Living Things",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "Social Studies 2 introduces the concept of a government by first exploring the reason for laws and rules. Students learn about national laws, state laws, and consequences for breaking a law. Learners then explore the role of government and government leaders who uphold the law. Students then study the states (California, Florida, Pennsylvania, Texas, and their own state) and the nation. Learners will explore how American heroes have changed America today before transitioning to a study on the world government, maps, and the environment. This course concludes by spending time looking into the past and relating the past to the student’s life today. Social Studies 2 provides practice and assessments throughout each lesson that target the learning objectives and maximize instruction. By the end of this course, students have both a broad understanding of the world and a specific understanding of how the national and state government impact their own life.",
                  ],
                  majorConcepts: [
                    "Laws",
                    "Government",
                    "State",
                    "Country",
                    "World",
                    "Historical American Heroes",
                    "Geography",
                    "Maps",
                    "Environment",
                    "Family History",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "During the second semester, students will use place value to add and subtract within 1000 and to estimate and solve word problems, demonstrating skills. Students will measure and compare length and represent it on a number line. They will work with money and time to compare value. Students will collect data to represent on graphs and discuss. Lastly, they will recognize common 2-dimensional and 3-dimensional shapes by specific characteristics.",
                  ],
                  majorConcepts: [
                    "Repeated Subtraction",
                    "Whole Number Length",
                    "Time: Minute, Hour, Day",
                    "Money: Combinations of 1 dollar",
                    "Area and Perimeter",
                    "Simple Fractions: Equivalency",
                    "Data",
                    "Volume and Weight",
                    "2D and 3D Shapes",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "The 2nd Grade Language Arts course will teach students to spell and write vocabulary, read more fluently, apply grammar concepts, and participate in handwriting and writing activities through thematic units. Students will also continue to master reading and comprehension strategies and weekly sight words to grow as readers. All Common Core 2 Language Arts standards are met in this course.",
                  ],
                  majorConcepts: [
                    "Animal Food",
                    "Animal Jobs",
                    "Travel Stories",
                    "My Community",
                    "Community Jobs",
                    "Gifts",
                    "Making Mischief",
                    "Natural World",
                    "Animal Adventure",
                    "Patterns",
                    "Earth",
                    "Water",
                    "To the Moon",
                    "Future",
                    "Making Plans",
                    "Asking Questions",
                    "Being Afraid",
                    "Moving On",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Semester B of Science 2 begins with how the earth changes and how things move. Learners will be asked to recall the characteristics of different animal groups and explore how they are interconnected through food chains and cooperation. The course ends with the students taking a closer look at how plants fit into the workings of the world and the different types of resources on Earth. At the close of the course, students will have a deeper understanding and appreciation of Earth’s systems and interdependence.",
                  ],
                  majorConcepts: [
                    "Earth Changes",
                    "Forces & Motion",
                    "Insects",
                    "Pollination",
                    "Food Chains",
                    "Life Cycles",
                    "Plants",
                    "Natural Resources",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "The second part of Social Studies 2 begins by introducing the concept of change over time through an understanding of transportation, recreation, and communication. Students will study famous historical inventors and design their own invention that solves a problem in their everyday lives. Students will understand the influence of perspective on historical events before launching into a relatable study of basic human needs. Students will understand the differences between needs and wants, producers and consumers, supply and demand, and goods and services. This course concludes with an examination of basic economics and an exploration of problems and solutions. By the end of this course, students will understand how society has changed over time and have a firm understanding of basic economics.",
                  ],
                  majorConcepts: [
                    "Historical Artifacts",
                    "Science and Technology",
                    "History Timeline",
                    "Needs and Wants",
                    "Goods and Services",
                    "Producers and Consumers",
                    "Resources",
                    "Supply and Demand",
                    "Economics",
                    "World Trade",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 3,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "During the first semester, students will build flexibility with numbers as they master addition and subtraction facts as well as multiplication and division facts. Students will understand relationships between addition and subtraction, multiplication and addition, and multiplication and division as they learn to borrow, carry, and regroup in order to find sums and differences of two whole numbers. Students will also comprehend the place value of base ten numbers up to 1,000,000 in order to find patterns and make estimations. Students will be introduced to the concepts of fractions in terms of defining, comparing, and finding equivalent fractions. Lastly, they will implement a five-step approach to solving word problems throughout the entire semester.",
                  ],
                  majorConcepts: [
                    "Multiplication",
                    "Division",
                    "Place value",
                    "Word problems and problem solving",
                    "Math facts",
                    "Fractions",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester, students will continue to build their vocabulary through the study of folktales, fables, myths, informational text, dramas, poems, and stories. They will recount stories and ask and answer questions to demonstrate their knowledge of text. They will compare and contract themes, setting, and plots and distinguish their own point of view from that of the author of the text. Students will also gain information from illustrations and describe logical connections between sentences and paragraphs. They will also be introduced to writing in cursive.",
                    "During the second semester, students will continue to apply phonics and grammar concepts with a focus on special vowel sounds, prefixes, and suffixes. Students will continue to build writing skills by responding to reading and utilizing a broadened vocabulary in authentic writing activities where they compare and contrast stories and use process writing to compose original work. They will read with accuracy and fluency to support comprehension as they solidify their understanding within context of the stories they read. By the end of the year, the third-grade student will read and comprehend informational texts, including history/social studies, science, and technical texts independently and proficiently. Students will report on a topic using descriptive details and speaking clearly and in complete sentences.",
                  ],
                  majorConcepts: [
                    "Parts of Speech",
                    "Consonant and Vowel Patterns",
                    "Paragraph Structure",
                    "Retelling with Details",
                    "Ending Sounds",
                    "Simple Sentence Structure",
                    "Process Writing",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 3 introduces students to experimentation as they journey through the earth and its many miracles. They will begin by learning about the basic needs of all life on Earth. By participating in simple experiments, students will explore the water cycle, the weather and its patterns, energy, and the role of plants in the production of oxygen and their importance to human survival. Learners will expand their knowledge through videos, pictures, short readings, projects, and hands-on experiments. Learners will understand that experiments require the use of instruments, observation, recording, and drawing evidence-based conclusions. Science 3 provides students with the opportunity to expand their minds and see for themselves the way that science is a part of their everyday lives.",
                  ],
                  majorConcepts: [
                    "Needs of living things",
                    "Plant Structures and Functions",
                    "Patterns of Earth",
                    "Weather",
                    "The Water Cycle",
                    "Heat & Light",
                    "Rocks",
                    "Climate",
                    "Animal Classification",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "Social Studies 3 introduces students to the geography of the regions of the United States. They will access maps, interpret map elements, and summarize physical and human characteristics of a region. They will also analyze the impact that humans have on their environment. Students will then learn how state and federal governments operate. Finally, students will be able to connect the rights of a citizen to the responsibilities of citizenship.",
                  ],
                  majorConcepts: [
                    "Geographic Literacy",
                    "Physical and Human Characteristics",
                    "Human Environment Interaction",
                    "Regions of the US",
                    "American Government",
                    "Symbols and Holidays",
                    "American Citizenship",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "During the second semester, students will use place value to add and subtract within 1000 and to estimate and solve word problems, demonstrating skills. Students will measure and compare length and represent it on a number line. They will work with money and time to compare value. Students will collect data to represent on graphs and discuss. Lastly, they will recognize common 2-dimensional and 3-dimensional shapes by specific characteristics.",
                  ],
                  majorConcepts: [
                    "Fractions cont.",
                    "Shapes",
                    "Measuring time, mass, capacity",
                    "Data and graphs",
                    "Area and perimeter",
                    "Place value",
                    "Estimation",
                    "Multiples",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester, students will continue to build their vocabulary through the study of folktales, fables, myths, informational text, dramas, poems, and stories. They will recount stories and ask and answer questions to demonstrate their knowledge of text. They will compare and contract themes, setting, and plots and distinguish their own point of view from that of the author of the text. Students will also gain information from illustrations and describe logical connections between sentences and paragraphs. They will also be introduced to writing in cursive.",
                    "During the second semester, students will continue to apply phonics and grammar concepts with a focus on special vowel sounds, prefixes, and suffixes. Students will continue to build writing skills by responding to reading and utilizing a broadened vocabulary in authentic writing activities where they compare and contrast stories and use process writing to compose original work. They will read with accuracy and fluency to support comprehension as they solidify their understanding within context of the stories they read. By the end of the year, the third-grade student will read and comprehend informational texts, including history/social studies, science, and technical texts independently and proficiently. Students will report on a topic using descriptive details and speaking clearly and in complete sentences.",
                  ],
                  majorConcepts: [
                    "Parts of Speech",
                    "Parts of Sentences",
                    "Past and Present Tense",
                    "Genre Study",
                    "Prefixes and Suffixes",
                    "Irregular Words",
                    "Story Structure",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "The second semester of Science 3 begins with the students diving deeper into habitats, biomes, and seasonal adaptations. The learners continue with types of terrain, inherited characteristics, matter, gravity, and even magnetism. All of these lessons are taught using videos, projects, and experimentation. This semester asks learners to look a bit deeper into things they encounter such as biodiversity and natural resources.",
                  ],
                  majorConcepts: [
                    "Habitats",
                    "Adaptations",
                    "Biomes",
                    "Landforms",
                    "Fossils",
                    "Matter",
                    "Magnetism",
                    "Biodiversity",
                    "Natural Resources",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "The second semester of Social Studies 3 begins with the students focusing on the principles of economics. Students will learn how the economy works through earning, spending, and saving money. Students will move into identifying heroic figures, both past and present, and interpreting timelines. Students will then assess American culture during the colonial times. They will also analyze immigration and the contributions made to the United States. Finally, they will relate landmarks to their historical significance.",
                  ],
                  majorConcepts: [
                    "Economics",
                    "Heroes Past and Present",
                    "Timelines",
                    "Westward Migration",
                    "Immigration",
                    "Changing Communities",
                    "Culture",
                    "Landmarks",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 4,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "Math 4 uses a varied amount of instructional material to reinforce and teach new math skills to the 4th grade learners. Instruction includes creative videos, mathematical storytelling, practical math applications, and repetition to reinforce skills throughout the course. Three areas are focused on and students will finish the course with a strong knowledge in these content areas. The first is developing an understanding and fluency with multi-digit multiplication and developing the understanding of dividing to find quotients involving multi-digit dividends. The second is developing an understanding of fraction equivalence, addition and subtraction of fractions with like denominators, and multiplication of fractions with whole numbers. The third will be addressed in Semester B.",
                  ],
                  majorConcepts: [
                    "Multiplication",
                    "Prime and composite numbers",
                    "Patterns",
                    "Place Value",
                    "Number Forms",
                    "Division",
                    "Comparisons and estimation",
                    "Fluency in addition and subtraction",
                    "Fractions (addition, subtraction, and division)",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "Language Arts 4’s curriculum integrates reading, writing, speaking, listening, and the study of vocabulary and grammar in a way that engages today’s learners and supports them in building a broad and diverse set of literacy skills. Students study classic literature as well as more contemporary forms, including media and multimedia products. Writing assignments in Semester A focus on narrative and persuasive modes and emphasize the use of reasoning and details to support opinions. Each writing assignment spans several lessons and guides students through a writing process that begins with prewriting and ends by emphasizing one or more aspects of conventions of standard written English. Students also learn how to participate in collaborative discussion and peer review sessions. In each lesson, engaging and relevant models and step-by-step instruction guide students toward mastery and appreciation of 21st Century communication in all its forms and functions.",
                  ],
                  majorConcepts: [
                    "Foundational skills for reading and writing",
                    "Literary elements and devices",
                    "Fluency in reading and writing",
                    "Collaborative discussions",
                    "Peer review process and strategies",
                    "Forms of fiction",
                    "Comparing literature",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 4 includes the three main domains of science which are physical, life, and earth and space science. Learners will use various kinds of experimenting, including field studies, systematic observations, models, and controlled experiences. The course begins with the explanation of the scientific method, which the students continue to use and build upon throughout the course.  The big picture of the earth is examined as students review the life on planet earth, salt and fresh water, and fast and slow changes that occur on the planet. Students go beyond planet earth, though, as they study galaxies, the solar system, and other planets. Students examine the ways that forces and motion can be measured and the concept that a single kind of matter can exist as a solid, liquid, or gas. Science 4 uses many modes of instruction including video presentations, enrichment activities, and hands-on experimentation.",
                  ],
                  majorConcepts: [
                    "The Scientific Method",
                    "Planet Earth",
                    "The Atmosphere and Air",
                    "The Weather",
                    "Galaxies and Stars",
                    "The Solar System and Planets",
                    "Measurements and Instruments",
                    "Matter",
                    "Forces of Motion",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In Semester A of Social Studies 4, students will explore the early development of the United States. Students will explore the early Native Americans and interactions with early European Settlers, the establishment of the American colonies, and early American government. Students will learn about important documents in the founding of the United States and the establishment of rules and laws that has led to the formation of the federal and state governments as we know them today. Students will have the opportunity to explore their own state government and learn more about the rules and regulations that govern where they live.",
                  ],
                  majorConcepts: [
                    "Native Americans",
                    "European Settlers",
                    "Mexican American War",
                    "Federal Government",
                    "State Government",
                    "Civil War",
                    "Important Documents",
                    "Living in America",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "Semester B of Math 4 has learners continuing to work with fractions. They will learn to multiply fractions and convert them to decimals. Students will also begin to learn to equivalent measurements of length, weight, mass, and capacity. They will also learn helpful skills in understanding time, distance, and money. Students will develop an understanding that geometric figures can be analyzed and classified based on their properties, such as having parallel sides, perpendicular sides, particular angle measures, and symmetry. Lessons on rectangles, line plots, angles, figure drawing, polygons, and symmetry will be taught. Semester B continues to use varied forms of instruction that allow students to learn these skills in a practical manner.",
                  ],
                  majorConcepts: [
                    "Fraction multiplication",
                    "Fractions to decimals",
                    "Length",
                    "Measuring weight, mass, and capacity",
                    "Time, distance, and money",
                    "Rectangles",
                    "Line plots",
                    "Angles",
                    "Polygons",
                    "Symmetry",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "Like Semester A, Semester B provides an integrated curriculum. Whereas Semester A focuses on skills needed to read fiction and other literary prose, Semester B teaches specific skills for reading poetry, drama, and informational text. In the second semester of the course, students learn how informational text differs from literary text and how different forms of information text differ from each other. Writing assignments emphasize expository writing and guide students through research projects. Near the end of the semester, students learn how to present information orally and using multimedia.",
                  ],
                  majorConcepts: [
                    "Elements of poetry and drama",
                    "Reading strategies for informational text",
                    "Text structures",
                    "Research stages and strategies",
                    "Analyzing and evaluating information",
                    "Writing research reports",
                    "Media literacy",
                    "Oral and multimedia presentations",
                    "English language conventions",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Semester B of Science 4 focuses on the relationship between heat, light, sound, and electrical energy and the way they can be transferred between each other. Learners distinguish between natural objects and objects made by humans as they examine technology and the role it plays in science. Students also look at life cycles of animals, plants, and humans and how they interact with each other. The course ends by looking at the ways that humans interact with the environment. Students will use research skills, watch videos, and get their hands dirty as they complete projects that require them to dig through dirt and trash in order to learn broader lessons that have to do with helping the environment.",
                  ],
                  majorConcepts: [
                    "Sound and Light",
                    "Heat and Temperature",
                    "Electricity and Magnetism",
                    "Science and Technology",
                    "Characteristics of Life",
                    "Animals and Plants",
                    "The Human Body",
                    "Biodiversity and Extinction",
                    "Humans Interaction with the Environment",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In Semester B of Social Studies 4, students will expand on their learning from Semester A, as well as work their way through American History to post-WWII and science and inventions that started shaping the modern-day United States. Various concepts including economics, the environment, and American geography will be explored to give students a better idea of all the facets that shape American lives today.",
                  ],
                  majorConcepts: [
                    "Economics",
                    "Environment",
                    "Geography",
                    "Human Geography",
                    "Cultural Awareness",
                    "Great Depression",
                    "World War II",
                    "Science and Innovations",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 5,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "Students will learn math topics outlined in this course drawing from a variety of sources, including hands-on activities, interactive lessons, and practical math applications. Students will focus on several critical areas including but not limited to developing fluency with addition, subtraction, multiplication, and division of fractions. They will also learn to extend division to 2-digit divisors, integrate decimal fractions into the place value system, and increase an understanding of operations with decimals to hundredths. They will develop a fluency with whole numbers and decimal operations. The semester begins with operations and expressions, moves into decimals and money, and ends with more work on fractions. Learners will gain valuable skills as they carry out activities that model real life situations, like grocery shopping, throughout the semester.",
                  ],
                  majorConcepts: [
                    "Operations and Expressions",
                    "Patterns and Graphs",
                    "Place Values",
                    "Powers of Ten",
                    "Decimals",
                    "Fractions",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "The Language Arts 5 curriculum integrates reading, writing, speaking, listening, and the study of vocabulary and grammar in a way that engages today’s learners and supports them in building a broad and diverse set of literacy skills. Students study classic literature as well as more contemporary forms, including media and multimedia products. Writing assignments in Semester A focus on narrative and persuasive modes and emphasize the use of reasoning and details to support opinions. Each writing assignment spans several lessons and guides students through a writing process that begins with prewriting and ends by emphasizing one or more aspects of conventions of standard written English. Students also learn how to participate in collaborative discussion and peer review sessions. In each lesson, engaging and relevant models and step-by-step instruction guide students toward mastery and appreciation of 21st Century communication in all its forms and functions.",
                  ],
                  majorConcepts: [
                    "Foundational skills for reading and writing",
                    "Literary elements and devices",
                    "Literary forms, including poetry and drama",
                    "Fluency in reading and writing",
                    "Collaborative discussions",
                    "English language conventions",
                    "Classic literature",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 5 continues to build on the science skills that have been obtained in years previous. There will be an emphasis on earth and space science, life science, and physical science. Students will begin the course by focusing on earth and space science by looking at the solar system and planets. Students will come to an understanding of the concept of the earth as a sphere and the earth’s place in the solar system. The course continues with a focus on physical science and the different tools that can measure force, time, and distance. They will also grow in their understanding of how light and sound travel and interact with each other, as well as the different types of energy. The semester concludes with a look into life science and the ways that organisms are interconnected. Instruction will include real life application, hands-on projects and assessments, and video and short research projects.",
                  ],
                  majorConcepts: [
                    "Scientific Inquiry: Ability and understanding of scientific inquiry",
                    "Physical Science: Forces and Movement, Lights and Sound, Light, heat, electricity, and magnetism",
                    "Life Science: Energy and Food Chains, Producers and Consumers",
                    "Earth and Space Science: The Earth, Objects in the sky, Changes in earth and sky, The solar system, The Stars and Sun",
                    "Science and Technology: Natural objects vs. objects made by humans, Understanding about science and technology",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "Grade 5 Social Studies asks students to use their understanding of social studies skills and concepts as they study the development of the United States. The first semester begins with a look at the geography of the regions of the United States, then moves into an examination of the development of the first cultures of the North American continent. From there, students will learn about the course and consequences of European exploration and colonization of North America. Students will also come to understand the causes of the Revolutionary War and the people that played significant roles in it.",
                  ],
                  majorConcepts: [
                    "The United States of America",
                    "The First Americans",
                    "The Age of Exploration",
                    "The Colonial Age",
                    "The American Economy",
                    "The American Revolution",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "Semester B begins with students continuing to work with fractions. The first lesson focuses on ratios and challenges students to solve word problems using fractions and ratios in practical life situations. Learners continue to strengthen their math skills by studying mixed and fraction products and fraction application, models, and division. The third critical area that students will focus on in Grade 5 Math is volume. Students will receive lessons in measurement of length, weight, and volume. They will end the course with a focus on geometry. Varied types of instruction are used to enhance their learning, including video and real life applications, activities, and creative projects.",
                  ],
                  majorConcepts: [
                    "Ratios",
                    "Fractions",
                    "Formulas",
                    "Measurement Conversions",
                    "Length, weight, and volume",
                    "Geometry",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "Like Semester A, Semester B provides an integrated curriculum.  Whereas Semester A focuses on skills needed to literary text, Semester B focuses on skills for reading and analyzing informational text. In Semester B, students learn how informational text differs from literary text and how different forms of information text differ from each other. Writing assignments emphasize expository writing and guide students through research projects. Near the end of the semester, students learn how to present information orally and using multimedia.",
                  ],
                  majorConcepts: [
                    "Strategies for reading informational text",
                    "Informational text structures",
                    "Research stages and strategies",
                    "Analyzing and evaluating information",
                    "Writing research reports",
                    "Media literacy",
                    "Oral and multimedia presentations",
                    "Peer review process and strategies",
                    "English language conventions",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Semester B puts great emphasis on life science and begins by focusing on the many ecosystems of the earth and the way that all parts of ecosystems depend on each other. Students will learn the different types of ecosystems that exist. They will learn that ecosystems change and how the changes affect their ability to support their populations. Learners will examine plants to see that they have different structures and how those structures allow them to respond to different needs. Students will also grow in their understanding of the importance of good nutrition to all living organisms. The course concludes with a look into the scientific process and the importance of investigations and conclusions in the study of science. Instruction will include real life application, hands-on projects and assessments, and video and short research projects.",
                  ],
                  majorConcepts: [
                    "Scientific Inquiry: Ability and Understanding of Scientific Inquiry",
                    "Life Science: Ecosystems, Plants: Stems and Transportation, Plants: Photosynthesis, Plants: Reproduction, Nutrition, Cell Division",
                    "Science and Technology, Natural Objects vs. Objects Made by Humans, Understanding Science and Technology",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "Semester B of Grade 5 Social Studies will focus more on the basic principles of American government and citizenship, as well as the development of the founding documents of the country. Historical exploration will continue with an overview of the major events of the 19th, 20th, and early 21st Centuries.",
                  ],
                  majorConcepts: [
                    "The Constitution of the United States",
                    "Government and Citizenship",
                    "American Grows (1800-1850)",
                    "Rebuilding a Nation (1850-1900)",
                    "America in the 20th Century",
                    "My America (Life in Modern America)",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 6,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "Students begin the first semester of this course with a review of how to use basic arithmetic operations with whole numbers, fractions, mixed numbers, and decimals. More complex concepts are built on these basics. Students learn how to express, work with, and solve problems using percentages. They also learn the similarities and differences between ratios, rates, and proportions. They apply these ideas to solving problems involving measurement. This semester ends with an introduction to integers, and how to perform operations on this number set.",
                  ],
                  majorConcepts: [
                    "Whole numbers and decimals",
                    "Fractions",
                    "Percentages",
                    "Rates and ratios",
                    "Proportional relationships",
                    "Integers",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "Through a study of myths, fables, and folk tales from different cultures—as well as novels and other modern forms of narrative, students learn the elements common to all forms of literature and also the elements that are unique to each form. In lessons focused on writing and language study, students craft essays in several different modes and learn how to create the more formal style expected for school writing assignments. Lessons in this semester guide students to recognize and reproduce text structures and organizational patterns that work for different types of essays. The writing lessons also demonstrate the kinds of changes that students should make during the revising and editing stages of the writing process. Opportunities for teacher feedback are frequent, detailed, and varied.",
                  ],
                  majorConcepts: [
                    "Reading Skills and Strategies",
                    "Recognizing and Describing Literary Elements",
                    "Comparing and Contrasting Forms of Narrative",
                    "Recurring Themes in Literature",
                    "Methods of Collaboration",
                    "Word Study Skills",
                    "Modes of Essay Writing",
                    "Stages of the Writing Process",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 6 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Introduction to Science",
                    "Forces and Gravity",
                    "The Stars",
                    "The Earth",
                    "The Moon",
                    "The Solar System",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "The first semester of Social Studies 6 introduces students to the beginnings of ancient civilization. We will trace the path of human origins in Africa and follow the path of migration around the Earth. This course will help students understand why we study history and the process in which we form conclusions about events in the past. Students will begin to learn about the major ancient civilization around the world and their cultures. Modern civilizations can trace their foundations to these ancient civilizations, and their cultures and histories teach us much about ourselves and the modern world in which we live.",
                  ],
                  majorConcepts: [
                    "Studying History & Time’s Beginning",
                    "Fertile Crescent Civilizations",
                    "Ancient Egypt",
                    "Ancient American Civilizations",
                    "Polytheism to Monotheism",
                    "The Mediterranean",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "In the second semester of grade 6 Math online course, we introduce students to expressions, equations, and inequalities. They learn how to simplify, solve, and plot both solutions and solution sets on a number line. Building on these concepts, students are then introduced to the coordinate plane and linear equations. Students then learn how to apply what they’ve learned so far to geometric concepts like perimeter, area, and volume. An exploration of statistical concepts concludes the second semester.",
                  ],
                  majorConcepts: [
                    "Expressions",
                    "Equations",
                    "Inequalities",
                    "Coordinate plane",
                    "Geometry",
                    "Statistics",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "The second semester of grade 6 English Language Arts online course builds on the skills and concepts introduced in the first semester. Students tackle more difficult texts in Semester B and apply more advanced analysis skills to reading and writing tasks. They also study some of the more subtle aspects of language, such as the role of connotation and nuance in an author’s word choices and how those choices affect readers. Reading assignments are selected, in part, to provide models for students’ own writing in specific modes, forms, or genres. Several lessons demonstrate methods of sharing and publishing writing using 21st century technology.",
                  ],
                  majorConcepts: [
                    "Uses of Figurative Language",
                    "Connotation, Denotation, and Nuance",
                    "Interpreting and Analyzing Literature",
                    "Reading and Summarizing Informational Texts",
                    "Coherence and Cohesion in Writing",
                    "Interpreting Multimedia Messages",
                    "Using Technology to Share and Publish",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Semester B of Integrated Science grade 6 builds on the concepts learned in the first semester and prepares the learners with the building blocks needed to dive deeper into earth and space science, life science, and physical science.",
                  ],
                  majorConcepts: [
                    "Ecosystems",
                    "The Energy Flow of Life",
                    "Green Plants",
                    "Energy",
                    "Heat",
                    "Newton’s Laws",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In the second semester of Social Studies grade 6, students will continue to examine ancient civilizations and their cultures. In this semester we will continue to trace the path of human civilization from the Mediterranean through the Eastern world. An emphasis will be placed on critical thinking and connecting themes in history to our modern world.",
                  ],
                  majorConcepts: [
                    "Early Rome",
                    "Roman Empire",
                    "Indian Civilizations",
                    "Classical China",
                    "Post Classical China",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 7,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "In this first semester of grade 7 math online course, students work with problem-solving skills, beginning algebra skills, geometry, decimals, fractions, data analysis, number theory and patterns, percents, and integer use. Projects measure the student’s ability to integrate and apply the course objectives.",
                  ],
                  majorConcepts: [
                    "Problem Solving and an Introduction to Algebra.",
                    "Combining Algebra and Geometry.",
                    "Learning About Decimals.",
                    "Applying Decimals.",
                    "Analyzing Data.",
                    "More Data Analysis and Applications.",
                    "Divisibility, Factors, and Simplifying Fractions.",
                    "Using Fractions and Percents.",
                    "Introduction to Integers.",
                    "Using and Applying Integers.",
                    "Exploring and Solving Equations.",
                    "An Introduction to Inequalities, Functions, and their Graphs",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "Through analysis of written, spoken, and multimedia texts, students will become more critical consumers of information and of various forms of media. They will also synthesize and organize ideas to prepare structured essays in several different modes, including narrative, persuasive, and expository. Each lesson will guide students in learning and applying specific strategies for reading and writing different types of texts.  A review of basic English mechanics is included in many of the writing lessons, along with a discussion of levels of formality required for different purposes and audiences. This course provides instruction in many modalities, including audiovisual presentations and videos, interactive activities, projects, and discussions. Opportunities for teacher feedback are frequent, detailed, and varied.",
                  ],
                  majorConcepts: [
                    "The Structure of Narrative",
                    "Reading Skills and Strategies",
                    "Writing Persuasively",
                    "Evaluating Arguments",
                    "Elements of Drama",
                    "Media Analysis",
                    "Forms of Research",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 7 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "The Nature of Science",
                    "Waves and Sound",
                    "Light",
                    "Lenses",
                    "Electricity",
                    "Magnets and Electricity",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "This study of the history of the United States emphasizes how ideas, events, and philosophies have shaped the nation. Students will learn about America’s past while mastering the skills of historical interpretation. Study begins with the earliest arrivals of people and ends with the conclusion of the Civil War.",
                  ],
                  majorConcepts: [
                    "The First Americans",
                    "Exploring the Americas",
                    "Colonial America",
                    "The Colonies Grow",
                    "Road to Independence",
                    "The American Revolution",
                    "A More Perfect Union",
                    "A New Nation",
                    "The Jefferson Era",
                    "Growth and Expansion",
                    "The Jackson Era",
                    "Manifest Destiny",
                    "North and South",
                    "The Age of Reform",
                    "Road to Civil War",
                    "The Civil War",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "In this continuation of the first semester, students work with fractions; unit conversions; proportions and rates; percents; geometry topics including lines, angles, polygons, polyhedrons, perimeter, area, surface area, volume, and transformations; squares and square roots; permutations and combinations; and probability. Real-life application of concepts is emphasized in all units.",
                  ],
                  majorConcepts: [
                    "Adding, Subtracting, Multiplying, and Dividing Fractions.",
                    "Changing Customary Units, Perimeter, Circumference.",
                    "Ratios, Rates, and Proportions.",
                    "Percents, Fractions, and Decimals.",
                    "Percent Equations, Statistics.",
                    "Percents and the Consumer.",
                    "Angles, Perpendicular and Parallel Lines.",
                    "Polygons, Triangles, Quadrilaterals, Transformations.",
                    "Squares and Square Roots, Area of Polygons and Circles.",
                    "Surface Area and Volume of Three-Dimensional Figures.",
                    "Theoretical and Experimental Probability, Tree Diagrams, Fundamental Counting Principle.",
                    "Independent and Dependent Events, Permutations, Combinations.",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "The second semester of grade 7 English Language Arts online course builds on the skills and concepts introduced in the first semester. Students tackle more difficult texts and themes in Semester B, and the level of analysis demonstrated and required is more in-depth. In this part of the course, students study the English language closely—both its history and evolution, and the less obvious ways it can be used to convey meaning.  The reading assignments are selected to guide students in understanding how language can be used to convey broader themes in poetry, drama, and humorous or satirical texts.  Students continue to develop their writing skills through multi-draft assignments and projects. Emphasis in this semester is on recognizing the multiple levels of meaning that any word or phase might convey, and in writing one’s own texts with these concepts in mind.",
                  ],
                  majorConcepts: [
                    "Elements of Poetry",
                    "History of Language",
                    "Precision of Word Choice",
                    "Style and Syntax",
                    "Levels of Formality",
                    "Forms of Narrative",
                    "Using Technology",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Semester B of Integrated Science Grade 7 builds on the concepts learned in the first semester and prepares the learners with the building blocks needed to dive deeper into earth and space science, life science, and physical science.",
                  ],
                  majorConcepts: [
                    "Matter and Chemical Reactions",
                    "Cells",
                    "The Digestive System and Human Nutrition",
                    "The Respiratory and Circulatory Systems",
                    "Body Movement",
                    "Body Systems – Information Processing and Chemical Messages",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "Social Studies grade 7 is a continuation of the first semester with an emphasis on how historical ideas, events, and philosophies have shaped the United States. Beginning with Reconstruction, this course uses the same skill development approach to guide students through U.S. history to the present.",
                  ],
                  majorConcepts: [
                    "Reconstruction and its Aftermath",
                    "The Western Frontier",
                    "The Growth of Industry",
                    "Toward an Urban America",
                    "Progressive Reforms",
                    "Overseas Expansion",
                    "World War I",
                    "The Jazz Age",
                    "The Depression and FDR",
                    "World War II",
                    "The Cold War Era",
                    "America in the 1950s",
                    "The Civil Rights Era",
                    "The Vietnam Era",
                    "Search for Stability",
                    "New Challenges",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 8,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The first semester of grade 8 Math online course will help students move from the world of simple mathematics to the exciting world of Algebra and Geometry and will provide them with a concrete understanding of the basics for algebraic thinking. Students will develop a deeper understanding of the math concepts they have already learned and will stretch their thinking by solving real world problems.",
                  ],
                  majorConcepts: [
                    "Number Operations",
                    "Powers and Exponents",
                    "Solving Equations and Inequalities",
                    "Graphing Linear Equations",
                    "Angles and Congruence",
                    "The Pythagorean Theorem",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "historical fiction",
                    "literary analysis",
                    "collaborative discussions",
                    "scientific texts (structure and style)",
                    "historical documents",
                    "primary vs. secondary sources",
                    "journalism",
                    "media literacy",
                    "dramatic irony",
                    "playwriting and screenwriting",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 8 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Science Then and Now",
                    "Simple and Compound Machines",
                    "Modern Machines",
                    "Earth’s History and Structure",
                    "Air, Climate, and Weather",
                    "Water",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "Introduction to civics and why we need government",
                    "Foundation of America’s Political System",
                    "American Citizenship",
                    "Federal Government",
                    "State Government",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The second semester of Math 8 builds on the concepts learned in the first semester and prepares students with the building blocks needed to dive deeper into the exciting world of Algebra and Geometry.",
                  ],
                  majorConcepts: [
                    "Proportional Relationships",
                    "Transformations and Similarity",
                    "Functions",
                    "Systems of Linear Equations and Inequalities",
                    "Bi-Variate Data",
                    "Types of Numbers",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "In Semester B of grade 8 English Language Arts online course, students will examine the role of historical autobiographies and diaries in our understanding of history. In the process, they’ll study the impact of point of view on nonfiction texts. Students will be given opportunities to write autobiographical narratives of their own and then asked to connect their experiences to universal themes or philosophical positions, which they explore through writing about them. In the second half of the semester, students will study the relationship between poetic expression and several conventions of language, including syntax, voice, sentence types, and punctuation. Next, they will explore the nature of creativity, the processes that tend to produce good literature, and the features of experimental and multi-genre forms of fiction. Near the end of the semester, students will reflect on their own growth and development throughout the year, compiling a portfolio that illustrates the progress they’ve made. Finally, students will consider what high school will ask of them and how they might fulfill those expectations, having gained a better understanding of their strengths as well as areas ripe for continued learning and progress.",
                  ],
                  majorConcepts: [
                    "forms of autobiography",
                    "writing to reflect on experience",
                    "methods of persuasion",
                    "revising for unity and coherence",
                    "public speaking",
                    "debate",
                    "digital storytelling",
                    "poetic devices",
                    "language conventions",
                    "modern forms of short fiction",
                    "portfolios, self-assessment",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Semester B of Integrated Science Grade 8 builds on the concepts learned in the first semester and prepares the learners with the building blocks needed to dive deeper into earth and space science, life science, and physical science.",
                  ],
                  majorConcepts: [
                    "Reproduction, Heredity, and Evolution",
                    "Classification of Living Things",
                    "Invertebrates",
                    "Vertebrates",
                    "Pollution",
                    "Human Impacts on Populations",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "Social Studies grade 8 takes a more individualistic approach as students closely examine topics such as the justice system, local government, the environment, and the economy.  Learners will understand the role that they play in each of these topics and the differences that they can make.  Students will get to know leaders and influential people that have championed many causes including civil rights and the environment.  Learners will also learn proper ways to interact in society including interpersonal skills and respecting differences in others including disabilities.  By the end of semester B students will have a deeper understanding of their civic responsibilities as well as the difference one individual can make in society.",
                  ],
                  majorConcepts: [
                    "The Justice System",
                    "Local Government and Your Community",
                    "The Environment",
                    "Civics and You",
                    "Civics and the Economy",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 9,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The first semester of grade 9 Math online course will help students move from the world of simple mathematics to the exciting world of Algebra and Geometry and will provide them with a concrete understanding of the basics for algebraic thinking. Students will develop a deeper understanding of the math concepts they have already learned and will stretch their thinking by solving real world problems.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 9",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 9 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The second semester of Math 9 builds on the concepts learned in the first semester and prepares students with the building blocks needed to dive deeper into the exciting world of Algebra and Geometry.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 9",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 9 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 10,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The first semester of grade 10 Math online course will help students move from the world of simple mathematics to the exciting world of Algebra and Geometry and will provide them with a concrete understanding of the basics for algebraic thinking. Students will develop a deeper understanding of the math concepts they have already learned and will stretch their thinking by solving real world problems.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 9",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 10 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The second semester of Math 9 builds on the concepts learned in the first semester and prepares students with the building blocks needed to dive deeper into the exciting world of Algebra and Geometry.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 9",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 10 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 11,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The first semester of grade 11 Math online course will help students move from the world of simple mathematics to the exciting world of Algebra and Geometry and will provide them with a concrete understanding of the basics for algebraic thinking. Students will develop a deeper understanding of the math concepts they have already learned and will stretch their thinking by solving real world problems.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 9",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 11 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The second semester of Math 9 builds on the concepts learned in the first semester and prepares students with the building blocks needed to dive deeper into the exciting world of Algebra and Geometry.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 9",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 11 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
          ],
        },
        {
          grade: 12,
          content: [
            {
              semester: "A",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The first semester of grade 12 Math online course will help students move from the world of simple mathematics to the exciting world of Algebra and Geometry and will provide them with a concrete understanding of the basics for algebraic thinking. Students will develop a deeper understanding of the math concepts they have already learned and will stretch their thinking by solving real world problems.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 12",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 12 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
            {
              semester: "B",
              duration: "4 Months",
              courseDetails: [
                {
                  subject: "MATH",
                  description: [
                    "The second semester of Math 9 builds on the concepts learned in the first semester and prepares students with the building blocks needed to dive deeper into the exciting world of Algebra and Geometry.",
                  ],
                  majorConcepts: [
                    "Algebra 1",
                    "Algebra 2",
                    "Consumer Math",
                    "Geometry",
                    "Integrated Math 1",
                    "Integrated Math 2",
                    "Integrated Math 3",
                    "Math Essentials",
                    "Pre-Algebra",
                    "Pre-Calculus",
                  ],
                },
                {
                  subject: "Language Arts",
                  description: [
                    "During the first semester of this year-long course, students will read and analyze various kinds of written texts, include novels and short fiction, informational texts representing a wide range of topics and forms, and several one-act plays. Lessons in Semester A will also guide students in writing their own narratives and essays, using the readings in the course as both examples and sources of ideas for reflection, analysis, and argument. Students will learn better ways to discuss their thoughts and perceptions with others—they will practice their skills in collaborative discussions as well as informal journal entries, presentations, and speeches. Writing assignments include personal narratives, analytical and persuasive essays, and an original one-act play. Special emphasis is placed on reading in certain content areas, such as science and history, as well as understanding and thinking critically about news and media sources.",
                  ],
                  majorConcepts: [
                    "Creative Writing",
                    "Contemporary Novels",
                    "Language Arts 9",
                    "Speech",
                  ],
                },
                {
                  subject: "Science",
                  description: [
                    "Science 12 is an integrated course in which the fields of science are not compartmentalized. Instead, earth and space science, life science, and physical science are integrated within each semester. Semester A begins with instruction on the nature of science.",
                    "The course focuses on both the understanding and application of science topics. It includes a variety of assignments that help students apply their knowledge of science concepts. Throughout each module, there are multiple opportunities for formative assessment.",
                  ],
                  majorConcepts: [
                    "Anatomy & Pysiology",
                    "Biology",
                    "Chemistry",
                    "Earth Science",
                    "Marine Science",
                    "Paleontology",
                    "Physical Science",
                    "Physic",
                    "Renewable Energy",
                    "Space Exploration",
                  ],
                },
                {
                  subject: "Social Studies",
                  description: [
                    "In this course students will understand the significance of government, law, and politics.  They will examine the United States foundational documents and how they shaped the Unites States government.  Students will examine the purposes and functions of federal and state government, law, and political systems.  Learners will evaluate their role and civic responsibility to their families, communities, and country including voting and being a productive member of society.   Learners will follow a step-by-step approach for successfully completing each lesson, which includes textbook reading, interactive activities, supplemental reading, lecture, video clips, and Power Point presentations to enhance and reinforce learning. Learners receive frequent feedback from teacher and peers through discussions.",
                  ],
                  majorConcepts: [
                    "American Govenment",
                    "American History",
                    "Civics",
                    "Economics",
                    "Ethnic Studies",
                    "World Geography & Cultures",
                    "World History",
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return data;
};
