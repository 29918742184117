import './App.css';

import Router from './routes/sections';
import GlobalStyles from './component/GlobalStyles/GlobalStyles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <GlobalStyles>
      <Router/>
      <ToastContainer closeButton={false} position='top-right' autoClose={3000}/>
    </GlobalStyles>
  );
}

export default App;
