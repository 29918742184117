import { createSlice } from "@reduxjs/toolkit";

interface InitialStateStyle{
    language: string;
}

const initialState:InitialStateStyle = {
    language: "en"
}

const TranslateSlice= createSlice({
    name: "translate",
    initialState,
    reducers: {
        updateLanguage: (state, action)=>{
            state.language=action.payload;
        }
    }
});

export const { updateLanguage }= TranslateSlice.actions;
export default TranslateSlice.reducer;