import axios from "axios";
import { baseUrl } from "../../utils/Axios-interceptor";
import { SortPriceStyle } from "../../redux/BookSlice/InterfaceResponse";

export const GetAllBook = async () => {
  try {
    const res = await axios.get(baseUrl + "/user/books");
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const GetBookById = async (id: number) => {
  try {
    const res = await axios.get(baseUrl + `/user/books/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const GetBookByPageNo = async (page: number) => {
  try {
    const res = await axios.get(baseUrl + `/user/books?pageNo=${page}`);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const GetBookCategory = async () => {
  try {
    const res = await axios.get(baseUrl + `/user/books/category`);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const GetAllBookByBookCategory = async (bookCategoryId: number) => {
  try {
    const res = await axios.get(
      baseUrl +
        `/user/books/types?bookCategoryId=${bookCategoryId}&sortBy="id:asc"`
    );
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const GetAllBookBySortPrice = async (data: SortPriceStyle) => {
  try {
    const res = await axios.get(
      baseUrl +
        `/user/books/sort?${
          data.style !== undefined ? `sortBy=${data.style}` : ""
        }${
          data.bookCategoryId !== undefined
            ? `&bookCategoryId=${data.bookCategoryId}`
            : ""
        }${data.pageNo !== undefined ? `&pageNo=${data.pageNo}` : ""}`
    );
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const GetAudioOfBookWithUser = async () => {
  try {
    const res = await axios.get(`${baseUrl}/user/books/get-book-radio`);
    if (res) {
      return res;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};