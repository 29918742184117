import { axiosInstance } from "../../utils/Axios-interceptor";
import {CapturePayment, PaymentData} from "../../redux/OrderSlice/InterfaceResponse";


export const CreateOrderAPI= async (data:PaymentData)=>{
    try{
        const response= await axiosInstance.post('/payment/createOrder',data);
        return response;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const CreateOrderWithVNPayService= async (data: PaymentData)=>{
    try{
        const response= await axiosInstance.post('/payment/create-order/vn-pay',data);
        return response as unknown as string|undefined;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const CreateOrderWithPaypalService= async (data: PaymentData)=>{
    try{
        const response= await axiosInstance.post('/payment/create-order/paypal',data);
        return response as unknown as string|undefined;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const CreateOrderWithDeliveryService= async (data: PaymentData)=>{
    try{
        const response= await axiosInstance.post('/payment/create-order/delivery',data);
        return response as unknown as string|undefined;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const UpdateStatusOrderService= async (data: CapturePayment)=>{
    try{
        const response: string|undefined = await axiosInstance.post(`/payment/capture-order?transactionId=${data.transactionId}&status=${data.status}&message=${data.message}`,data.orderData);
        return response;
    }catch(err:any){
        throw new Error(err.message);
    }
}