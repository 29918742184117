import { createSlice } from "@reduxjs/toolkit";


const initialState= {
    loading: false,
    error: ""
}

export const CourseSlice= createSlice({
    name: "courses",
    initialState,
    reducers: {
        updateStateLoading: (state)=>{
            state.loading=!state.loading;
        }
    }
});

export const { updateStateLoading }= CourseSlice.actions;
export default CourseSlice.reducer;