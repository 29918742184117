// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Suspense, lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import RoyalLightLayout from "../layout";
import { Loader } from "../component/loader";
export const HomePage = lazy(() => import("../pages/Home"));
export const CourseRegisterPage = lazy(() => import("../pages/CourseRegister"));
export const CoursesPage = lazy(() => import("../pages/Courses"));
export const CoursesPrice = lazy(() => import("../pages/CoursesPrice"));
export const BlogPage = lazy(() => import("../pages/Blog"));
export const AboutPage = lazy(() => import("../pages/About"));
export const CommingSoonPage = lazy(() => import("../pages/CommingSoon"));
export const BlogDetailPage = lazy(() => import("../pages/BlogDetail"));
export const TeacherDetailPage = lazy(() => import("../pages/TeacherDetail"));
export const TeacherPage = lazy(() => import("../pages/Teacher"));
export const EbookPage = lazy(() => import("../pages/Ebook"));
export const CartPage = lazy(() => import("../pages/Cart"));
export const PaypalPage = lazy(() => import("../pages/PaypalTest"));
export const LoginPage = lazy(() => import("../pages/Login"));
export const RegisterPage = lazy(() => import("../pages/Register"));
export const PaymentPage = lazy(() => import("../pages/Payment"));
export const PaymentSuccessPage = lazy(() => import("../pages/PaymentSuccess"));
export const PaymentFailedPage = lazy(() => import("../pages/PaymentFailed"));
export const BookDetailPage = lazy(() => import("../pages/BookDetail"));
export const OTPPage = lazy(() => import("../pages/OTP"));
export const ForgotPasswordPage = lazy(() => import("../pages/ForgotPassword"));
export const AudioTheBookPage = lazy(() => import("../pages/AudioTheBook"));

export default function Router() {
    const routes = useRoutes([
        {
            element: (
                <RoyalLightLayout>
                    <Suspense fallback={<Loader color="569db9" width={50} />}>
                        <Outlet />
                    </Suspense>
                </RoyalLightLayout>
            ),
            children: [
                // { element: <HomePage />, index: true },
                // { path: "/home", element: <HomePage /> },
                // { path: "/course-register", element: <CourseRegisterPage /> },
                // { path: "/courses/price", element: <CoursesPrice /> },
                // { path: "/courses/*", element: <CoursesPage /> },
                // { path: "/blog", element: <BlogPage /> },
                // { path: "/blog/*", element: <BlogDetailPage /> },
                // { path: "/about", element: <AboutPage /> },
                // { path: "/e-book/*", element: <EbookPage /> },
                { path: "/teacher/detail", element: <TeacherDetailPage /> },
                // {
                //     path: "/sign-in",
                //     element: <Navigate to="/coming-soon" replace />,
                // },
                { path: "/paypal", element: <PaypalPage /> },
                { element: <HomePage />, index: true },
                { path: "/home", element: <HomePage /> },
                { path: "/course-register", element: <CourseRegisterPage /> },
                { path: "/courses/price", element: <CoursesPrice /> },
                { path: "/courses/*", element: <CoursesPage /> },
                { path: "/blog", element: <BlogPage /> },
                { path: "/blog/*", element: <BlogDetailPage /> },
                { path: "/about", element: <AboutPage /> },
                { path: "/teacher/detail", element: <CommingSoonPage /> },
                { path: "/coming-soon", element: <CommingSoonPage /> },
                { path: "/teacher", element: <CommingSoonPage /> },
                { path: "/cart", element: <CartPage /> },
                { path: "/paypal", element: <PaypalPage /> },
                { path: "/login", element: <LoginPage /> },
                { path: "/register", element: <RegisterPage /> },
                { path: "/payment", element: <PaymentPage /> },
                { path: "/payment-success", element: <PaymentSuccessPage /> },
                { path: "/payment-failed", element: <PaymentFailedPage /> },
                { path: "/e-book/*", element: <EbookPage /> },
                { path: "/e-book/types", element: <EbookPage /> },
                { path: "/e-book/sort", element: <EbookPage /> },
                { path: "/e-book/:id", element: <BookDetailPage /> },
                { path: "/otp/auth", element: <OTPPage /> },
                { path: "/audio-the-book", element: <AudioTheBookPage /> },
            ],
        },
        {
            path: "forgot-password",
            element: <ForgotPasswordPage />,
        },
        {
            path: "404",
            element: <CommingSoonPage />,
        },
        {
            path: "*",
            element: <Navigate to="/404" replace />,
        },
    ]);
    return routes;
}
