import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GetAllBook,
  GetAllBookByBookCategory,
  GetAllBookBySortPrice,
  GetAudioOfBookWithUser,
  GetBookById,
  GetBookByPageNo,
  GetBookCategory,
} from "../../services/BookAPI/BookAPI";
import { ApiResponse, BookCategory, BookRadio, SortPriceStyle } from "./InterfaceResponse";

interface InitialStateStyle {
  Loading: boolean;
  IsFilterBookByCategory: number;
  IsFilterBookByPrice: string;
  Error: string;
  Books: ApiResponse[];
  BooksCategoryTemp: ApiResponse[];
  BooksPriceTemp: ApiResponse[];
  BookDetail: ApiResponse | undefined;
  BookCategorys: BookCategory[];
  PageNo: number;
  bookRadios: BookRadio[];
}

export const getAllBooks = createAsyncThunk<ApiResponse[], void>(
  "getAllBooks",
  async () => {
    try {
      const response = await GetAllBook();
      return response.data as ApiResponse[];
    } catch (err: any) {
      throw Error(err.message);
    }
  }
);
export const getBookById = createAsyncThunk<ApiResponse, number>(
  "getBookById",
  async (id: number) => {
    try {
      const response = await GetBookById(id);
      return response.data as ApiResponse;
    } catch (err: any) {
      throw Error(err.message);
    }
  }
);
export const getBookByPageNo = createAsyncThunk<ApiResponse[], number>(
  "getBookByPageNo",
  async (page: number) => {
    try {
      const response = await GetBookByPageNo(page);
      return response.data as ApiResponse[];
    } catch (err: any) {
      throw Error(err.message);
    }
  }
);

export const getAllBookCategory = createAsyncThunk<BookCategory[], void>(
  "getBookCategory",
  async () => {
    try {
      const response = await GetBookCategory();
      return response as BookCategory[];
    } catch (err: any) {
      throw Error(err.message);
    }
  }
);
export const getAllBookByBookCategory = createAsyncThunk<
  { payload: ApiResponse[]; bookCategory: number },
  number
>("getAllBookByBookCategory", async (bookCategoryId: number) => {
  try {
    const response = await GetAllBookByBookCategory(bookCategoryId);
    return {
      payload: response as ApiResponse[],
      bookCategory: bookCategoryId,
    } as {
      payload: ApiResponse[];
      bookCategory: number;
    };
  } catch (err: any) {
    throw Error(err.message);
  }
});
export const getAllBookBySortPrice = createAsyncThunk<
  { payload: ApiResponse[]; sortStyle: string },
  SortPriceStyle
>("getAllBookBySortPrice", async (data: SortPriceStyle) => {
  try {
    const response = await GetAllBookBySortPrice(data);
    return {
      payload: response as ApiResponse[],
      sortStyle: data.style !== undefined ? data.style : "",
    } as {
      payload: ApiResponse[];
      sortStyle: string;
    };
  } catch (err: any) {
    throw Error(err.message);
  }
});

export const getAllBookRadioAction = createAsyncThunk<BookRadio[],void>(
  "getAllBookRadioAction", async () => {
  try {
    const response = await GetAudioOfBookWithUser();
    return response?.data as unknown as BookRadio[];
  } catch (err: any) {
    throw Error(err.message);
  }
});


const initialState: InitialStateStyle = {
  Loading: false,
  Error: "",
  Books: [],
  BookDetail: undefined,
  BookCategorys: [],
  IsFilterBookByCategory: 0,
  IsFilterBookByPrice: "",
  BooksCategoryTemp: [],
  BooksPriceTemp: [],
  PageNo: 0,
  bookRadios: []
};

export const BookSlice = createSlice({
  name: "books",
  initialState,
  reducers: {
    UpdatePageNo: (state,action)=>{
      state.PageNo= action.payload;
    },
    UpdateIsFilterBookByCategory: (state) => {
      state.IsFilterBookByCategory = 0;
      state.BooksCategoryTemp = [];
    },
    UpdateIsFilterBookByPrice: (state) => {
      state.IsFilterBookByPrice = "";
      state.BooksPriceTemp = [];
    },
    SortBookByPrice: (state, action) => {
      state.IsFilterBookByPrice = action.payload;
      state.BooksPriceTemp=[...state.Books];
      if (action.payload === "price:asc") {
        state.Books.sort((a, b) => a.price - b.price);
      } else {
        state.Books.sort((a, b) => b.price - a.price);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllBooks.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getBookById.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getBookByPageNo.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllBookCategory.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllBookByBookCategory.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllBookBySortPrice.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllBookRadioAction.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllBooks.fulfilled, (state, action) => {
        state.Loading = false;
        state.Books = action.payload;
      })
      .addCase(getBookById.fulfilled, (state, action) => {
        state.Loading = false;
        state.BookDetail = action.payload;
      })
      .addCase(getBookByPageNo.fulfilled, (state, action) => {
        state.Loading = false;
        state.Books = state.Books.concat(action.payload);
      })
      .addCase(getAllBookCategory.fulfilled, (state, action) => {
        state.Loading = false;
        state.BookCategorys = action.payload;
      })
      .addCase(getAllBookByBookCategory.fulfilled, (state, action) => {
        state.Loading = false;
        if(state.Books.length>0){
          state.BooksCategoryTemp= [...state.Books];
        }
        state.Books = action.payload.payload;
        state.IsFilterBookByCategory = action.payload.bookCategory;
      })
      .addCase(getAllBookBySortPrice.fulfilled, (state, action) => {
        state.Loading = false;
        state.IsFilterBookByPrice = action.payload.sortStyle;
        if (state.Books.length > 0) {
          state.BooksPriceTemp = [...state.Books];
          if(state.PageNo!==0){
            state.Books = state.Books.concat(action.payload.payload);
          }else{
            state.Books = action.payload.payload;
          }
        } else {
          state.Books = action.payload.payload;
        }
      })
      .addCase(getAllBookRadioAction.fulfilled, (state, action) => {
        state.Loading = false;
        state.bookRadios = action.payload.sort((a,b)=>{
          return a.stt-b.stt;
        })
      })
      .addCase(getAllBooks.rejected, (state, action) => {
        state.Loading = false;
        state.Error =
          action.error.message || "error when i call api get book list!";
      })
      .addCase(getBookById.rejected, (state, action) => {
        state.Loading = false;
        state.Error =
          action.error.message || "error when i call api get book by id!";
      })
      .addCase(getBookByPageNo.rejected, (state, action) => {
        state.Loading = false;
        state.Error =
          action.error.message || "error when i call api get book by page no!";
      })
      .addCase(getAllBookCategory.rejected, (state, action) => {
        state.Loading = false;
        state.Error =
          action.error.message || "error when i call api get book category!";
      })
      .addCase(getAllBookByBookCategory.rejected, (state, action) => {
        state.Loading = false;
        state.Error =
          action.error.message ||
          "error when i call api get all book by book category!";
      })
      .addCase(getAllBookBySortPrice.rejected, (state, action) => {
        state.Loading = false;
        state.Error =
          action.error.message ||
          "error when i call api get all book by sort price!";
      })
      .addCase(getAllBookRadioAction.rejected, (state, action) => {
        state.Loading = false;
        state.Error =
          action.error.message ||
          "error when i call api get all book radio";
      });
  },
});

export const {
  UpdateIsFilterBookByCategory,
  UpdateIsFilterBookByPrice,
  SortBookByPrice,
  UpdatePageNo
} = BookSlice.actions;
export default BookSlice.reducer;
