import { createSlice } from "@reduxjs/toolkit";

interface InitialState{
    loading: boolean;
    error: string;
    history: string[];
}
const initialState: InitialState = {
    loading: false,
    error: "",
    history: []
}

const MenuNavigationSlice= createSlice({
    name: "menuNavigation",
    initialState,
    reducers: {
        updateStateMenuNavigation: (state,action)=>{
            state.loading=action.payload;
        },
        updateLocationHistory: (state,action)=>{
            state.history=[...state.history,action.payload];
        }
    }
});

export const {updateStateMenuNavigation,updateLocationHistory}=MenuNavigationSlice.actions;
export default MenuNavigationSlice.reducer;