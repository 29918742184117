import React from "react";
import styles from "./Button.module.scss";
import { MouseEvent } from 'react';

function MyButton({children,onClick,bgColor, width}: {
  children: React.ReactNode,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  bgColor: string;
  width: string;
}): JSX.Element {
  return (
      <button className={styles.button} style={{backgroundColor: bgColor, width: width+'px'}} onClick={onClick}>{children} <span className={styles.dot}></span></button>
  );
}

export default MyButton;
