import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CreateOrderAPI, CreateOrderWithDeliveryService, CreateOrderWithPaypalService, CreateOrderWithVNPayService, UpdateStatusOrderService } from "../../services/PaymentAPI/PaymentAPI";
import {CapturePayment, PaymentData, VNPayResponse} from "./InterfaceResponse";
import { CartResponse } from "../CartSlice/InterfaceResponse";
import { toast } from "react-toastify";

interface InitialStateStyle{
    loading: boolean;
    error: string;
    bookPayment: CartResponse[];
    loadingRedirect: boolean;
}


export const CreateOrderAction= createAsyncThunk<string|undefined,PaymentData>(
    "CreateOrderAction",
    async (data) => {
        try{
            const response = await CreateOrderAPI(data);
            
            return response as unknown as string|undefined;
        }catch(err:any) {
            throw new Error(err.message);
        }
    }
);
export const CreateOrderWithVNPayAction= createAsyncThunk<VNPayResponse|any,PaymentData>(
    "CreateOrderWithVNPayAction",
    async (data) => {
        try{
            const response:VNPayResponse|any = await CreateOrderWithVNPayService(data);
            if(response){
                sessionStorage.setItem("orderId",response.transactionId);
                window.location.href= response.urlPayment;
            }
            return response as unknown as VNPayResponse|any;
        }catch(err:any) {
            throw new Error(err.message);
        }
    }
);
export const CreateOrderWithPaypalAction = createAsyncThunk<string,PaymentData>(
    "CreateOrderWithPaypalAction",
    async (data:PaymentData) => {
        try{
            const res= await CreateOrderWithPaypalService(data);
            return res as string;
        }catch(err:any) {
            throw new Error(err.message);
        }
    }
)

export const CreateOrderWithDeliveryAction = createAsyncThunk<string,PaymentData>(
    "CreateOrderWithDeliveryAction",
    async (data:PaymentData) => {
        try{
            const res= await CreateOrderWithDeliveryService(data);
            if(res){
                sessionStorage.setItem("orderId",res);
            }
            return res as string;
        }catch(err:any) {
            throw new Error(err.message);
        }
    }
)

export const UpdateStatusOrderAction= createAsyncThunk<string|undefined,CapturePayment>(
    "UpdateStatusOrderAction",
    async (data:CapturePayment)=>{
        try{
            const response: string|undefined= await UpdateStatusOrderService(data);
            return response as unknown as string|undefined;
        }catch(err:any) {
            throw new Error(err.message);
        }
    }
)

const initialState : InitialStateStyle = {
    loading: false,
    error: "",
    bookPayment: [],
    loadingRedirect: false
}


const OrderSlice= createSlice({
    name: "order",
    initialState,
    reducers: {
        updateStateLoading: (state)=>{
            state.loading=!state.loading;
        },
        updateBookPayment: (state,action)=>{
            state.bookPayment=action.payload;
        },
        updateStateLoadingRedirect: (state,action)=>{
            state.loadingRedirect=action.payload;
        }
    },
    extraReducers: (builder)=>{
        builder
           .addCase(CreateOrderAction.pending,(state,action)=>{
                state.loading=true;
            })
            .addCase(CreateOrderWithVNPayAction.pending,(state,action)=>{
                state.loading=true;
            })
            .addCase(CreateOrderWithPaypalAction.pending,(state,action)=>{
                state.loading=true;
            })
            .addCase(CreateOrderWithDeliveryAction.pending,(state,action)=>{
                state.loading=true;
            })
            .addCase(UpdateStatusOrderAction.pending,(state,action)=>{
                state.loading=true;
            })
           .addCase(CreateOrderAction.fulfilled,(state,action)=>{
                state.loading=false;
            })
            .addCase(CreateOrderWithVNPayAction.fulfilled,(state,action)=>{
                state.loading=false;
            })
            .addCase(CreateOrderWithPaypalAction.fulfilled,(state,action)=>{
                state.loading=false;
            })
            .addCase(CreateOrderWithDeliveryAction.fulfilled,(state,action)=>{
                state.loading=false;
                state.loadingRedirect=false;
                window.location.href="/payment-success?codeExcep=00";
            })
            .addCase(UpdateStatusOrderAction.fulfilled,(state,action)=>{
                state.loading=false;
            })
           .addCase(CreateOrderAction.rejected,(state,action)=>{
                state.loading=false;
            })
            .addCase(CreateOrderWithVNPayAction.rejected,(state,action)=>{
                state.loading=false;
                state.loadingRedirect=false;
            })
            .addCase(CreateOrderWithPaypalAction.rejected,(state,action)=>{
                state.loading=false;
                state.loadingRedirect=false;
            })
            .addCase(CreateOrderWithDeliveryAction.rejected,(state,action)=>{
                state.loading=false;
                state.loadingRedirect=false;
                toast.error("Have error when your try payment by Delivery method, please try again");
            })
            .addCase(UpdateStatusOrderAction.rejected,(state,action)=>{
                state.loading=false;
            })
    }
});

export const {
    updateStateLoading,
    updateBookPayment,
    updateStateLoadingRedirect
}= OrderSlice.actions;

export default OrderSlice.reducer;