import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import{updateLocationHistory} from "../../redux/MenuNavigationSlice/MenuNavigationSlice";
import { useAppDispatch, useAppSelector } from '../../redux/store';
function useRouter() {
    const navigate= useNavigate();
    const dispatch= useAppDispatch();

    const {history}= useAppSelector(state=>state.menuNavigation);
    const router= useMemo(
        ()=>({
            back: ()=> {
                if(history.length>1){
                    navigate(-1);
                }else{
                    navigate("/");
                }
            },
            forward: ()=> navigate(1),
            reload: ()=> window.location.reload(),
            push: (href:string)=>{
                dispatch(updateLocationHistory(href));
                navigate(href)
            },
            replace: (href: string)=> navigate(href,{replace: true}),
        }),
        [dispatch, history.length, navigate]
    )
  return router;
}

export default useRouter;