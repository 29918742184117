import React from 'react'
import './GlobalStyles.module.scss';
interface GlobalStyle{
    children: React.ReactNode
}
function GlobalStyles({children}: GlobalStyle) {
  return (
    <div>{children}</div>
  )
}

export default GlobalStyles;