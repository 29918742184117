interface VietnameseCourses {
  title: string;
  data: {
    id: number;
    value: {
      name: string;
      color: string;
      label: string;
    }[];
  }[];
}

interface IntroductionContentType {
  contentFor: string;
  content: string[];
  contentEN: string[];
}
interface CoursePath {
  lessonUnit: string;
  lessonName: string;
  description: string;
  activities: string;
}

interface CourseContent {
  courseName: string;
  introductionContent: IntroductionContentType[];
  coursePath: {
    level: string;
    courseContent: CoursePath[];
    courseContentEN: CoursePath[];
  }[];
}

export const VietnameseCourseForAdult = () => {
  const data: VietnameseCourses[] = [
    {
      title: "courses-type-1",
      data: [
        {
          id: 1,
          value: [
            {
              name: "Certificate of Vietnamese language proficiency (A1 to C1)",
              color: "#378dca",
              label: "VIETNAM-ADULT-COURSE-COVLP",
            },
          ],
        },
        {
          id: 2,
          value: [
            {
              name: "Vietnamese language test preparation class (A1 to C1)",
              color: "#65934f",
              label: "VIETNAM-ADULT-COURSE-VLTPC",
            },
          ],
        },
        {
          id: 3,
          value: [
            {
              name: "Practice Vietnamese pronunciation",
              color: "#ffc92c",
              label: "VIETNAM-ADULT-COURSE-PRACTICE-VIETNAMESE-PRONUNCIATION",
            },
          ],
        },
        {
          id: 4,
          value: [
            {
              name: "Vietnamese offline/online course",
              color: "#d06117",
              label: "VIETNAM-ADULT-COURSE-VIETNAMESE-OFFLINE/ONLINE",
            },
          ],
        },
        // {
        //   id: 5,
        //   value: [
        //     {
        //       name: "Pracetice speaking Vietnamese with Vietnamese dialect (the Northem, the Central & the Southem)",
        //       color: "#82807f",
        //     },
        //   ],
        // },
        // {
        //   id: 6,
        //   value: [
        //     {
        //       name: "Vietnamese traditional costumes for rent",
        //       color: "#c1a223",
        //     },
        //   ],
        // },
        {
          id: 7,
          value: [
            {
              name: "Vietnamese for business communication",
              color: "#c1a223",
              label:
                "VIETNAM-ADULT-COURSE-VIETNAMESE-FOR-BUSINESS-COMMUNICATION",
            },
          ],
        },
      ],
    },
  ];
  return data;
};

export const VietnameseCourseData = () => {
  const data: VietnameseCourses[] = [
    {
      title: "courses-type-1",
      data: [
        {
          id: 1,
          value: [
            {
              name: "Learn and play (3~6 y/o)",
              color: "#9358b8",
              label: "VIETNAM-COURSE-LEARN-AND-PLAY",
            },
          ],
        },
        {
          id: 2,
          value: [
            // {
            //   name: "Basic Vietnamese Pre A1",
            //   color: "#4f673b",
            // },
            {
              name: "Basic Vietnamese A1",
              color: "#4f673b",
              label: "VIETNAM-COURSE-BASIC-VIETNAMESE-A1",
            },
            {
              name: "Basic Vietnamese A2",
              color: "#4f673b",
              label: "VIETNAM-COURSE-BASIC-VIETNAMESE-A2",
            },
          ],
        },
        {
          id: 3,
          value: [
            // {
            //   name: "Intermediate Vietnamese Pre B1 ",
            //   color: "#ca9d2a",
            // },
            {
              name: "Intermediate Vietnamese B1",
              color: "#ca9d2a",
              label: "VIETNAM-COURSE-INTERMEDIATE-VIETNAMESE-B1",
            },
            {
              name: "Intermediate Vietnamese B2",
              color: "#ca9d2a",
              label: "VIETNAM-COURSE-INTERMEDIATE-VIETNAMESE-B2",
            },
          ],
        },
        {
          id: 4,
          value: [
            // {
            //   name: "Advanced Vietnamese Pre C1 ",
            //   color: "#295590",
            // },
            {
              name: "Advanced Vietnamese C1",
              color: "#295590",
              label: "VIETNAM-COURSE-ADVANCED-VIETNAMESE-C1",
            },
            {
              name: "Advanced Vietnamese C2",
              color: "#295590",
              label: "VIETNAM-COURSE-ADVANCED-VIETNAMESE-C2",
            },
          ],
        },
      ],
    },
    {
      title: "course-type-2",
      data: [
        {
          id: 1,
          value: [
            {
              name: "Practice Vietnamese pronunciation",
              color: "#944d18",
              label: "VIETNAM-COURSE-PRACTICE-VIETNAMESE-PRONUNCIATION",
            },
            {
              name: "Practice Vietnamese songs",
              color: "#363f4a",
              label: "VIETNAM-COURSE-PRACTICE-VIETNAMESE-SONGS",
            },
          ],
        },
      ],
    },
  ];
  return data;
};

export const VietnameseCourseContentForChildren = () => {
  const data: CourseContent[] = [
    {
      courseName: "Learn and play (3~6 y/o)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 3 – 6, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Việt đầy thú vị.",
          ],
          contentEN: [
            "This specialized course is dedicated for children aged 3-6, with rich and varied content, offering a unique and engaging experience.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "Teachers of ABBA are experienced professionals from prestigious universities like: Hanoi University of Education, University of Social Sciences and Humanities.",
            "The design of the course is tailored to each group of students according to their specific needs, aspirations.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Students will receive a comprehensive vocabulary and basic grammar to facilitate communication, serve as a foundation for higher education levels.",
            "The emphasis is on practicing standard pronunciation like native speakers from the beginning. After learning the alphabet chart and vowel sounds, students will be able to read and understand any language.",
          ],
        },
      ],
      coursePath: [],
    },
    {
      courseName: "Basic Vietnamese A1",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp Pre A1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 7 - 9, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Việt đầy thú vị.",
          ],
          contentEN: [
            "Students who have completed Pre A1",
            "This specialized course is dedicated for children aged 7-9, with rich and varied content, offering a unique and engaging experience.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Những chủ đề này cung cấp nền tảng vững chắc cho trẻ ở trình độ A1 bắt đầu học tiếng Việt một cách vui vẻ và tương tác, tạo nền tảng cho việc tiếp thu ngôn ngữ sâu hơn. Việc kết hợp các trò chơi, bài hát và hoạt động thực hành có thể làm cho quá trình học tập trở nên thú vị và hiệu quả đối với trẻ nhỏ.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "These topics provide a solid foundation for children at level A1 to start learning Vietnamese in a fun and interactive way, creating a foundation for deeper language acquisition. The combination of games and lessons Singing and hands-on activities can make the learning process fun and effective for young children.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Students will receive a comprehensive vocabulary and basic grammar to facilitate communication, serve as a foundation for higher education levels.",
            "The emphasis is on practicing standard pronunciation like native speakers from the beginning. After learning the alphabet chart and vowel sounds, students will be able to read and understand any language.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonUnit: "Bài 1",
              lessonName: "Lời chào và Giới thiệu",
              description: "Dạy những lời chào và cách giới thiệu cơ bản",
              activities: "Thực hành trao đổi làm quen",
            },
            {
              lessonUnit: "Bài 2",
              lessonName: "Số và Đếm",
              description:
                "Làm quen với các con số ( 1 – 20 ) cùng các phép toán cơ bản (cộng, trừ )",
              activities: "Thực hành củng cố bằng bài hát, trò chơi",
            },
            {
              lessonUnit: "Bài 3",
              lessonName: "Màu sắc và Hình dạng",
              description:
                "Tiếp xúc với các màu sắc ( đỏ, vàng,… ) và nhận diện chúng",
              activities: "Tham gia trò chơi, bảng tô màu, và dự án thủ công",
            },
            {
              lessonUnit: "Bài 4",
              lessonName: "Các thành viên trong gia đình",
              description:
                "Giới thiệu các từ vựng và cụm từ chỉ các thành viên trong gia đình",
              activities: "Đóng giả thành các nhân vật trong nhà để thực hành",
            },
            {
              lessonUnit: "Bài 5",
              lessonName: "Động vật",
              description:
                "Tiếp xúc với tên các loài động vật thông thường và tiếng kêu của chúng",
              activities:
                "Sử dụng thẻ ghi chú động vật,  tranh sách, bài hát để mô tả con vật",
            },
            {
              lessonUnit: "Bài 6",
              lessonName: "Thức ăn và đồ uống",
              description: "Tiếp xúc với các từ ngữ lên quan đến ăn - uống",
              activities: "Kết hợp các trò chơi nhập vai và  nấu ăn giả vờ",
            },
            {
              lessonUnit: "Bài 7",
              lessonName: "Quần áo",
              description: "Tiếp xúc tên các loại quần – áo, giày – dép…",
              activities:
                "Tham gia các trò chơi quần áo và trình diễn thời trang",
            },
            {
              lessonUnit: "Bài 8",
              lessonName: "Hoạt động hàng ngày",
              description:
                "Giới thiệu và dạy các hoạt động hàng ngày như: ăn, ngủ, chơi…",
              activities: "Tạo biểu đồ để trẻ hiểu những trình tự hàng ngày",
            },
            {
              lessonUnit: "Bài 9",
              lessonName: "Đồ chơi và trò chơi",
              description: "Giới thiệu và dạy tên các đồ chơi và trò chơi",
              activities:
                "Tham gia các hoạt động trò chơi để trẻ tiếp thu tốt hơn",
            },
            {
              lessonUnit: "Bài 10",
              lessonName: "Thời tiết",
              description: "Giới thiệu và dạy các từ vựng cơ bản về thời tiết",
              activities:
                "Sử dụng các bài hát, công cụ, hình vẻ để củng cố bài học",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "Lesson 1",
              lessonName: "Greetings and Introductions",
              description: "Teach basic greetings and introductions",
              activities: "Practice getting to know each other",
            },
            {
              lessonUnit: "Lesson 2",
              lessonName: "Numbers and Counting",
              description:
                "Getting familiar with numbers (1 – 20) and basic math operations (addition, subtraction)",
              activities: "Practice reinforcement with songs and games",
            },
            {
              lessonUnit: "Lesson 3",
              lessonName: "Colors and Shapes",
              description:
                "Exposure to colors (red, yellow, ...) and identifying them",
              activities: "Join games, coloring sheets, and craft projects",
            },
            {
              lessonUnit: "Lesson 4",
              lessonName: "Family members",
              description:
                "Introduction vocabulary and phrases for family members",
              activities: "Pretend to be characters in the house to practice",
            },
            {
              lessonUnit: "Lesson 5",
              lessonName: "Animals",
              description:
                "Exposure to the names of common animals and their sounds",
              activities:
                "Use animal flashcards, picture books, songs to describe animals",
            },
            {
              lessonUnit: "Lesson 6",
              lessonName: "Food and Drink",
              description: "Exposure to words related to eating and drinking",
              activities: "Combine role-playing games and pretend cooking",
            },
            {
              lessonUnit: "Lesson 7",
              lessonName: "Clothes",
              description:
                "Exposure to the names of different types of clothes, shoes, etc.",
              activities: "Participate in clothing games and fashion shows",
            },
            {
              lessonUnit: "Lesson 8",

              lessonName: "Daily activities",
              description:
                "Introduce and teach daily activities such as: eating, sleeping, playing...",
              activities:
                "Create charts for children to understand daily sequences",
            },
            {
              lessonUnit: "Lesson 9",
              lessonName: "Toys and games",
              description: "Introduce and teach the names of toys and games",
              activities:
                "Participate in game activities for children to absorb better",
            },
            {
              lessonUnit: "Lesson 10",
              lessonName: "Weather",
              description: "Introduce and teach basic vocabulary about weather",
              activities: "Use songs, tools, pictures to reinforce the lesson",
            },
          ],
        },
      ],
    },
    {
      courseName: "Basic Vietnamese A2",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp A1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 7 - 9, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Việt đầy thú vị.",
          ],
          contentEN: [
            "Students who have completed level A1",
            "This specialized program is specifically designed for children aged 7 - 9, offering a rich and diverse curriculum, with a promise to open up an exciting journey in Vietnamese.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Các chủ đề này cung cấp nền tảng để trẻ trình độ A2 phát triển hơn nữa kỹ năng tiếng Việt đồng thời khám phá nội dung mới, hấp dẫn, phù hợp với lứa tuổi và sở thích của các em. Việc kết hợp các hoạt động tương tác, trò chơi và kể chuyện có thể nâng cao trải nghiệm học tập của các em.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "These topics provide a foundation for children at level A2 to further develop their Vietnamese language skills while discovering new, attractive content suitable for their age and interests. The combination of activities Interactive activities, games and storytelling can enhance children's learning experience.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Children will receive a rich vocabulary and basic grammar skills to facilitate communication and serve as a foundation for higher education.",
            "Focus on developing standard pronunciation as a native speaker from the beginning. After learning the alphabet chart and Vietnamese pronunciation, children will be able to read and understand any vocabulary easily.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonUnit: "Bài 1",
              lessonName: "Thói quen hàng ngày",
              description:
                "Mở rộng thêm các từ và cụm từ liên quan đến thói quen hàng ngày",
              activities: "Tạo biểu đồ để trẻ hiểu những trình tự hàng ngày",
            },
            {
              lessonUnit: "Bài 2",
              lessonName: "Địa điểm vùng lân cận",
              description: "Giới thiệu về các địa điểm xung quanh",
              activities: "Tập hỏi và chỉ đường đến những nơi đó",
            },
            {
              lessonUnit: "Bài 3",
              lessonName: "Giao thông vận tải",
              description:
                "Xây dựng vốn từ vựng về giao thông, các phương tiện…",
              activities:
                "Tham gia trò chơi nhập vai về các phương tiện giao thông",
            },
            {
              lessonUnit: "Bài 4",
              lessonName: "Sở thích",
              description:
                "Khám phá các từ mới về sở thích và hoạt động yêu thích",
              activities:
                "Thảo luận sở thích và hoạt động yêu thích với bạn bè",
            },
            {
              lessonUnit: "Bài 5",
              lessonName: "Mua sắm và tiền bạc",
              description:
                "Giới thiệu các từ vựng liên quan mua sắm, tiền bạc và các cửa hàng",
              activities: "Trò chơi đóng vai mua bán , trao đổi đơn giản",
            },
            {
              lessonUnit: "Bài 6",
              lessonName: "Sức khỏe và hạnh phúc",
              description:
                "Giới thiệu các từ vựng liên quan đến sức khỏe bao gồm các bộ phận trên cơ thể",
              activities: "Thảo luận cách giữ gìn sức khỏe, vận động…",
            },
            {
              lessonUnit: "Bài 7",
              lessonName: "Mùa và thời tiết",
              description: "Khám phá các từ vựng liên quan đến mùa, thời tiết…",
              activities: "Vẽ tranh hoặc tạo ra các sản phẩm thủ công về mùa",
            },
            {
              lessonUnit: "Bài 8",
              lessonName: "Lễ kỷ niệm và Lễ hội",
              description: "Khám phá các ngày lễ, ngày kỷ niệm ở việt nam",
              activities: "Thảo luận về các ngày lễ, các phong tục diễn ra",
            },
            {
              lessonUnit: "Bài 9",
              lessonName: "Động vật và thiên nhiên",
              description:
                "Mở rộng vốn từ vựng, kể them tên và môi trường sống",
              activities: "Tham khảo và tìm hiểu qua video và thảo luận",
            },
            {
              lessonUnit: "Bài 10",
              lessonName: "Gia đình và các mối quan hệ",
              description:
                "Giới thiệu gia đình và các mối quan hệ trong gia đình",
              activities: "Nói về truyền thống gia đình và các dịp đặc biệt",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "Lesson 1",
              lessonName: "Daily routines",
              description:
                "Expand more words and phrases related to daily routines",
              activities:
                "Create charts to help children understand daily routines",
            },
            {
              lessonUnit: "Lesson 2",
              lessonName: "Nearby places",
              description: "Introduce surrounding places",
              activities:
                "Practice asking and giving directions to those places",
            },
            {
              lessonUnit: "Lesson 3",
              lessonName: "Transportation",
              description:
                "Build vocabulary about transportation, means of transport...",
              activities: "Join a role-playing game about means of transport",
            },
            {
              lessonUnit: "Lesson 4",
              lessonName: "Hobbies",
              description:
                "Discover new words about hobbies and favorite activities",
              activities:
                "Discuss hobbies and favorite activities with friends",
            },
            {
              lessonUnit: "Lesson 5",
              lessonName: "Shopping and money",
              description:
                "Introduce vocabulary related to shopping, money and shops",
              activities:
                "Simple role-playing games about buying and selling and exchanging",
            },
            {
              lessonUnit: "Lesson 6",
              lessonName: "Health and happiness",
              description:
                "Introduce vocabulary related to health including body parts",
              activities: "Discuss ways to stay healthy, exercise...",
            },
            {
              lessonUnit: "Lesson 7",
              lessonName: "Seasons and weather",
              description: "Explore vocabulary related to seasons, weather...",
              activities: "Draw pictures or create seasonal crafts",
            },
            {
              lessonUnit: "Lesson 8",
              lessonName: "Celebrations and Festivals",
              description: "Explore holidays and anniversaries in Vietnam",
              activities: "Discuss holidays and customs",
            },
            {
              lessonUnit: "Lesson 9",
              lessonName: "Animals and nature",
              description: "Expand vocabulary, name more and habitat",
              activities: "Refer and learn through videos and discuss",
            },
            {
              lessonUnit: "Lesson 10",
              lessonName: "Family and relationships",
              description: "Introduce family and family relationships",
              activities: "Talk about family traditions and special occasions",
            },
          ],
        },
      ],
    },
    {
      courseName: "Intermediate Vietnamese B1",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp Pre B1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 10 - 12, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Việt đầy thú vị.",
          ],
          contentEN: [
            "Participants have completed Pre B1",
            "This special program is designed for children aged 10 - 12 with a rich and diverse curriculum, offering an immersive experience in Vietnamese.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Các chủ đề này cung cấp nền tảng để trẻ trình độ B1 phát triển hơn nữa kỹ năng tiếng Việt đồng thời khám phá nội dung mới, hấp dẫn, phù hợp với lứa tuổi và sở thích của các em. Việc kết hợp các hoạt động, trò chơi và dự án tương tác có thể nâng cao trải nghiệm học tập và thúc đẩy khả năng sáng tạo của các em.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "These topics provide a foundation for children at level B1 to further develop their Vietnamese language skills while discovering new, attractive content suitable for their age and interests. The combination of activities Interactive activities, games and projects can enhance children's learning experiences and boost their creativity.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Children will receive a rich vocabulary and basic grammar knowledge to facilitate communication, serve as a foundation for higher education levels.",
            "Focus on developing standard pronunciation like native speakers from the beginning. After familiarizing themselves with the alphabetic chart and Vietnamese pronunciation, children will be able to read and understand any vocabulary.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonUnit: "Bài 1",
              lessonName: "Thói quen hàng ngày nâng cao",
              description:
                "Giới thiệu các hoạt động và diễn đạt thời gian phức tạp hơn",
              activities: "Mô tả lịch trình và thói quen 1 cách chi tiết hơn",
            },
            {
              lessonUnit: "Bài 2",
              lessonName: "Cộng đồng và vùng lân cận",
              description:
                "Khám phá các từ liên quan đến cộng đồng, khu phố,…. Địa điểm lân cận",
              activities: "Thảo luận vai trò người giúp đỡ cộng đồng",
            },
            {
              lessonUnit: "Bài 3",
              lessonName: "Truyền thống văn hóa và phong tục",
              description:
                "Giới thiệu các từ vựng về truyền thống văn hóa, phong tục…",
              activities: "Thảo luận về lễ hội, phong tục người việt nam",
            },
            {
              lessonUnit: "Bài 4",
              lessonName: "Thiên nhiên và môi trường",
              description:
                "Mở rộng từ vựng liên quan đến thiên nhiên, môi trường…",
              activities: "Thảo luận về các bảo vệ môi trường và tính bền vững",
            },
            {
              lessonUnit: "Bài 5",
              lessonName: "Công nghệ và truyền thông",
              description:
                "Giới thiệu các từ vựng liên quan đến công nghệ, truyền thông",
              activities: "Thảo luận an toàn internet và sử dụng công nghệ",
            },
            {
              lessonUnit: "Bài 6",
              lessonName: "Ẩm thực và nấu ăn",
              description:
                "Khám phá từ vựng liên quan ẩm thực và nấu ăn ở việt nam",
              activities: "Cùng nhau nấu những món ăn đơn giản",
            },
            {
              lessonUnit: "Bài 7",
              lessonName: "Thể thao và hoạt động",
              description:
                "Giới thiệu các từ vựng thể thao và hoạt động giải trí",
              activities:
                "Thảo luận lợi ích của việc tham gia thể thao và giải trí",
            },
            {
              lessonUnit: "Bài 8",
              lessonName: "Cuộc sống và các mối quan hệ gia đình",
              description:
                "Hiểu sâu hơn về cuộc sống gia đình và các mối quan hệ",
              activities:
                "Thảo luận và nhập vai vào các tình huống trong gia đình",
            },
            {
              lessonUnit: "Bài 9",
              lessonName: "Du lịch và địa lý",
              description:
                "Mở rộng từ vựng liên quan đến du lịch và vị trí địa lý",
              activities: "Thảo luận các vùng đất ở việt nam  và điểm hấp dẫn",
            },
            {
              lessonUnit: "Bài 10",
              lessonName: "Biểu hiện sáng tạo",
              description:
                "Khuyến khích thể hiện sáng tạo thông qua dự án, biểu diễn…",
              activities: "Vẽ tranh, biểu diễn tiểu phẩm…",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "Lesson 1",
              lessonName: "Advanced daily routines",
              description:
                "Introduce more complex activities and time expressions",
              activities: "Describe schedules and routines in more detail",
            },
            {
              lessonUnit: "Lesson 2",
              lessonName: "Community and neighbourhood",
              description:
                "Explore words related to community, neighbourhood,…. Neighbourhood",
              activities: "Discuss the role of community helpers",
            },
            {
              lessonUnit: "Lesson 3",
              lessonName: "Cultural traditions and customs",
              description:
                "Introduce vocabulary about cultural traditions, customs…",
              activities: "Discuss Vietnamese festivals and customs",
            },
            {
              lessonUnit: "Lesson 4",
              lessonName: "Nature and environment",
              description:
                "Expand vocabulary related to nature, environment...",
              activities: "Discuss environmental protection and sustainability",
            },
            {
              lessonUnit: "Lesson 5",
              lessonName: "Technology and communication",
              description:
                "Introduce vocabulary related to technology, communication",
              activities: "Discuss internet safety and using technology",
            },
            {
              lessonUnit: "Lesson 6",
              lessonName: "Cuisine and cooking",
              description:
                "Explore vocabulary related to cuisine and cooking in Vietnam",
              activities: "Cook simple dishes together",
            },
            {
              lessonUnit: "Lesson 7",
              lessonName: "Sports and activities",
              description:
                "Introduce vocabulary related to sports and recreational activities",
              activities:
                "Discuss the benefits of participating in sports and recreational activities",
            },
            {
              lessonUnit: "Lesson 8",
              lessonName: "Family life and relationships",
              description: "Understanding family life and relationships",
              activities: "Discussing and role-playing family situations",
            },
            {
              lessonUnit: "Lesson 9",
              lessonName: "Travel and geography",
              description:
                "Expanding vocabulary related to travel and geography",
              activities: "Discussing regions in Vietnam and attractions",
            },
            {
              lessonUnit: "Lesson 10",
              lessonName: "Creative expression",
              description:
                "Encouraging creative expression through projects, performances...",
              activities: "Drawing pictures, performing skits...",
            },
          ],
        },
      ],
    },
    {
      courseName: "Intermediate Vietnamese B2",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp B1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 10 - 12, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Việt đầy thú vị.",
          ],
          contentEN: [
            "Students who have completed level B1",
            "This specialized course is specifically designed for children aged 10 - 12, offering a rich and diverse curriculum, with a promise of opening up a captivating learning experience in Vietnamese.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Các chủ đề này cung cấp nền tảng để trẻ trình độ B2 phát triển hơn nữa kỹ năng tiếng Việt đồng thời khám phá nội dung đa dạng và kích thích tư duy. Việc kết hợp các cuộc thảo luận, tranh luận và các dự án sáng tạo có thể nâng cao trải nghiệm học tập của học sinh và khuyến khích tư duy phê phán.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "These topics provide a foundation for B2 level children to further develop their Vietnamese language skills while exploring diverse and thought-provoking content. The combination of discussions, debates and projects Creativity can enhance students' learning experiences and encourage critical thinking.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Students will be provided with a rich vocabulary and basic grammar knowledge to facilitate communication and serve as a foundation for higher education.",
            "Emphasize the importance of standard pronunciation, such as native language learners, as the foundation for learning and understanding vocabulary. After mastering the alphabet chart and Vietnamese pronunciation, students will be able to read and understand any vocabulary.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonUnit: "Bài 1",
              lessonName: "Vấn đề toàn cầu",
              description: "Thảo luận các vấn đề toàn cầu",
              activities:
                "Khuyến khích trẻ bày tỏ quan điển và động não tìm giải pháp bằng tiếng việt",
            },
            {
              lessonUnit: "Bài 2",
              lessonName: "Sự kiện lịch sử",
              description:
                "Khám phá các sự kiện và nhân vật lịch sử ở việt nam",
              activities:
                "Thảo luận về sự quan trọng và tác động của chúng với xã hội",
            },
            {
              lessonUnit: "Bài 3",
              lessonName: "Văn học và thơ",
              description: "Giới thiệu văn học và thơ việt nam cho trẻ em",
              activities: "Khuyến khích trẻ viết thơ và truyện của riêng mình",
            },
            {
              lessonUnit: "Bài 4",
              lessonName: "Truyền thông xã hội và công nghệ",
              description:
                "Thảo luận về ảnh hưởng của truyền thông và công nghệ đến cuộc sống của trẻ",
              activities:
                "khám phá các ưu và nhược điểm của công nghệ bằng tiếng việt",
            },
            {
              lessonUnit: "Bài 5",
              lessonName: "Đa dạng văn hóa",
              description:
                "Khám phá sự đa dạng văn hóa của Việt nam và thế giới",
              activities: "Thảo luận về truyền thống văn hóa, phong tục…",
            },
            {
              lessonUnit: "Bài 6",
              lessonName: "Doanh nhân và đổi mới",
              description: "Giới thiệu các khái niệm về khởi nghiệp và đổi mới",
              activities:
                "Khuyến khích trẻ nêu ý tưởng khởi nghiệp bằng tiếng việt",
            },
            {
              lessonUnit: "Bài 7",
              lessonName: "Sự kiện và tin tức thời sự",
              description:
                "Thảo luận về các sự khiện hiện tại và tin tức ở việt nam",
              activities:
                "Xem clip thời sự và thảo luận ý nghĩa bằng tiếng  việt",
            },
            {
              lessonUnit: "Bài 8",
              lessonName: "Bảo tồn môi trường",
              description: "Hiểu sâu hơn về bảo tồn môi trường",
              activities: "Thảo luận nổ lực bảo tồn động vật ở việt nam",
            },
            {
              lessonUnit: "Bài 9",
              lessonName: "Phục vụ cộng đồng và tình nguyện",
              description:
                "Khuyến khích trẻ tham gia hoạt động cộng đồng và tình nguyện",
              activities:
                "Bàn về tầm quan trọng của việc cống hiến cho cộng đồng",
            },
            {
              lessonUnit: "Bài 10",
              lessonName: "Kiến thức truyền thông",
              description: "Tìm hiểu về những kiến thức truyền thông",
              activities: "Thảo luận vai trò truyền thông ở việt nam",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "Lesson 1",
              lessonName: "Global Issues",
              description: "Discussing Global Issues",
              activities:
                "Encouraging Children to Express Their Opinions and Brainstorm Solutions in Vietnamese",
            },
            {
              lessonUnit: "Lesson 2",
              lessonName: "Historical Events",
              description:
                "Discovering Historical Events and Figures in Vietnam",
              activities: "Discussing Their Importance and Impact on Society",
            },
            {
              lessonUnit: "Lesson 3",
              lessonName: "Literature and Poetry",
              description:
                "Introducing Vietnamese Literature and Poetry to Children",
              activities:
                "Encouraging Children to Write Their Own Poems and Stories",
            },
            {
              lessonUnit: "Lesson 4",
              lessonName: "Social Media and Technology",
              description:
                "Discussing the Impact of Media and Technology on Children's Lives",
              activities:
                "Explore the advantages and disadvantages of technology in Vietnamese",
            },
            {
              lessonUnit: "Lesson 5",
              lessonName: "Cultural diversity",
              description:
                "Explore the cultural diversity of Vietnam and the world",
              activities: "Discuss cultural traditions, customs...",
            },
            {
              lessonUnit: "Lesson 6",
              lessonName: "Entrepreneurship and innovation",
              description:
                "Introduce the concepts of entrepreneurship and innovation",
              activities:
                "Encourage children to present startup ideas in Vietnamese",
            },
            {
              lessonUnit: "Lesson 7",
              lessonName: "Current events and news",
              description: "Discuss current events and news in Vietnam",
              activities:
                "Watch news clips and discuss the meaning in Vietnamese",
            },
            {
              lessonUnit: "Lesson 8",
              lessonName: "Environmental conservation",
              description:
                "Understanding more about environmental conservation",
              activities: "Discussing efforts to conserve animals in Vietnam",
            },
            {
              lessonUnit: "Lesson 9",
              lessonName: "Community service and volunteering",
              description:
                "Encouraging children to participate in community activities and volunteering",
              activities:
                "Discussing the importance of contributing to the community",
            },
            {
              lessonUnit: "Lesson 10",
              lessonName: "Media knowledge",
              description: "Learning about media knowledge",
              activities: "Discussing the role of media in Vietnam",
            },
          ],
        },
      ],
    },
    {
      courseName: "Advanced Vietnamese C1",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp Pre C1",
            "Chương trình chuyên biệt dành cho trẻ em trong độ tuổi 13 - 15, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Việt đầy thú vị.",
          ],
          contentEN: [
            "Students have completed Pre C1 class",
            "Specialized program for children aged 13 - 15, with rich, diverse and practical content, promising to open an exciting journey to discover Vietnamese.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của RYL là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Các chủ đề này cung cấp nền tảng để trẻ trình độ C1 phát triển hơn nữa kỹ năng tiếng Việt đồng thời tham gia vào các nội dung phức tạp và kích thích trí tuệ. Việc kết hợp các cuộc thảo luận, dự án nghiên cứu và ứng dụng trong thế giới thực có thể nâng cao trải nghiệm học tập của họ và chuẩn bị cho họ theo đuổi học tập và nghề nghiệp trong tương lai.",
          ],
          contentEN: [
            "RYL's teaching staff are experts from prestigious universities such as: Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "These topics provide a foundation for C1 level children to further develop their Vietnamese language skills while engaging in complex and intellectually stimulating content. The combination of discussions and research projects and real-world applications that can enhance their learning experience and prepare them for future academic and career pursuits.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Students will be provided with a rich vocabulary and basic grammar skills to facilitate communication, serve as a foundation for higher education levels.",
            "Focus on developing standard pronunciation like native speaker as early as possible. After mastering the alphabet and phonetic symbols in Vietnamese, students will be able to read and understand any vocabulary.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonUnit: "Bài 1",
              lessonName: "Triết học và đạo đức",
              description:
                "Giới thiệu khái niệm triết học và tình huống đạo đức",
              activities:
                "Suy ngẫm về đạo đức, tính công bằng và ý nghĩa cuộc sống việt nam",
            },
            {
              lessonUnit: "Bài 2",
              lessonName: "Nghiên cứu văn học nâng cao",
              description: "Đi sâu hơn vào văn học việt nam",
              activities:
                "Phân tích chủ đề văn học , sự phát triển tính cách trong tiếng việt",
            },
            {
              lessonUnit: "Bài 3",
              lessonName: "Công dân toàn cầu",
              description: "Nuôi dưỡng ý thức công dân toàn cầu",
              activities:
                "Khuyến khích trẻ suy nghỉ chính chắn về vai trò của mình",
            },
            {
              lessonUnit: "Bài 4",
              lessonName: "Tư duy phê phán và giải quyết vấn đề",
              description:
                "Tham gia các hoạt động thúc đẩy tư duy phê phán và giải quyết vấn đề",
              activities:
                "Khuyến khích trẻ nói lên suy nghỉ và quan điểm của mình",
            },
            {
              lessonUnit: "Bài 5",
              lessonName: "Phân tích truyền thông và tuyên truyền",
              description:
                "Dạy trẻ phân tích phê bình các thông điệp truyền thông, xác định kỹ thuật tuyên truyền",
              activities: "Thảo luận tác động truyền thông đến xã hội việt nam",
            },
            {
              lessonUnit: "Bài 6",
              lessonName: "Hoạt động vì môi trường",
              description:
                "Truyền cảm hứng cho trẻ hành động về vấn đề môi trường",
              activities: "Khuyến khích tham gia chiến dịch môi trường",
            },
            {
              lessonUnit: "Bài 7",
              lessonName: "Nhân quyền và công bằng xã hội",
              description:
                "Thảo luận các vấn đề cơ bản về quyền con người, công bằng xã hội",
              activities:
                "Khuyến khích trẻ em ủng hộ sự thay đổi của xã hội bằng tiếng việt",
            },
            {
              lessonUnit: "Bài 8",
              lessonName: "Kiến thức kĩ thuật số và an toàn trực tuyến",
              description:
                "Dạy trẻ kiến thức kỹ thuật số và an toàn trực tuyến",
              activities:
                "Thảo luận về tầm quan trọng của quyền công dân ở việt nam",
            },
            {
              lessonUnit: "Bài 9",
              lessonName: "Lãnh đạo và trao quyền",
              description:
                "Trao quyền cho trẻ em trở thành lãnh đạo và phát triển kỷ năng trở thành lãnh đạo",
              activities:
                "Thảo luận phẩm chất con người và tầm quan trọng của việc lãnh đạo",
            },
            {
              lessonUnit: "Bài 10",
              lessonName: "Nghề nghiệp và khát vọng tương lai",
              description:
                "Khuyến khích trẻ khám phá sở thích, tài năng và nguyện vọng nghề nghiệp",
              activities:
                "Cung cấp nguồn lực và hướng dẫn trẻ theo đuổi ước mơ và nghề nghiệp",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "Lesson 1",
              lessonName: "Philosophy and Ethics",
              description:
                "Introducing the concept of philosophy and ethical situations",
              activities:
                "Reflecting on ethics, fairness and the meaning of Vietnamese life",
            },
            {
              lessonUnit: "Lesson 2",
              lessonName: "Advanced literary studies",
              description: "Going deeper into Vietnamese literature",
              activities:
                "Analyzing literary themes, character development in Vietnamese",
            },
            {
              lessonUnit: "Lesson 3",
              lessonName: "Global Citizens",
              description: "Nurturing global citizenship awareness",
              activities:
                "Encouraging children to think critically about their roles",
            },
            {
              lessonUnit: "Lesson 4",
              lessonName: "Critical thinking and problem solving",
              description:
                "Participate in activities that promote critical thinking and problem solving topic",
              activities:
                "Encourage children to express their thoughts and opinions",
            },
            {
              lessonUnit: "Lesson 5",
              lessonName: "Analyze media and propaganda",
              description:
                "Teach children to critically analyze media messages, identify propaganda techniques",
              activities: "Discuss the impact of media on Vietnamese society",
            },
            {
              lessonUnit: "Lesson 6",
              lessonName: "Activities for the environment",
              description:
                "Inspire children to take action on environmental issues",
              activities: "Encourage participation in environmental campaigns",
            },
            {
              lessonUnit: "Lesson 7",
              lessonName: "Human rights and social justice",
              description:
                "Discuss basic issues of human rights and social justice",
              activities:
                "Encourage children to support social change in Vietnamese",
            },
            {
              lessonUnit: "Lesson 8",
              lessonName: "Digital literacy and online safety",
              description:
                "Teaching children digital literacy and online safety",
              activities: "Discussing the importance of citizenship in Vietnam",
            },
            {
              lessonUnit: "Lesson 9",
              lessonName: "Leadership and empowerment",
              description:
                "Empowering children to become leaders and developing leadership skills",
              activities:
                "Discussing human qualities and the importance of leadership",
            },
            {
              lessonUnit: "Lesson 10",
              lessonName: "Careers and future aspirations",
              description:
                "Encouraging children to explore their interests, talents and career aspirations",
              activities:
                "Providing resources and guidance for children to pursue their dreams and careers",
            },
          ],
        },
      ],
    },
    {
      courseName: "Advanced Vietnamese C2",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên đã hoàn thành lớp C1",
            "chương trình chuyên biệt dành cho trẻ em trong độ tuổi 13 - 15, với những nội dung phong phú, đa dạng và thiết thực, hứa hẹn mở ra một hành trình khám phá tiếng Việt đầy thú vị.",
          ],
          contentEN: [
            "Students who have completed C1 course",
            "This specialized course is designed for children aged 13-15, offering a rich and varied curriculum, with a strong expectation to open up a captivating exploration of Vietnamese.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Các chủ đề này cung cấp nền tảng để trẻ trình độ C2 phát triển hơn nữa kỹ năng tiếng Việt đồng thời tham gia vào các nội dung nâng cao và kích thích trí tuệ. Việc kết hợp các cuộc thảo luận, dự án nghiên cứu và ứng dụng trong thế giới thực có thể nâng cao trải nghiệm học tập của họ và chuẩn bị cho họ theo đuổi học tập và nghề nghiệp trong tương lai ở mức cao nhất.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "These topics provide a foundation for C2 level children to further develop their Vietnamese language skills while engaging in advanced and intellectually stimulating content. The combination of discussions and research projects and real-world applications that can enhance their learning experience and prepare them to pursue future education and careers at the highest level.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Các em sẽ được cung cấp một lượng từ vựng phong phú và những kiến thức ngữ pháp cơ bản để giao tiếp, làm nền tảng cho những bậc học cao hơn.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Students will receive a rich vocabulary and basic grammar knowledge to facilitate communication, serve as a foundation for higher education levels.",
            "Focus on developing accurate pronunciation as a native speaker from the beginning. After learning the alphabet and phonetic chart, students will be able to read any language fluently.",
          ],
        },
      ],
      coursePath: [
        {
          level: "",
          courseContent: [
            {
              lessonUnit: "Bài 1",
              lessonName: "Phân tích văn học nâng cao",
              description: "Đi sâu phân tích văn học việt nam",
              activities:
                "Khuyến khích trẻ phân tích và diễn giải văn bản bằng tiếng việt",
            },
            {
              lessonUnit: "Bài 2",
              lessonName: "Bản sắc và di sản văn hóa",
              description: "Khám phá các chủ đề liên quan đến bản sắc văn hóa",
              activities: "Bàn về ý nghĩa của di sản văn hóa ở việt nam",
            },
            {
              lessonUnit: "Bài 3",
              lessonName: "Các vấn đề toàn cầu và vận động chính sách",
              description: "Tham gia thảo luận về các vấn đề toàn cầu cấp bách",
              activities:
                "Khuyến khích ủng hộ những thay đổi tích cực của xã hội",
            },
            {
              lessonUnit: "Bài 4",
              lessonName:
                "Kiến thức về truyền thông và phân tích truyền thông quan trọng",
              description:
                "Tìm hiểu sâu hơn kỹ năng hiểu biết về truyền thông nâng cao",
              activities:
                "Thảo luận vai trò truyền thông trong việc thúc đẩy sự phát triển xã hội việt nam",
            },
            {
              lessonUnit: "Bài 5",
              lessonName: "Lãnh đạo có đạo đức và trách nhiệm xã hội",
              description:
                "Khám phá các chủ đề liên quan đến lãnh đạo có đạo đức và trách nhiệm",
              activities:
                "Khuyến khích trẻ áp dụng các nguyên tắc đạo đức vào cuộc sống",
            },
            {
              lessonUnit: "Bài 6",
              lessonName: "Đổi mới và khởi nghiệp",
              description:
                "Truyền cảm hứng cho trẻ trở thành nhà tư tưởng và doanh nhân",
              activities:
                "Khuyến khích phát triển giải pháp sáng tạo cho vấn đề thế giới và theo đuổi dự án kinh doanh",
            },
            {
              lessonUnit: "Bài 7",
              lessonName: "Bảo tồn và bền vững môi trường",
              description: "Giúp trẻ hiểu sâu hơn về tính bền vững và bảo tồn",
              activities: "Khuyến khích trẻ hành động bảo vệ môi trường",
            },
            {
              lessonUnit: "Bài 8",
              lessonName: "Chủ nghĩa nhân đạo và công dân toàn cầu",
              description:
                "Tham gia thảo luận về chủ nghĩa nhân đạo và công dân toàn cầu",
              activities:
                "Khuyến khích trẻ trở thành công dân nhân ái và ủng hộ nhân quyền ở việt nam",
            },
            {
              lessonUnit: "Bài 9",
              lessonName: "Xu hướng công nghệ và đổi mới kỹ thuật số",
              description:
                "Khám phá nâng cao chủ đề đổi mới kỹ thuật và xu hướng công nghệ",
              activities:
                "Khuyến khích trẻ khám phá tiềm năng công nghệ, cải thiện cuộc sống",
            },
            {
              lessonUnit: "Bài 10",
              lessonName: "Nghề nghiệp tương lai và học tập suốt đời",
              description:
                "Trao quyền cho trẻ khám phá niềm đam mê, sở thích, khát vọng…",
              activities:
                "Cung cấp nguồn lực và hướng dẫn trẻ theo đuổi ước mơ và tạo ảnh hưởng tích cực đến thế giới bằng tiếng việt",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "Lesson 1",
              lessonName: "Advanced literary analysis",
              description: "Deepening analysis of Vietnamese literature",
              activities:
                "Encouraging children to analyze and interpret texts in Vietnamese",
            },
            {
              lessonUnit: "Lesson 2",
              lessonName: "Cultural identity and heritage",
              description: "Exploring topics related to cultural identity",
              activities:
                "Discussing the significance of cultural heritage in Vietnam",
            },
            {
              lessonUnit: "Lesson 3",
              lessonName: "Global issues and policy advocacy",
              description:
                "Participate in discussions on pressing global issues",
              activities: "Encouraging support for positive social change",
            },
            {
              lessonUnit: "Lesson 4",
              lessonName: "Media knowledge and critical media analysis",
              description:
                "Deepening understanding skills know about advanced communication",
              activities:
                "Discuss the role of communication in promoting the development of Vietnamese society",
            },
            {
              lessonUnit: "Lesson 5",
              lessonName: "Ethical leadership and social responsibility",
              description:
                "Explore topics related to ethical and responsible leadership",
              activities:
                "Encourage children to apply ethical principles to their lives",
            },
            {
              lessonUnit: "Lesson 6",
              lessonName: "Innovation and entrepreneurship",
              description:
                "Inspiring children to become thinkers and entrepreneurs",
              activities:
                "Encourage developing creative solutions to world problems and pursuing business projects",
            },
            {
              lessonUnit: "Lesson 7",
              lessonName: "Environmental conservation and sustainability",
              description:
                "Help children gain a deeper understanding of sustainability and conservation",
              activities:
                "Encourage children to take action to protect the environment school",
            },
            {
              lessonUnit: "Lesson 8",
              lessonName: "Humanitarianism and global citizenship",
              description:
                "Join the discussion on humanitarianism and global citizenship",
              activities:
                "Encouraging children to become compassionate citizens and advocate for human rights in Vietnam",
            },
            {
              lessonUnit: "Lesson 9",
              lessonName: "Technology trends and digital innovation",
              description:
                "Advanced exploration of the topic of technical innovation and technology trends",
              activities:
                "Encouraging children to explore the potential of technology, improve life",
            },
            {
              lessonUnit: "Lesson 10",
              lessonName: "Future careers and lifelong learning",
              description:
                "Empower children to explore their passions, interests, aspirations...",
              activities:
                "Providing resources and guidance for children to pursue their dreams and make a positive impact on the world in Vietnamese",
            },
          ],
        },
      ],
    },
    {
      courseName: "Practice Vietnamese pronunciation",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Người nước ngoài học tiếng Việt",
            "Trung tâm Ngôn ngữ và Văn hóa Việt Nam Học viện ABBA",
          ],
          contentEN: [
            "Foreigners studying Vietnamese",
            "Vietnamese Language and Culture Center ABBA Academy",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            'Hiểu rằng tiếng Việt là một ngôn ngữ thanh điệu có sáu thanh điệu riêng biệt. Tránh hiểu lầm bằng cách phát âm các từ một cách chính xác dựa trên thanh điệu của chúng (ví dụ: "ma" với các thanh điệu khác nhau)',
            "Học và phân biệt giữa các nguyên âm tương tự (ví dụ: â vs ă, ê vs e).Đảm bảo giao tiếp rõ ràng bằng cách nắm vững các nguyên âm này.",
            "Nắm vững các phụ âm tiếng Việt khó (ví dụ: âm 'd' của miền Bắc Việt Nam giống như 'z', âm 'r' của miền Nam Việt Nam có thể được phát âm giống như 'z' hoặc 'y'). Nói tự nhiên và được người bản xứ hiểu chính xác.",
            "Đảm bảo sự rõ ràng trong giao tiếp bằng cách phát âm chính xác các từ.Tránh nhầm lẫn hoặc khó hiểu, đặc biệt là trong các bối cảnh quan trọng như kinh doanh, học thuật hoặc tương tác hàng ngày.",
            "Thể hiện sự tôn trọng ngôn ngữ và văn hóa Việt Nam bằng cách học phát âm đúng. Tăng cường hội nhập văn hóa và thúc đẩy mối quan hệ tích cực với người bản xứ.",
            "Mở ra cơ hội nghề nghiệp và theo đuổi học thuật thông qua khả năng phát âm tiếng Việt thành thạo.",
            "Tham gia sâu hơn vào cộng đồng nói tiếng Việt.",
            "Tạo điều kiện giao tiếp hiệu quả và thể hiện bản thân một cách tự tin. Kết nối chân thực hơn với người nói tiếng Việt.",
          ],
          contentEN: [
            'Understanding that Vietnamese is a tonal language with six distinct tones.Avoiding misunderstandings by pronouncing words correctly based on their tones (e.g., "ma" with different tones).',
            "Learning and distinguishing between similar vowel sounds (e.g., â vs ă, ê vs e).Ensuring clear communication by mastering these vowel sounds.",
            "Mastering challenging Vietnamese consonants (e.g., Northern Vietnam's 'd' sounds like 'z', Southern Vietnam's 'r' can be pronounced like 'z' or 'y').Speaking naturally and being understood correctly by native speakers.",
            "Ensuring clarity in communication by pronouncing words correctly.Avoiding confusion or difficulty in understanding, especially in important contexts like business, academia, or daily interactions.",
            "Showing respect for the Vietnamese language and culture by learning correct pronunciation.Enhancing cultural integration and promoting positive relationships with native speakers.",
            "Opening up career opportunities and academic pursuits through proficiency in Vietnamese pronunciation.",
            "Participating more deeply in the Vietnamese-speaking community.",
            "Facilitating effective communication and expressing oneself confidently.Connecting more authentically with Vietnamese speakers.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Đạt được sự thành thạo tiếng Việt.",
            "Đạt được sự hiểu biết về văn hóa.",
            "Thành công trong giao tiếp trong cả bối cảnh cá nhân và nghề nghiệp.",
            "Hưởng lợi từ khóa học phát âm tiếng Việt do Trung tâm Ngôn ngữ và Văn hóa Việt Nam ABBA cung cấp.",
          ],
          contentEN: [
            "Achieving fluency in Vietnamese.",
            "Gaining cultural understanding.",
            "Succeeding in communication in both personal and professional contexts.",
            "Benefits from a Vietnamese pronunciation course offered by the Vietnamese Language and Culture Center ABBA.",
          ],
        },
      ],
      coursePath: [],
    },
    {
      courseName: "Practice Vietnamese songs",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên muốn nói được tiếng Việt nhanh nhất trong thời gian ngắn nhất.",
          ],
          contentEN: [
            "Learners looking to speak Vietnamese the fastest in the shortest time.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Được luyện nghe, nói theo hệ thống chủ đề đa dạng, phong phú, gần gũi với đời sống xã hội hiện đại như: kinh tế, thương mại, hành chính, công sở, du lịch, truyền thông…",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "Practice listening and speaking on a system of diverse and rich topics close to modern social life such as economics, commerce, administration, office, tourism, media...",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Có thể tham gia và hiểu được những đoạn hội thoại ngắn, có cấu trúc đơn giản với tốc độ nói chậm và rõ về các chủ đề cơ bản, thiết yếu.",
            "Có thể viết và trả lời bưu thiếp, điền các bảng và biểu mẫu đơn giản.",
            "Có thể trả lời phỏng vấn những câu hỏi trực tiếp, đơn giản với tốc độ chậm, rõ ràng.",
            "Chú trọng rèn luyện phát âm chuẩn xác như người bản ngữ ngay từ đầu. Sau khi làm quen với bảng chữ cái và thanh điệu tiếng Việt, các em sẽ được học cách đánh vần để có thể tự đọc được bất cứ từ ngữ nào.",
          ],
          contentEN: [
            "Can participate in and understand short, simply structured conversations, speaking slowly and clearly on basic, essential topics.",
            "Can write and reply to postcards, fill out simple tables and forms.",
            "Can answer direct, simple interview questions at a slow, clear pace.",
            "Focus on practicing correct pronunciation like a native speaker from the beginning. After getting familiar with the Vietnamese alphabet and tones, children will learn how to spell so they can read any word on their own. .",
          ],
        },
      ],
      coursePath: [],
    },
  ];
  return data;
};

export const VietnameseCourseContentForAdult = () => {
  const data: CourseContent[] = [
    {
      courseName: "Certificate of Vietnamese language proficiency (A1 to C1)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Dành cho những học viên muốn đạt được chứng chỉ năng lực tiếng Việt để tham gia vào các hoạt động nghề nghiệp hoặc học tập tại Việt Nam.",
          ],
          contentEN: [
            "For learners seeking to achieve the Certificate of Vietnamese language proficiency to participate in job opportunities or study abroad in Vietnam.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Chuẩn bị Tốt Cho Kỳ Thi Năng Chứng Chỉ: Khóa học giúp bạn chuẩn bị tốt cho kỳ thi năng chứng chỉ năng lực tiếng Việt, tăng cơ hội đạt được chứng chỉ với kết quả cao.",
            "Nắm Vững Kỹ Năng làm bài thi:  Bạn sẽ nắm vững các kỹ năng làm bài thi và xừ lý bài thi nhanh và hiệu quả.",
            "Tự Tin Vào kỳ Thi: Khóa học giúp bạn tự tin hơn khi tham gia vào cuộc thi năng chứng chỉ năng lực tiếng Việt.",
          ],
          contentEN: [
            "Prepare Well for the Certificate of Proficiency Test: The course helps you prepare well for the Vietnamese Language Proficiency Certificate Test, increasing your chances of achieving a certificate with high results.",
            "Master test-taking skills: You will master test-taking skills and process tests quickly and effectively.",
            "Confidence in Exams: The course helps you be more confident when participating in the Vietnamese language proficiency certification exam.",
          ],
        },
      ],
      coursePath: [],
    },
    {
      courseName: "Vietnamese language test preparation class (A1 to C1)",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Dành cho những học viên muốn kết hợp sự thành thạo về Tiếng Việt với kiến thức chuyên môn trong một lĩnh vực cụ thể.",
          ],
          contentEN: [
            "For learners seeking to combine their Vietnamese proficiency with specialized knowledge in a specific field.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Chuyên Môn Cơ Bản: Bạn sẽ nắm vững các kiến thức cơ bản trong lĩnh vực chuyên môn của bạn, bất kể đó là kinh doanh, y học, khoa học xã hội, hay lĩnh vực khác.",
            "Kỹ Năng Đọc và Viết Chuyên Ngành: Bạn sẽ học cách đọc và viết các loại tài liệu chuyên môn, bao gồm bài báo khoa học, tài liệu kỹ thuật, và báo cáo.",
            "Giao Tiếp Chuyên Môn: Khóa học sẽ giúp bạn phát triển khả năng giao tiếp chuyên môn qua việc tham gia vào các cuộc thảo luận, buổi trình bày, và thực hành trong lĩnh vực chuyên môn của bạn.",
          ],
          contentEN: [
            "Fundamentals of Expertise: You will master the fundamentals of your area of ​​expertise, whether it is business, medicine, social sciences, or another.",
            "Professional Reading and Writing Skills: You will learn how to read and write a variety of professional documents, including scientific articles, technical documents, and reports.",
            "Professional Communication: The course will help you develop your professional communication abilities by participating in discussions, presentations, and practice in your area of ​​expertise.",
          ],
        },
      ],
      coursePath: [],
    },
    {
      courseName: "Practice Vietnamese pronunciation",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Foreigners learning Vietnamese",
            "Vietnamese people learn Vietnamese",
          ],
          contentEN: [
            "Foreigners learning Vietnamese",
            "Vietnamese people learning Vietnamese",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            'Understanding that Vietnamese is a tonal language with six distinct tones.Avoiding misunderstandings by pronouncing words correctly based on their tones (e.g., "ma" with different tones).',
            "Learning and distinguishing between similar vowel sounds (e.g., â vs ă, ê vs e).Ensuring clear communication by mastering these vowel sounds.",
            "Mastering challenging Vietnamese consonants (e.g., Northern Vietnam's 'd' sounds like 'z', Southern Vietnam's 'r' can be pronounced like 'z' or 'y').Speaking naturally and being understood correctly by native speakers.",
            "Ensuring clarity in communication by pronouncing words correctly.Avoiding confusion or difficulty in understanding, especially in important contexts like business, academia, or daily interactions.",
            "Showing respect for the Vietnamese language and culture by learning correct pronunciation.Enhancing cultural integration and promoting positive relationships with native speakers.",
            "Opening up career opportunities and academic pursuits through proficiency in Vietnamese pronunciation.",
            "Participating more deeply in the Vietnamese-speaking community.",
            "Facilitating effective communication and expressing oneself confidently.Connecting more authentically with Vietnamese speakers.",
          ],
          contentEN: [
            'Understanding that Vietnamese is a tonal language with six distinct tones.Avoiding misunderstandings by pronouncing words correctly based on their tones (e.g., "ma" with different tones)',
            "Learning and distinguishing between similar vowel sounds (e.g., â vs ă, ê vs e).Ensuring clear communication by mastering these vowel sounds.",
            "Mastering challenging Vietnamese consonants (e.g., Northern Vietnam's 'd' sounds like 'z', Southern Vietnam's 'r' can be pronounced like 'z' or 'y').Speaking naturally and being understood correctly by native speakers.",
            "Ensuring clarity in communication by pronouncing words correctly.Avoiding confusion or difficulty in understanding, especially in important contexts like business, academia, or daily interactions.",
            "Showing respect for the Vietnamese language and culture by learning correct pronunciation.Enhancing cultural integration and promoting positive relationships with native speakers.",
            "Opening up career opportunities and academic pursuits through proficiency in Vietnamese pronunciation.",
            "Participating more deeply in the Vietnamese-speaking community.",
            "Facilitating effective communication and expressing oneself confidently.Connecting more authentically with Vietnamese speakers.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Achieving fluency in Vietnamese.",
            "Gaining cultural understanding.",
            "Succeeding in communication in both personal and professional contexts.",
            "Benefiting from a Vietnamese pronunciation course offered by the Vietnamese Language and Culture Center ABBA.",
          ],
          contentEN: [
            "Achieving fluency in Vietnamese.",
            "Gaining cultural understanding.",
            "Succeeding in communication in both personal and professional contexts.",
            "Benefiting from a Vietnamese pronunciation course offered by the Vietnamese Language and Culture Center ABBA.",
          ],
        },
      ],
      coursePath: [
        {
          level:
            "1 : Vietnamese alphabet and structure of a syllable (Bảng chữ cái tiếng Việt và cấu trúc của một âm tiết)",
          courseContent: [
            {
              lessonUnit: "1.1",
              lessonName: "Vietnamese alphabet  (Bảng chữ cái tiếng Việt)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2",
              lessonName:
                "Brief introduction to the structure of a syllable in Vietnamese (Giới thiệu sơ lược về cấu trúc âm tiết trong tiếng Việt)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.1",
              lessonName: "The syllable (Âm tiết)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.2",
              lessonName: "Initial sounds (Âm đầu)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.3",
              lessonName: "The main sounds (Âm chính)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.4",
              lessonName: "Prevocalic (Âm đệm)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.5",
              lessonName: "Final sounds (Âm cuối)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.6",
              lessonName: "The tones (Thanh điệu)",
              description: "",
              activities: "",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "1.1",
              lessonName: "Vietnamese alphabet",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2",
              lessonName:
                "Brief introduction to the structure of a syllable in Vietnamese",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.1",
              lessonName: "The syllable",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.2",
              lessonName: "Initial sounds",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.3",
              lessonName: "The main sounds",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.4",
              lessonName: "Prevocalic",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.5",
              lessonName: "Final sounds",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "1.2.6",
              lessonName: "The tones",
              description: "",
              activities: "",
            },
          ],
        },
        {
          level:
            "2: Practice pronouncing open rhymed syllables (Luyện phát âm âm tiết có vần mở)",
          courseContent: [
            {
              lessonUnit: "2.1",
              lessonName:
                "Introducing how to spell the open rhymes in Vietnamese (Giới thiệu cách đánh vần âm tiết có vần mở trong tiếng Việt)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "2.2",
              lessonName:
                "Introducing the initial consonant system and how to pronounce initial sounds (Giới thiệu hệ thống phụ âm đầu và cách phát âm các âm đầu)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "2.3",
              lessonName:
                "The main sounds system and how to pronounce the main sounds (Hệ thống âm chính và cách phát âm các âm chính)",
              description: "",
              activities: "",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "2.1",
              lessonName:
                "Introducing how to spell the open rhymes in Vietnamese",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "2.2",
              lessonName:
                "Introducing the initial consonant system and how to pronounce initial sounds",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "2.3",
              lessonName:
                "The main sounds system and how to pronounce the main sounds",
              description: "",
              activities: "",
            },
          ],
        },
        {
          level:
            "3: Practice pronouncing syllables with semi-open rhymes, closed rhymes and semi-closed rhymes (Luyện phát âm âm tiết có vần nửa mở, vần khép và vần nửa khép)",
          courseContent: [
            {
              lessonUnit: "3.1",
              lessonName:
                "Introduce spelling rules for semi-open, closed and semi-closed rhymes (Giới thiệu cách đánh vần các vần nửa mở, vần khép và nửa khép)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "3.2",
              lessonName:
                "Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘i/y, ê, e, iê, yê, ia/ya’. (Luyện đánh vần âm tiết có vần nửa mở, khép và nửa khép có âm chính “i/y, ê, e, iê, yê, ia/ya”)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "3.3",
              lessonName:
                "Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘ư, ơ, â, a, ă, ươ, ưa’ (Luyện đánh vần âm tiết có vần nửa mở, khép và nửa khép có âm chính “ư, ơ, â, a, ă, ươ, ưa”)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "3.4",
              lessonName:
                "Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘u, ô, o, uô, ua’. (Luyện đánh vần âm tiết có vần nửa mở, khép và nửa khép có âm chính “u, ô, o, uô, ua”)",
              description: "",
              activities: "",
            },
          ],
          courseContentEN: [
            {
              lessonUnit: "3.1",
              lessonName:
                "Introduce spelling rules for semi-open, closed and semi-closed rhymes",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "3.2",
              lessonName:
                "Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘i/y, ê, e, iê, yê, ia/ya’. (Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘i/y, ê, e, iê, yê, ia/ya’.)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "3.3",
              lessonName:
                "Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘ư, ơ, â, a, ă, ươ, ưạ’ (Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘ư, ơ, â, a, ă, ươ, ưạ”)",
              description: "",
              activities: "",
            },
            {
              lessonUnit: "3.4",
              lessonName:
                "Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘u, ô, o, uô, ua’. (Practice spelling syllables with semi-open, closed and semi-closed rhymes with main sounds ‘u, ô, o, uô, ua”)",
              description: "",
              activities: "",
            },
          ],
        },
      ],
    },
    {
      courseName: "Vietnamese offline/online course",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Học viên muốn nói được tiếng Việt nhanh nhất trong thời gian ngắn nhất.",
          ],
          contentEN: [
            "Learners wanting to speak Vietnamese as fast as possible within the shortest timeframe.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
            "Được luyện nghe, nói theo hệ thống chủ đề đa dạng, phong phú, gần gũi với đời sống xã hội hiện đại như: kinh tế, thương mại, hành chính, công sở, du lịch, truyền thông…",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
            "Practice listening and speaking on a system of diverse and rich topics close to modern social life such as economics, commerce, administration, office, tourism, media...",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Có thể tham gia và hiểu được những đoạn hội thoại ngắn, có cấu trúc đơn giản với tốc độ nói chậm và rõ về các chủ đề cơ bản, thiết yếu.",
            "Có thể viết và trả lời bưu thiếp, điền các bảng và biểu mẫu đơn giản.",
            "Có thể trả lời phỏng vấn những câu hỏi trực tiếp, đơn giản với tốc độ chậm, rõ ràng.",
          ],
          contentEN: [
            "Learners can participate and understand short conversations with clear and simple structure, slow and clear speaking about basic topics, essential skills.",
            "Learners can write and reply emails, fill out simple forms.",
            "Learners can answer one-on-one interview questions, slow and clear speaking, deep understanding.",
          ],
        },
      ],
      coursePath: [],
    },
    {
      courseName: "Vietnamese for business communication",
      introductionContent: [
        {
          contentFor: "Participants",
          content: [
            "Cá nhân làm việc tại các doanh nghiệp có vốn đầu tư nước ngoài, muốn sử dụng tiếng Việt trong công việc.",
            "Học viên đã có thể nói tiếng việt cơ bản nhưng muốn thảo luận sâu hơn một số vấn đề trong công việc.",
          ],
          contentEN: [
            "Individuals working in foreign corporations, wanting to use Vietnamese in their work, and seeking to deepen their understanding of certain business topics.",
          ],
        },
        {
          contentFor: "Benefit",
          content: [
            "Đội ngũ giảng viên của ABBA là những chuyên gia đến từ các trường đại học uy tín như: Đại học Sư Phạm Hà Nội, Đại học Khoa học xã hội và Nhân văn.",
            "Thiết kế chương trình học riêng cho từng gói học theo đúng nhu cầu, nguyện vọng của học viên.",
          ],
          contentEN: [
            "ABBA's teaching staff are experts from prestigious universities such as Hanoi University of Education, University of Social Sciences and Humanities.",
            "Design a separate curriculum for each package according to the needs and wishes of the student.",
          ],
        },
        {
          contentFor: "Receive Result",
          content: [
            "Học viên sẽ có khả năng giao tiếp: các tình huống giao tiếp cơ bản trong công việc, email trong công việc, trao đổi công việc cơ bản",
          ],
          contentEN: [
            "Learners will have the ability to communicate: basic communication scenarios in work, email in work, basic work communication.",
          ],
        },
      ],
      coursePath: [],
    },
  ];
  return data;
};
