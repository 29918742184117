import { axiosInstance } from "../../utils/Axios-interceptor";
import { LoginRequest, RegisterRequest } from "./InterfaceResponse";

export const RegisterAccountService= async (data:RegisterRequest)=>{
    try{
        const res= await axiosInstance.post(`/authenticate/signUp`,data);
        return res;
    }catch(err:any){
        throw new Error(err.response);
    }
}

export const ActiveAccountService= async (values:string)=>{
    try{
        const data:{
            email: string;
        }={
            email: values
        }
        const res= await axiosInstance.post(`/authenticate/active-account`,data);
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const LoginService= async (data: LoginRequest)=>{
    try{
        const res= await axiosInstance.post(`/authenticate/signIn`,data);
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}
export const CheckSessionLoginService= async ()=>{
    try{
        const res= await axiosInstance.get(`/authenticate/autoLogin`);
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}
export const LogoutService= async ()=>{
    try{
        const res= await axiosInstance.get(`/authenticate/logout`);
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const LoadUserInfoService= async ()=>{
    try{
        const res= await axiosInstance.get(`/authenticate/load-info`);
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const FindEmailExistAPI= async (email:string)=>{
    try{
        const res :string= await axiosInstance.post(`/authenticate/find-email?email=${email}`);
        return res as string;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const SendUpdatePasswordAPI= async (data: {email:string;password:string})=>{
    try{
        const res:string= await axiosInstance.post(`/authenticate/reset-password`,data);
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}