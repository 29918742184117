import axios from "axios";
import { ExtractCodeResponse, LoginWithOAuth2GoogleRequest } from "./InterfaceResponse";
import { axiosInstance } from "../../utils/Axios-interceptor";
import { LoginResponse } from "../AuthenticateAPI/InterfaceResponse";



const client_id : string|undefined= process.env.REACT_APP_GOOGLE_CLIENT_ID
const client_secret : string|undefined= process.env.REACT_APP_GOOGLE_SECRET_ID
const redirect_uri : string|undefined= process.env.REACT_APP_GOOGLE_REDIRECT_URI
const grant_type : string|undefined= process.env.REACT_APP_GOOGLE_GRANT_TYPE

export const GetTokenAndRefreshTokenFromCode= async (code:string)=>{
    try{
        const formData= new FormData();
        if(client_id) formData.append("client_id",client_id);
        if(client_secret) formData.append("client_secret",client_secret);
        if(redirect_uri) formData.append("redirect_uri",redirect_uri);
        if(grant_type) formData.append("grant_type","authorization_code");
        if(code) formData.append("code",code);
        const response= axios.post("https://oauth2.googleapis.com/token",formData,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        if(response){
            return (await response).data as unknown as ExtractCodeResponse;
        }
    }catch(e:any){
        throw new Error(e.message);
    }
}
export const LoginWithOAuth2GoogleAPI= async (userInfo: LoginWithOAuth2GoogleRequest)=>{
    try{
        const res= await axiosInstance.post("/OAuth2/google/login",userInfo);
        return res as unknown as LoginResponse;
    }catch(e:any){
        throw new Error(e.message);
    }
}