import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from 'react-redux';

import CourseSliceReducer from "./CourseSlice/CourseSlice";
import MenuNavigationReducer from "./MenuNavigationSlice/MenuNavigationSlice";
import BookSliceReducer from "./BookSlice/BookSlice";
import CartSliceReducer from "./CartSlice/CartSlice";
import OrderSliceReducer from "./OrderSlice/OrderSlice";
import AuthenticateProvider from "../redux/AuthenticateSlice/AuthenticateSlice";
import BlogReducer from "../redux/BlogSlice/BlogSlice";
import TranslateReducer from "../redux/TranslateSlice/TranslateSlice";

export const store= configureStore({
    reducer: {
        courses: CourseSliceReducer,
        menuNavigation: MenuNavigationReducer,
        books: BookSliceReducer,
        cart: CartSliceReducer,
        order: OrderSliceReducer,
        authenticate: AuthenticateProvider,
        blogs: BlogReducer,
        translate: TranslateReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: (selector: (state: RootState) => any) => any = useSelector;