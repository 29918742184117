import { CartRequest, CartResponse } from "../../redux/CartSlice/InterfaceResponse";
import { axiosInstance } from "../../utils/Axios-interceptor";

export const AddToCart = async (data: CartRequest) => {
    try{
        const res= await axiosInstance.post(`/user/books/add-to-cart/${data.bookId}`,{quantity: data.quantity});
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const GetCartItemOfUser= async ()=>{
    try{
        const res:CartResponse[]|[]= await axiosInstance.get(`/cart/get-all-book-in-cart`);
        return res;
    }catch(err:any){
        throw new Error(err.message);
    }
}

export const IncreaseQuantityBookInCart = async (bookId:number)=>{
    try{
        const res= await axiosInstance.put(`/cart/increase/${bookId}`);
        return res;
    }catch(err:any){
        throw err;
    }
}

export const DecreaseQuantityBookInCart = async (bookId:number)=>{
    try{
        const res= await axiosInstance.put(`/cart/decrease/${bookId}`);
        return res;
    }catch(err:any){
        throw err;
    }
}

export const DeleteBookInCart= async (bookId:number)=>{
    try{
        const res= await axiosInstance.delete(`/cart/delete/${bookId}`);
        return res;
    }catch(err:any){
        throw err;
    }
}