import { createSlice } from "@reduxjs/toolkit";
import { BlogResponse } from "./InterfaceResponse";

interface InitialStateStyles{
    loading: boolean;
    error: string;
    blogs: BlogResponse;
}

const initialState:InitialStateStyles={
    loading: false,
    error: "",
    blogs: {
        kind: "",
        items: [],
        etag: ""
    }
}

const BlogSlice= createSlice({
    name: "blogs",
    initialState,
    reducers: {
        updateStateLoading: (state)=>{
            state.loading=!state.loading;
        },
        updatePost: (state,action)=>{
            state.blogs=action.payload;
        }
    }
});

export const { updateStateLoading,updatePost }= BlogSlice.actions;
export default BlogSlice.reducer;