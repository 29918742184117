import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CartRequest, CartResponse } from "./InterfaceResponse";
import {
  AddToCart,
  DecreaseQuantityBookInCart,
  DeleteBookInCart,
  GetCartItemOfUser,
  IncreaseQuantityBookInCart,
} from "../../services/CartAPI/CartAPI";
import { toast } from "react-toastify";

interface InitialState {
  Loading: boolean;
  LoadingPage: boolean;
  error: string;
  message: string;
  CheckLogin: boolean;
  CartItem: CartResponse[] | null;
}

export const AddBookToCart = createAsyncThunk<any, CartRequest>(
  "AddBookToCart",
  async (data: CartRequest) => {
    try {
      const response = await AddToCart(data);
      return response;
    } catch (err: any) {
      throw Error(err.status);
    }
  }
);

export const IncreaseQuantityBookInCartAction = createAsyncThunk<any, number>(
  "IncrementQuantityBookInCartAction",
  async (bookId: number) => {
    try {
      const response = await IncreaseQuantityBookInCart(bookId);
      return response;
    } catch (err: any) {
      throw Error(err.status);
    }
  }
);

export const DecreaseQuantityBookInCartAction = createAsyncThunk<any, number>(
  "DecreaseQuantityBookInCartAction",
  async (bookId: number) => {
    try {
        const response = await DecreaseQuantityBookInCart(bookId);
        return response;
      } catch (err: any) {
        throw Error(err.status);
      }
  }
);

export const DeleteBookInCartAction = createAsyncThunk<any, number>(
  "DeleteBookInCartAction",
  async (bookId: number) => {
    try{
        const response = await DeleteBookInCart(bookId);
        return response;
    }catch(err:any){
        throw Error(err.status);
    }
  }
);

export const GetAllCartItemAction = createAsyncThunk<CartResponse[]>(
  "GetAllCartItemAction",
  async () => {
    try {
      const response = await GetCartItemOfUser();
      return response as unknown as CartResponse[];
    } catch (err: any) {
      throw Error(err.status);
    }
  }
);

const initialState: InitialState = {
    Loading: false,
    error: "",
    message: "",
    CheckLogin: false,
    CartItem: null,
    LoadingPage: false
};
const CartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    updateStateCheckLogin: (state, action) => {
      state.CheckLogin = action.payload;
    },
    updateStateCartItem: (state, action) => {
      if (state.CartItem) {
        const findBookInCart = state.CartItem.find(
          (item) => item.bookId === action.payload.bookId
        );
        if (findBookInCart) {
          state.CartItem.forEach((item) => {
            if (item.cartItemId === findBookInCart.cartItemId) {
              item.quantity += action.payload.quantity;
            }
          });
        } else {
          state.CartItem.push(action.payload);
        }
      }
    },
    ClearStateCartItemWhenLogout: (state) => {
      state.CartItem = [];
    },
    updateQuantityBookIncart: (state, action) => {
      if (state.CartItem && state.CartItem.length > 0) {
        if(action.payload.method==="+"){
            state.CartItem.forEach((item) => {
                if (item.bookId === action.payload.bookId) {
                  item.quantity += 1;
                }
              });
        }else{
            state.CartItem.forEach((item) => {
                if (item.bookId === action.payload.bookId) {
                  item.quantity -= 1;
                }
            });
        }
        
      }
    },
    deleteBookInCart: (state,action)=>{
        if(state.CartItem && state.CartItem.length>0){
            state.CartItem = state.CartItem.filter((item:CartResponse)=>{
                return item.bookId!== action.payload;
            })
        }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddBookToCart.pending, (state) => {
        state.Loading = true;
      })
      .addCase(GetAllCartItemAction.pending, (state) => {
        state.Loading = true;
        state.LoadingPage=true;
      })
      .addCase(IncreaseQuantityBookInCartAction.pending, (state) => {
        state.Loading = true;
      })
      .addCase(DecreaseQuantityBookInCartAction.pending, (state) => {
        state.Loading = true;
      })
      .addCase(DeleteBookInCartAction.pending, (state) => {
        state.Loading = true;
        state.LoadingPage=true;
      })
      .addCase(AddBookToCart.fulfilled, (state, action) => {
        state.Loading = false;
        toast.success("Add book to cart successfully");
        state.message = action.payload.message || "add book to cart success";
      })
      .addCase(GetAllCartItemAction.fulfilled, (state, action) => {
        state.Loading = false;
        state.LoadingPage=false;
        state.CartItem = action.payload;
      })
      .addCase(IncreaseQuantityBookInCartAction.fulfilled, (state, action) => {
        state.Loading = false;
      })
      .addCase(DecreaseQuantityBookInCartAction.fulfilled, (state, action) => {
        state.Loading = false;
      })
      .addCase(DeleteBookInCartAction.fulfilled, (state, action) => {
        state.Loading = false;
        state.LoadingPage=false;
      })
      .addCase(AddBookToCart.rejected, (state, action) => {
        state.Loading = false;
        state.error = "error when try add book to cart";
        if (action.error.message === "401") {
          state.CheckLogin = true;
        }
      })
      .addCase(GetAllCartItemAction.rejected, (state, action) => {
        state.Loading = false;
        state.LoadingPage=false;
        state.error = "error when try get ALL cart";
        if (action.error.message === "401") {
          state.CheckLogin = true;
        }
      })
      .addCase(IncreaseQuantityBookInCartAction.rejected, (state, action) => {
        state.Loading = false;
        state.error = "error when try increase quantity book in cart";
        if (action.error.message === "401") {
          state.CheckLogin = true;
        }
      })
      .addCase(DecreaseQuantityBookInCartAction.rejected, (state, action) => {
        state.Loading = false;
        state.error = "error when try decrease quantity book in cart";
        if (action.error.message === "401") {
          state.CheckLogin = true;
        }
      })
      .addCase(DeleteBookInCartAction.rejected, (state, action) => {
        state.Loading = false;
        state.LoadingPage=false;
        state.error = "error when try delete book in cart";
        if (action.error.message === "401") {
          state.CheckLogin = true;
        }
      });
  },
});
export const {
  updateStateCheckLogin,
  updateStateCartItem,
  ClearStateCartItemWhenLogout,
  updateQuantityBookIncart,
  deleteBookInCart
} = CartSlice.actions;
export default CartSlice.reducer;
